import React, { Component } from 'react';
import {FaTimes, FaArrowLeft} from "react-icons/fa";
import {
    Form,
    Input
  } from "reactstrap";

let fields = [];

class EmergencyContactCard extends Component {
    state = {
        park: null,
        full_name: null,
        phone: null,
        error: {
            full_name: false,
            phone: false,
        }
    }

    validateWaiver() {
        let state = { ...this.state };
        let error = false

        if (!this.state.full_name) {
            state.error.full_name = "Full Name is Required"
            error = true
        }
        else {
            if (this.state.full_name.split(' ').length == 1) {
                state.error.full_name = "Please complete your full name"
                error = true
            }
        }
        if (!this.state.phone) {
            state.error.phone = "Phone is Required"
            error = true
        }
        
        this.setState(state)
        if (!error) {
            this.props.addEmergencyContact(this.state);
        }
        else {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    handleChange(e) {
        let value;
        
        fields[e.target.name] = e.target.value.length;

        if (e.target.type == 'checkbox') {
            value = e.target.checked
        }
        else if(e.target.type == 'radio') {
            if (parseInt(e.target.value) == 1) {
                value = true
            } 
            else {
                value = false
            }
        }
        else {
            value = e.target.value;
        }
        this.setState({ [e.target.name]: value });
    }

    getFormErrorContent() {
        return (
            <div class="errorTxt fs-subtitle">
                {   this.state.error.full_name ?
                    <div class="error">
                        {this.state.error.full_name}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.phone ?
                    <div class="error">
                        {this.state.error.phone}
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }

    previousPage() {
        this.props.actionShow("PersonalInformationShow")
    }

    render() {
        const FormErrorContent = this.getFormErrorContent()
        return (
            this.props.EmergencyContactShow ?
            <Form 
                onSubmit={e => {
                    e.preventDefault()
                    this.validateWaiver()
                }}
                className="waiver-personal-info"
            >
                <div className="form-card">
                    <button type="button" className="close btn btn-lg btn-light fa fa-times" name="btn_cancel" id="btn_cancel" data-toggle="modal" data-target="#leave_waiver_kiosk" value="Cancel"></button>
                    <div className="title-content text-center fs-subtitle">
                        <h2 className="fs-title"><span>{this.props.park.name}</span></h2>
                        <h4><strong>Please provide us your emergency contact</strong></h4>
                        <div className="errorTxt">
                            {FormErrorContent}
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: "22px", justifyContent: "center"}}>
                        <div className="col-md-6" style={{alignContent: "center"}}>
                            <label for="name_em" style={{marginLeft: "5px"}} className="fs-label">Full Name</label>
                            <Input 
                                className={`form-control fs-input ${this.state.error.full_name ? 'form-error ' : ''}`}
                                style={{marginBottom: "5px"}}
                                type="text"
                                id="full_name"
                                name="full_name"
                                value={this.state.full_name}
                                onChange={(e) => {
                                    this.handleChange(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="row" style={{marginBottom: "22px", justifyContent: "center"}}>
                        <div className="col-md-6">
                            <label for="phone" style={{marginLeft: "5px"}} className="fs-label">Phone Number</label>
                            <Input 
                                style={{marginBottom: "5px"}} 
                                type="tel" 
                                className={`number-only form-control fs-input ${this.state.error.full_name ? 'form-error ' : ''}`}
                                id="phone" 
                                name="phone"
                                value={this.state.phone}
                                onChange={(e) => {
                                    this.handleChange(e)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <button type="button" className="action-button-previous" value="Previous" onClick={() => this.previousPage()}><FaArrowLeft/> Back</button>
                <input id="submit_pi" type="submit" name="submit_pi" className="next action-button float-right" value="Next"></input>
            </Form>
            :
            <></>
        );
    }
}
export default EmergencyContactCard
