import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Form, FormGroup, Input, Button } from "reactstrap";
import { fetchParks } from "../../actions/global";
import { fetchProducts } from "../../actions/products";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      park_id: null,
      product_id: null,
      redirect: false,
      page_redirect: 'order'
    };
    this.handleChangeParks = this.handleChangeParks.bind(this);
  }
  setRedirect = () => {
    this.setState({
      redirect: true,
      page_redirect:"order"
    });
  };
  setRedirectvoucher = () => {
    this.setState({
      redirect: true,
      page_redirect:"voucher"
    });
  };
  renderRedirect = () => {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname:
              "/"+this.state.page_redirect+"/" + this.state.park_id
          }}
        />
      );
    }
  };
  componentDidMount() {
    const { fetchParks, fetchProducts } = this.props;
    fetchProducts();
    fetchParks();
  }
  handleChangeParks(v, e) {
    this.setState({ park_id: e.target.value });
  }

  render() {
    const parks = this.props.parks.map(park => {
      return (
        <option value={park.id} key={park.id}>
          {park.name}
        </option>
      );
    });      
    return (
      <Form>
        {this.renderRedirect()}
        <Row form style={{ margin: "10px" }}>
          <Col md={4}>
            <FormGroup row>
              <Col sm={12}>
                <Input
                  type="select"
                  name="park"
                  ref="park"
                  onChange={event =>
                    this.handleChangeParks("product", event)
                  }
                >
                  <option value="">Select a park</option>
                  {parks}
                </Input>
              </Col>
            </FormGroup>
          </Col>
          <Col md={2}>
            <Button
              onClick={this.setRedirect}
              color="secondary"
              name="btnbook"
              ref="btnbook"
            >
              Book Now
            </Button>{" "}
            <Button
              onClick={this.setRedirectvoucher}
              color="secondary"
              name="btnbook"
              ref="btnbook"
            >
              Buy Voucher
            </Button>{" "}
          </Col>
        </Row>
      </Form>
    );
  }
}
const mapStateToProps = state => {
  return {
    parks: state.parkReducer.data,
    products: state.productReducer.data
  };
};

function mapDispatchToProps(dispatch) {
  return {
    fetchParks: data => dispatch(fetchParks(data)),
    fetchProducts: data => dispatch(fetchProducts(data))
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
