import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class ProgressBar extends Component {
    render() {
        return (
            <div id="progress">
              <div className="progress-line">
                <div className={this.props.progress > 0 ? "progress-item finished" : this.props.progress == 1 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Waiver Type</div>
                  <div className="circle">1</div>
                  <div className="circle-line" style={{borderTopLeftRadius: "8px", borderBottomLeftRadius: "8px"}}></div>
                </div>                  
                <div className={this.props.progress > 1 ? "progress-item finished" : this.props.progress == 2 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Personal Information</div>
                  <div className="circle">2</div>
                  <div className="circle-line"></div>
                </div>
                <div className={this.props.progress > 2 ? "progress-item finished" : this.props.progress == 3 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Emergency Contact</div>
                  <div className="circle">3</div>
                  <div className="circle-line"></div>
                </div>
                <div className={this.props.progress > 3 ? "progress-item finished" : this.props.progress == 4 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Add Minor</div>
                  <div className="circle">4</div>
                  <div className="circle-line"></div>
                </div>
                <div className={this.props.progress > 4 ? "progress-item finished" : this.props.progress == 5 ? "progress-item ongoing" : "progress-item"}>
                  <div className="progress-title">Sign Waiver</div>
                  <div className="circle">5</div>
                  <div className="circle-line"></div>
                </div>
              </div>
            </div>
        );
    }
}
export default ProgressBar
