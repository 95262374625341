import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class BreadCrumb extends Component {
    render() {
        return (
            <div className="nav" style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <Link to="/" > TREES ADVENTURE </Link>
                </div>
                <div  className={"pointer xxx"} style={{zIndex : "6"}}>
                    <div className="item-beard"></div>
                </div>
                <div  className={"pointer "+(this.props.step === 'step1' && "active")} style={{zIndex : "5"}}>
                    <div className="item-beard">PARK AND PRODUCT</div>
                </div>
                <div  className={"pointer "+(this.props.step === 'step2' && "active")} style={{zIndex : "4"}} >
                    <div className="item-beard">DATE AND TIME</div>
                </div>
            </div>
        );
    }
}
export default BreadCrumb
