import React, { Component } from 'react';
import Home from './components/Home/Home'
import Header from './components/Layout/Header';

class App extends Component {

	render() {
		return (
			<React.Fragment>
				<Header />
				<Home />
			</React.Fragment>
		);
	}
}

export default App;