import React, { Component } from 'react';
import {FaCircle} from "react-icons/fa";

class WaiverTypeCard extends Component {
    state = {
        park: null,
        booking: null,
        customer: null,
        waiverParticipants: null,
        showMore: false,
        start: 0,
        end: 0,
    }

    componentDidUpdate(prevProp, prevState) {
        if (prevProp.park !== this.props.park) {
            this.setState({park: this.props.park})
        }
        if (prevProp.booking !== this.props.booking) {
            this.setState({booking: this.props.booking})
        }
        if (prevProp.customer !== this.props.customer) {
            this.setState({customer: this.props.customer})
        }
        if (prevProp.waiverParticipants !== this.props.waiverParticipants) {
            this.setState({
                waiverParticipants: this.props.waiverParticipants,
                start: 0
            })
            if (this.props.waiverParticipants.length > 3) {
                this.setState({end: 3})
            }
        }
    }

    getData() {
        let park_name = null;
        let waiverParticipants = [];
        let booking = {};
        let customer = {};

        if (this.state.park != null) {
            park_name = this.state.park.name
        }
        if (this.state.booking != null) {
            booking = this.state.booking
        }
        if (this.state.customer != null) {
            customer = this.state.customer
        }
        if (this.state.waiverParticipants != null) {
            waiverParticipants = this.state.waiverParticipants
        }
        return {park_name, booking, customer, waiverParticipants}
    }

    toggleSeeMore() {
        this.setState({showMore: !this.state.showMore})
    }

    getWaiverPaxContent() {
        let start = this.state.start 
        let end = this.state.end
        
        if (this.state.waiverParticipants.length > 3 && this.state.showMore) {
            end = this.state.waiverParticipants.length
        }
        else {
            end = 3
        }
        const waiverContent = this.state.waiverParticipants.slice(start, end).map(pax => {
            return (
                <table className="table-borderless mb-0" key={pax.id}>
                    <tbody>
                        <tr>
                            <td className="pl-3">
                                <span>{pax.first_name}</span>
                            </td>
                            <td className="pl-3" style={{width: '35px'}}>
                                {
                                    pax.type == 'main' ? 
                                    <span>Adult</span>
                                    : 
                                    <span>Minor</span>
                                }
                            </td>
                            <td className="pl-3" style={{width: "1px"}}>
                                <FaCircle color='#009682'/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        })
        return waiverContent
    }

    checkPersonalInformation(waiverType) {
        this.props.changeWaiverType(waiverType)
    }

    render() {
        const {park_name, booking, customer, waiverParticipants} = this.getData()
        let waiverParticipantsContent;
        if (waiverParticipants.length > 0){
            waiverParticipantsContent = this.getWaiverPaxContent()
        }
        return (
            this.props.WaiverTypeShow ?
                <div className="form-card">
                    <div className="title-content text-center">
                        <h2 className="main-title-txt"><span>{park_name}</span></h2>
                        <h4 className="fs-subtitle"><strong>Choose your e-waiver form type.</strong></h4>
                        <h6 className="fs-subtitle">*Minors: Participants aged 17 Years and Under</h6>
                    </div>
                    <div className="row text-center">
                        <input type="submit" onClick={() => {this.checkPersonalInformation('self_only')}} id="self_only" name="self_only" className="next signing-button sign-type" value="Participating Adult" />
                        <input type="submit" onClick={() => {this.checkPersonalInformation('self_minor')}} id="self_minor" name="self_minor" className="next signing-button sign-type" value="Participating Adult and Minors" />
                        <input type="submit" onClick={() => {this.checkPersonalInformation('minor_child')}} id="minor_child" name="minor_child" className="next signing-button sign-type" value="Participating Minors Only" />
                    </div>
                    <div className="col-md-12 booking-info pt-3">
                        <div className="col-md-8">
                            <div className={`card booking-card-portal`}>
                                <div className="card-body">
                                    <div className="row justify-content-between px-3">
                                        <h5 className="card-title">
                                            <span className="main-card-title">{customer.name}</span> 
                                            <span className="main-card-title"> - </span>
                                            <span className="main-card-title">{booking.name}</span>
                                        </h5>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 fs-subtitle">
                                            Total Participants: <span>{booking.total_participants}</span><br/>
                                            Waiver Received: <span>{booking.total_waiver_participants}</span>
                                        </div>
                                        <div className="col-md-6 fs-subtitle">
                                            {waiverParticipantsContent}
                                            {
                                                waiverParticipants.length > 3 ?
                                                <button type="button" className="btn btn-link" style={{padding: 0}} onClick={() => this.toggleSeeMore()}>
                                                    {
                                                        this.state.showMore ?
                                                        <span style={{fontSize: "11px"}}>Hide</span>
                                                        :
                                                        <span style={{fontSize: "11px"}}>See more</span>
                                                    }
                                                </button>
                                                :
                                                <>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                <></>
        );
    }
}
export default WaiverTypeCard
