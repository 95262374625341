import { combineReducers } from 'redux';
import { parkReducer } from './park';
import { productReducer } from './product'
import { segmentReducer } from './segment'
import { voucherReducer } from './voucher'
import { partnercategoryReducer } from './partner_category'

const rootReducer =  combineReducers({
    parkReducer: parkReducer,
    productReducer: productReducer,
    segmentReducer: segmentReducer,
    voucherReducer: voucherReducer,
    partnercategoryReducer: partnercategoryReducer
})

export default rootReducer