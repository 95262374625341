import {
    FETCH_PARTNER_CATEGORY,
    IS_FETCHING_PARTNER_CATEGORY,
    FETCH_SEGMENT,
    IS_FETCHING_SEGMENT,
    FETCH_PARK,
    IS_FETCHING_PARK,
    FETCH_VOUCHER,
    IS_FETCHING_VOUCHER,
    ERROR,  
} from "./actionTypes";
import {
  api_read_partner_category,
  api_read_segment,
  api_read_voucher,
  api_park_cache 
} from "../api/api";
import axios from "axios";

let params_segment = {
    jsonrpc: "2.0",
    method: "call",
    params: {
        model: "absale.segment",
        domain: [],
        fields: [],
        limit: 0,
        offset: 0,
        sort: "id",
        context: {
        tz: "Asia/Jakarta",
        lang: "en_US",
        uid: 11471
        }
    }
};
let params_parks = {
    jsonrpc: "2.0",
    method: "call",
    params: {
        model: "res.company",
        domain: [["state", "=", "validated"]],
        fields: [
            "name", "two_exp", "email", "logo_web", "website",
            "t_color1", "t_color2", "t_color3", "t_color4",
            "t_google_font_snippets", "t_embed_head_tag", "t_footer", "t_font_to_use"],
        limit: 0,
        offset: 0,
        sort: "id",
        context: {
        tz: "Asia/Jakarta",
        lang: "en_US",
        uid: 11471
        }
    }
};
let params_voucher = {
    jsonrpc: "2.0",
    method: "call",
    params: {
        model: "absale.voucher",
        domain: [[]],
        fields: ["valid_until", "voucher_value"],
        limit: 4,
        offset: 0,
        sort: "id",
        context: {
        tz: "Asia/Jakarta",
        lang: "en_US",
        uid: 11471
        }
    }
};
let params_partner_category  = {
    jsonrpc: "2.0",
    method: "call",
    params: {
        model: "res.partner.category",
        domain: [],
        fields: [],
        limit: 0,
        offset: 0,
        sort: "id",
        context: {
        tz: "Asia/Jakarta",
        lang: "en_US",
        uid: 11471
        }
    }
}

export const fetchSegment = () => {
    const segment = axios.post(api_read_segment, params_segment, {
        headers: { "Content-Type": "application/json;charset=utf-8" }
    });
    return dispatch => {
        dispatch({ type: IS_FETCHING_SEGMENT });
        segment
        .then(({ data }) => {
            dispatch({ type: FETCH_SEGMENT, payload: data.result });
        })
        .catch(err => {
            dispatch({ type: ERROR, payload: err });
        });
    };
};

export const fetchParks = () => {
    const parks = axios.get(api_park_cache);
    return dispatch => {
      dispatch({ type: IS_FETCHING_PARK });
      parks
        .then(({ data }) => {
          dispatch({ type: FETCH_PARK, payload: data.result });
        })
        .catch(err => {
          dispatch({ type: ERROR, payload: err });
        });
    };
};

export const fetchVoucher = (park_id) => {
    params_voucher.params.domain = [["state", "=", "draft"], ["park_id", "=", parseInt(park_id)]]
    const voucher = axios.post(api_read_voucher, params_voucher, {
        headers: {"Content-Type": "application/json;charset=utf-8"}
    });
    return dispatch => {
        dispatch({ type: IS_FETCHING_VOUCHER});
        voucher
            .then(({ data }) => {
                dispatch({ type: FETCH_VOUCHER, payload: data.result});
            })
            .catch(err => {
                dispatch({ type: ERROR, payload: err });
            })
    }
};

export const fetchPartnerCategory = () => {
    const partner_category = axios.post(api_read_partner_category, params_partner_category, {
        headers: {"Content-Type": "application/json;charset=utf-8"}
    });
    return dispatch => {
        dispatch({ type: IS_FETCHING_PARTNER_CATEGORY});
        partner_category
            .then(({ data }) => {
                dispatch({ type: FETCH_PARTNER_CATEGORY, payload: data.result});
            })
            .catch(err => {
                dispatch({ type: ERROR, payload: err });
            })
    }
};
