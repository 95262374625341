import React, { Component } from "react";
import Header from "./helper/Header";

class WaiverConfirmation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.match.params.bookingcode,
        };
    }

    render() {
        // let errorMsg = sessionStorage.getItem('error_message')
        return (
            <>
                <div id="waiver" className="pt-4 pb-4 pl-4">
                    <h1>Oops! Time's Up</h1>
                    {/* {errorMsg} */}
                    <p>
                        It's been over 20 minutes since your session started, so the waiver form is now closed.
                    </p>
                    <p>
                        If you need help, please reach out to us at the park
                    </p>
                    <p>
                        Thank you.
                    </p>
                </div>
            </>
        )
    }
}

export default WaiverConfirmation;
