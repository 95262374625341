import React, { Component } from "react";
import AbsaleCard from "../../OrderProcess/AbsaleCard";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import "react-credit-cards/lib/styles-compiled.css";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "./utils";

import { FaUserAlt, FaInfoCircle } from "react-icons/fa";
import { getErrors } from "../../../helpers/helpers";

let hasActive= false, numberError= "", expireError= "", cvcError= "", shake= "";

class Payment extends Component {
  state = {
    number: "",
    name: "",
    expiry: "",
    cvv: "",
    voucher: "",
    issuer: "",
    focused: "",
    formData: null,
    nameClass: "",
    numberClass: "",
    expireClass: "",
    cvcClass: "",
    checkTerm: false,
    terms_and_conditions: "",
  };

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.PaymentCollapse !== this.props.PaymentCollapse) {
      this.setState({ checkTerm: newProps.enableSubmit })
    }

    let parks = this.props.parks;
    if (parks !== null && parks.length > 0) {
        let park_data = parks.filter(park => park.id == this.props.park_id)[0];
        if (park_data !== undefined) {
            this.setState({
                terms_and_conditions: park_data.terms_and_conditions_link,
            });
        }
    }
  }

  handleInputFocus = ({ target }) => {
    this.setState({
      focused: target.name
    });
  };

  handleInputChange = ({ target }) => {
    if (target.name === "number") {
      target.value = formatCreditCardNumber(target.value);
      if(target.value.length > 0){
        this.setState({numberClass: "not-empty"});
      }else{
        this.setState({numberClass: ""}); 
      }
    } else if (target.name === "expiry") {
      target.value = formatExpirationDate(target.value);
      if(target.value.length > 0){
        this.setState({expireClass: "not-empty"});
      }else{
        this.setState({expireClass: ""}); 
      }
    } else if (target.name === "cvc") {
      target.value = formatCVC(target.value);
      if(target.value.length > 0){
        this.setState({cvcClass: "not-empty"});
      }else{
        this.setState({cvcClass: ""}); 
      }
    }else if (target.name === "name"){
      if(target.value.length > 0){
        this.setState({nameClass: "not-empty"});
      }else{
        this.setState({nameClass: ""}); 
      }
    }

    this.setState({ [target.name]: target.value });
  };

  handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      this.setState({ issuer });
    }
  };

  render() {
    const { cardStyle, numberCircle, cardClass} = this.props;
    const { headCard, cardCollapse } = this.getCardDetail();

    return (
      <AbsaleCard
        cardStyle={cardStyle}
        cardClass={cardClass}
        numberCircle={numberCircle}
        headCard={headCard}
        step="4"
        cardCollapse={cardCollapse}
        collapse={this.props.PaymentCollapse}
        data={this.props.data}
        actionCollapse={this.props.actionCollapse}
        name="PaymentCollapse"
      />
    );
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleCheckbox = (e) => {
    this.setState({ checkTerm: !this.state.checkTerm });
  }

  getError(){
    var ewayError = this.props.payErrorMsg.split("/");
    var msg = "";

    if (ewayError.length == 1) {
        msg = "Internal Error: There was a problem processing your booking, our engineers have been informed and you have not been charged.";
    } else {
        var errorCode = ewayError[0].trim();
        var userErrorMsg = getErrors(errorCode);
        if (userErrorMsg !== undefined) {
            msg = "Incorrect " + userErrorMsg + " entered. Please try again. Eway error code: " + errorCode + ".";
        } else {
            msg = "Error: ("+ errorCode +") " + ewayError[1] + ".";
        }
    }

    const errorMsg = (
      <div>
        <span>Payment Unsuccessful.</span> <br />
        {msg}
      </div>
    );
    return errorMsg;
  }

  getCardDetail() {
    if(this.props.PaymentMethodCardCollapse){
        hasActive=true;
    }
    const headCard = (
      <React.Fragment>
        <div className={hasActive ? "font-weight-bold title" : "font-weight-bold title head-dis"} >Enter Payment method</div>
        <div className="text-secondary hidden">
          _
        </div>
      </React.Fragment>
    );

    const creditInfo = (
        <React.Fragment>
          <FormGroup>
            <Label className="font-color-step5 font-weight-bold">
              Payment
            </Label>
            <br />
            <span>
              <img src="/master_s.png" alt="master"/> &nbsp;
              <img src="/visa_s.png" alt="visa"/> &nbsp;
            </span>
          </FormGroup>{" "}
          <FormGroup>
            <div className="input-group-prepend">
              <div className="input-group">
                <Input
                    type="text"
                    name="name"
                    className={this.state.nameClass+" form-control padl"}
                    required
                    onChange={this.handleInputChange}
                    onFocus={this.handleInputFocus}
                    placeholder="Name on the card"
                    autoComplete="off"
                    disabled={this.props.remaining_payment <= 0}
                />
              </div>
              <i className={this.state.nameClass+" icon-on-input"}>
                <FaUserAlt />
              </i>
            </div>
          </FormGroup>
          <FormGroup>
            <div className={"input-group-prepend "+numberError}>
              <div className="input-group">
                <Input
                  type="tel"
                  name="number"
                  className={this.state.numberClass+" form-control padl"}
                  placeholder="Card Number"
                  pattern="[\d| ]{16,22}"
                  required
                  onChange={this.handleInputChange}
                  onFocus={this.handleInputFocus}
                  autoComplete="off"
                  disabled={this.props.remaining_payment <= 0}
                />
              </div>
              <i className={this.state.numberClass+" icon-on-input"}>
                <FaInfoCircle />
              </i>
            </div>
          </FormGroup>
          <FormGroup className="row">
            <div className={"col-md-5 "+expireError}>
              <Input
                type="tel"
                name="expiry"
                id="expiry"
                className={this.state.expireClass+" form-control"}
                placeholder="MM/YY"
                pattern="\d\d/\d\d"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                autoComplete="off"
                disabled={this.props.remaining_payment <= 0}
              />
            </div>
            <div className={"col-md-5 div-flex "+cvcError}>
              <Input
                type="tel"
                name="cvv"
                className={this.state.cvcClass+" form-control"}
                placeholder="CVV"
                // pattern="\d{3/4}"
                required
                onChange={this.handleInputChange}
                onFocus={this.handleInputFocus}
                autoComplete="off"
                maxLength="3"
                disabled={this.props.remaining_payment <= 0}
              />
            </div>
          </FormGroup>
        </React.Fragment>
      );
    let total = 0;
    this.props.list_recepient.map(recepient=> total = total + recepient.voucher_value)
    const cardCollapse = (
      <Card key="Payment">
        <CardBody>
          <Form
            onSubmit={e => {
              e.preventDefault();
              shake= "";
              this.props.action({
                name: this.state.name,
                cvv: this.state.cvv,
                number: this.state.number,
                expiry: this.state.expiry
              });
              this.setState({ checkTerm: false });
            }}
          >
            {creditInfo}
            <FormGroup check>
              <Label check>

                <Input type="checkbox" checked={this.state.checkTerm} onChange={e => this.handleCheckbox(e.target.value)} />
                <span className="font-color-step5 letterspace">
                  <small className="term-link">
                    I accept{" "}
                    <a href={this.state.terms_and_conditions} target="_blank" rel="noopener noreferrer">
                      the terms and conditions
                    </a>.
                  </small>
                </span>

              </Label>
            </FormGroup>
            <div className={this.props.payError ? "card-step5 pay-error "+shake : "card-step5 "}>
              {/* TODO */}
              
              <Button type="submit" size="lg" disabled={!this.state.checkTerm} block>
                Pay Now ${Math.max(0, total)}
              </Button>
            </div>
          </Form>
          <div className={this.props.payError ? "pay-error-msg" : "no-error"}>
            {this.props.payError ?  this.getError() : ""}
          </div>
        </CardBody>
      </Card>
    );
    return { headCard, cardCollapse };
  }
}

export default Payment;
