import React, { Component } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button} from 'reactstrap';
import { fetchProducts } from "../../actions/products";
import { fetchSegment, fetchParks } from "../../actions/global";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa"
import BreadCrumb from '../Layout/Breadcrumb'
import { Redirect } from "react-router-dom";
import axios from "axios";
import { numberSpinner, colorSetProperty, setGoogleFont, setHeadTag } from "../../helpers/helpers";
import {
    api_user_token,
    url
  } from "../../api/api";
var totalOfparticipant = 0
var timeSpinner = ""
class Step1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            park_id: null,
            product_id: null,
            list_product: [],
            isDisabled: false,
            isLoading: false,
            redirect: false,
            isAccess: false,
            isAccessForbidden: true,
            userPark_id: null,
            product_id_step2: null,
            quantity_step2: null,
            product_from_st: null,
            fromCalender: false,
            park_data: null,
            updatedFont: false,
            updatedHeadTag: false
        };
        this.handleChangeParks = this.handleChangeParks.bind(this);
        this.handleChangeProduct = this.handleChangeProduct.bind(this);
    }
    setRedirect = () => {
        this.setState({
            redirect: true
        });
    };
    renderRedirect = () => {
        if (this.state.redirect) {
            const string = JSON.stringify(this.state.list_product)
            window.localStorage.setItem('product_data', string)
            return (
                <Redirect
                to={{
                    pathname:
                    "/create-booking/" + this.state.userPark_id + "/" + this.state.product_id + "/step2",
                    state: {step1_state:this.state}
                }}
                />
            );
        }
      };
    
    forbiddenPage() {
        return (
            <div className={this.state.isLoading === true ? "loading" : ""}>
                <div className={this.state.isAccessForbidden ? "d-none": ""}>
                    <Container style={{ marginTop: "20%", textAlign: "center"}}>
                        <Row>
                            <Col sm={12}>
                                <div className="text-forbidden">
                                    <h2>Sorry !!! you don't have access for this page</h2>
                                    <a href={url} className="back-button font-weight-bold">
                                        <FaArrowLeft /> Back
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
    
    getUsers = async (token) => {
        const params = {
            jsonrpc: "2.0",
            method: "call",
            params: {	
                "token": token
            }  
        };
        try {
            let res = await axios.post(api_user_token, params, {headers: { "Content-Type": "application/json;charset=utf-8" }})
            let { data } = res
            if (data.result.data.length > 0) {
                this.toggleLoading(false);
                this.setState({isAccess: true})
                this.setState({userPark_id: data.result.company_id})
            } else {
                this.toggleLoading(false);
                this.setState({isAccessForbidden: false})
            }
        } catch(error) {
            this.toggleLoading(false);
            alert(error)
        }
    }
    componentWillReceiveProps(newProps){
        this.toggleLoading(true)
        if (this.props.location.state) {
            window.localStorage.setItem('token', this.props.location.state.token)
            let back_step2 = {...this.props.location.state.step1_state}
            back_step2["product_id_step2"] =this.props.location.state.product[0].id
            back_step2["quantity_step2"] =this.props.location.state.product[0].absale_product_price
         
            this.setState(back_step2)

        } else {
            setTimeout(
                function() {
                    var first = true
                    if (newProps.products.length > 0) {
                        let products = this.state.fromCalender ? newProps.products.filter(obj => obj.company_id[0] === this.state.userPark_id && obj.experience_id.id === this.state.product_from_st) : newProps.products.filter(obj => obj.company_id[0] === this.state.userPark_id)
                        // eslint-disable-next-line
                        products.map(product => {
                            if (this.state.product_from_st) {
                                // eslint-disable-next-line
                                this.state.list_product[0] = product
                                this.toggleLoading(false)
                            } else {
                                if (first) {
                                    first = false
                                    if (!(this.state.list_product instanceof Array)) {
                                        this.state.list_product.push(product)
                                    } else {
                                        // eslint-disable-next-line
                                        this.state.list_product[0] = product
                                    }
                                    this.toggleLoading(false)
                                }
                            }
                        })
                        this.toggleLoading(false)
                    } else {
                        this.toggleLoading(false)
                    }
                }
                .bind(this),
                2000
            );
        }
        // Set park data
        if (this.props != newProps && this.state.userPark_id) {
            let park_data = newProps.parks.filter(park => park.id == this.state.userPark_id)
            this.setState({ park_data: park_data[0] })
        }
    }
    componentDidMount() {
        const { fetchParks, fetchProducts, fetchSegment } = this.props;
        fetchProducts()
        fetchParks();
        fetchSegment();
        if (this.props.location.state) {
            window.localStorage.setItem('token', this.props.location.state.token)

            let back_step2 = {...this.props.location.state.step1_state}
            back_step2["product_id_step2"] =this.props.location.state.product[0].id
            back_step2["quantity_step2"] =this.props.location.state.product[0].absale_product_price
         
            this.setState(back_step2)

        } else {
            const query = new URLSearchParams(this.props.location.search);
            const data = query.get('token')
            const start_time_date = query.get('date')
            const start_time_time = query.get('time')
            const start_time_id = query.get('start_time_id')
            const experience_id = query.get('experience_id')
            const park_id = query.get('park_id')
            if (experience_id !== 'False') {
                this.setState({
                    product_from_st: parseInt(experience_id),
                    park_id: parseInt(park_id),
                    fromCalender: true
                })
            }
            if (start_time_date !== 'False' && start_time_time !== 'False' && start_time_id !== 'False') {
                let val = '{"id":'+ parseInt(start_time_id)+',"date":"'+start_time_date+'","time":"'+start_time_time +'"}'
                window.localStorage.setItem('start_time', val)
            } else {
                window.localStorage.removeItem('start_time')
            }
            window.localStorage.setItem('token', data)
        }
        const token = window.localStorage.getItem('token')
        this.getUsers(token)
    }
    
    componentDidUpdate() {
        // Call Customize
        if (this.state.park_data) {
            this.customizeColor(this.state.park_data);
            // - custom font -
            let snipFont = this.state.park_data.t_google_font_snippets + ' <style> body {' + this.state.park_data.t_font_to_use + '} </style>';
            if (snipFont && this.state.updatedFont == false) {
              setGoogleFont(snipFont);
              this.setState({ updatedFont: true })
            }
            // - head tag - 
            let snipheadtag = this.state.park_data.t_embed_head_tag;
            if (snipheadtag && this.state.updatedHeadTag == false) {
              setHeadTag(snipheadtag);
              this.setState({updatedHeadTag: true})
            }
        }
    }

    customizeColor(park) {
      // Customize color
      const colors = {
          'color1': park.t_color1,
          'color2': park.t_color2,
          'color3': park.t_color3,
          'color4': park.t_color4,
      }
      colorSetProperty(colors);
    }

    handleUpdateQty(state, new_qty) {
        new_qty = new_qty !== "" ? new_qty : 0;
        const states = { ...this.state };

        let productParticipant = states.list_product[0];
        let obj = productParticipant.absale_product_price.indexOf(state);
        let product = productParticipant.absale_product_price[obj];
        new_qty = parseInt(new_qty) | 0;
        new_qty = new_qty > 0 ? new_qty : 0;
        let diff_qty = new_qty - product.qty
        totalOfparticipant = totalOfparticipant + diff_qty
        product.qty = parseInt(new_qty) | 0;
        if (totalOfparticipant > 0) {
            states.isDisabled = true
        } else {
            states.isDisabled = false
        }
        this.setState(states);
    }
    increamentQty(state, new_qty) {
        // totalOfparticipant = totalOfparticipant + 1
        this.handleUpdateQty(state, new_qty)
    }
    decreamentQty(state, new_qty) {
        // totalOfparticipant = totalOfparticipant - 1
        this.handleUpdateQty(state, new_qty)
    }
    handleChangeParks(v, e) {
        this.setState({ park_id: e.target.value });
        ReactDOM.findDOMNode(this.refs[v]).focus();
    }
    handleChangeProduct(v, e) {
        this.setState({ product_id: e.target.value });
        // eslint-disable-next-line
        this.props.products.filter(obj => obj.id === parseInt(e.target.value)).map(product => {
            if (!(this.state.list_product instanceof Array)) {
                this.state.list_product.push(product)
            } else {
                // eslint-disable-next-line
                this.state.list_product[0] = product
            }
        })
    }
    spinnerTimeout() {
        timeSpinner = setTimeout(
          function() {
            this.setState({isLoading: false})
          }
          .bind(this),
          numberSpinner()
        );
    }
    stopSpinnerTimout() {
        clearTimeout(timeSpinner)
    }
    toggleLoading(value) {
        this.setState({isLoading: value});
    }
    buttonMinTemplate(s_product) {
        return (
            <button
                type="button"
                onClick={() => this.decreamentQty(s_product, parseInt(s_product.qty) - 1)}
                className={
                    s_product.qty == 0 ? "btn btn-min btn-krg dis" : "btn btn-min btn-krg"
                }
                >
                -
            </button>
        )
    }
    buttonPlusTemplate(s_product) {
        return (
            <button
            type="button"
            className="btn btn-min btn-plus"
            onClick={() => this.increamentQty(s_product, parseInt(s_product.qty) + 1)}
            >
            +
            </button>
        )
    }
    inputTemplate(s_product) {
        s_product.qty = s_product.qty === undefined ? 0 : s_product.qty;

        return (
            <input
                type="text"
                id="quantity"
                name="quantity"
                className="form-control input-number card quantity"
                value={s_product.qty}
                min="1"
                max="100"
                style={{ textAlign: "center" }}
                onChange={e => this.handleUpdateQty(s_product, e.target.value)}
            />
        )
    }
    render() {
        const forbiddenPage = this.forbiddenPage()
        const { list_product } = this.state
        var segment_product = ""
        if (list_product.length > 0) {
            segment_product = list_product[0].absale_product_price.map(s_p => {
                const buttonmin = this.buttonMinTemplate(s_p)
                const inputTemplate = this.inputTemplate(s_p)
                const buttonplus = this.buttonPlusTemplate(s_p)
                return (
                    <FormGroup key={s_p.id} className="row" style={{ width: "40rem" }}>
                        {this.renderRedirect()}
                        <Col sm={4}>
                            <Label className="col-form-label" for="numberParticipant">
                                <div style={{ color: "#005559" }}>{s_p.segment_name}{s_p.segment_description ? " ("+s_p.segment_description.replace("yrs","").trim()+")":""}</div>
                            </Label>
                        </Col>
                        <Col sm={3}>
                            <div className="div-input-number" style={{ display: "flex", paddingRight: "0px" }}>
                                <span className="col-sm-7 input-group-btn span-min">
                                    {buttonmin}
                                </span>
                                <div className=" col-sm-7 input-group">
                                    {inputTemplate}
                                </div>
                                <span className="col-sm-7 input-group-btn span-plus">
                                    {buttonplus}
                                </span>
                            </div>
                        </Col>
                    </FormGroup>
                )
            })
        }
        // eslint-disable-next-line
        const parks = this.props.parks.map(park => {
            if (park.id == this.state.userPark_id) {
                return (
                    <option value={park.id} key={park.id} selected>
                        {park.name}
                    </option>
                );
            }
          });
        var first = true
        const products = this.props.products
            .filter(obj => obj.company_id[0] == this.state.userPark_id)
            .map(product => {
                let first_product = 0
                if (first) {
                    first = false
                    first_product = product.id
                }
                return (
                    <option value={product.id} key={product.id} selected={this.state.product_id_step2 == product.id || this.state.product_from_st == product.experience_id.id || first_product == product.id}>
                        {product.name}
                    </option>
                );
        });
        const step = "step1"
        return (
            <div className={this.state.isLoading === true ? "loading" : ""}>
                <div className={!this.state.isAccess ? "d-none": ""}>
                    <BreadCrumb step={step}/>
                    <Container style={{ marginTop: 20 }}>
                        <Form >
                            <Row>
                                <Col sm={6}>
                                    <FormGroup row>
                                        <Label for="park" sm={3}>Park</Label>
                                        <Col sm={12}>
                                            <Input
                                            type="select"
                                            name="park"
                                            ref="park"
                                            onChange={event =>
                                                this.handleChangeParks("product", event)
                                            }
                                            readOnly>
                                            {parks}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="park" sm={3}>Product</Label>
                                        <Col sm={12}>
                                            <Input
                                                type="select"
                                                name="product"
                                                ref="product"
                                                onChange={event =>
                                                    this.handleChangeProduct("btnbook", event)
                                                }
                                                >
                                                <option value="">Select a product</option>
                                                {products}
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                </Col>
                                <Col sm={6}>
                                    {/* {segment} */}
                                    {segment_product}
                                </Col>
                                <Col sm={12}>
                                    <Button className="float-right btn font-weight-bold" onClick={this.setRedirect} type="submit" disabled={!this.state.isDisabled}>
                                        NEXT <FaArrowRight />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
                {forbiddenPage}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      parks: state.parkReducer.data,
      products: state.productReducer.data,
      segment: state.segmentReducer.data
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      fetchParks: data => dispatch(fetchParks(data)),
      fetchProducts: data => dispatch(fetchProducts(data)),
      fetchSegment: data => dispatch(fetchSegment(data))
    };
  }
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Step1);
