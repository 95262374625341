import React, { Component } from "react";
import AbsaleCard from "../AbsaleCard";
import Moment from "moment";
import { startTime } from "../../../helpers/helpers";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Popover,
  PopoverBody,
  PopoverHeader
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import { api_read_resource_consumption, api_start_time, api_get_active_discount } from "../../../api/api";
import axios from "axios";
import {
  FaUserAlt,
  FaCalendarDay,
  FaAngleDown,
  FaAngleLeft,
  FaAngleRight,
  FaPlus,
  FaCheck,
  FaWindowClose,
  FaExclamationCircle
} from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import ShowMoreText from "react-show-more-text";
import { enGB } from 'date-fns/locale';

const SCREEN_WIDTH_LIMIT = 1000;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
let valQty = "",
  hasActive = false,
  maxSupervisedExceeded = false; //NEXT_CODE
let selectDateTime = { date: null, time: null };
let selectSecondActivityDateTime = { date: null, time: null };
var cek_onchange = {
  "1": true,
  "2": true,
  "3": true
};

var totalOfparticipant = 0;

var totalAdult = 0;
var totalChild = 0;
let segmentQty = {};

var totalSupervisor = 0
var totalSupervised = [];

let mins = 1000*60;
let mainDelta = { tbuff_before: null, tbuff_after: null };

let displayPopUp = true;
let enableParticipantButton = true;
let currentSegment = null;
let lastInputId = "";

let skipEmptyCompatibleActivity = false;

class ParticipantCard extends Component {
  state = {
    timezone: false,
    productParticipant: this.props.productParticipant,
    modalPartici: false,
    modalDatetime: false,
    participantWarningModal: false,
    participantWarningModalMsg: "",
    sessionDatetimeModal: false,
    sessionDatetimeModalMsg: "",
    secondActWarningModal: false,
    secondActWarningModalMsg: "",
    highlightWithRanges: [],
    dateParticipant: null,
    includeDates: [],
    dateAvalaible: [],
    preference: null,
    popoverOpenPart: false,
    popoverOpenDate: false,
    totalParticipant: 0,
    start_time: [],
    resource_consumption: [],
    rc_start_date: null,
    rc_end_date: null,
    should_get_rc: true,
    componentLoading: false,
    booking_id: false,
    openpopoverSecondActivitys: false,
    secondActivity: null,
    two_exp: 0,
    company_email: "",
    height: window.innerHeight,
    width: window.innerWidth,
    enableTicketOptions: [],
    ticketOption: '',
    ticketOptions: [],
    ticketOptionID: null,
    ticketCollapse: true,
    openpopoverTicketOptions: false,
    classFocus: "ro",
    secondTicketOption: [],
    canOpenTicketOption: true,
    discount_package: [],
    selected_discount_package: [],
    applicable_package: null,
    participantMinimumAgeModal: false,
    participantMinimumAgeModalMsg: "",
    participantSupervisedMaximumQty: 0,
    participantSupervisedInputAge: 0,
    participantMinimumAgeErrorModal: false,
    participantMinimumAgeModalError: "",
    discountSelectorModal: false,
    combo_selected: true,
    discount_selected: false,
    should_change_supervisor: false,
    show_group_discount_poper: false,
    disable_group_discount_poper: false,
    child_under_10: "",
    supervisionConditionMetNoticeMsg: "",
    supervisionConditionMetNotice: false,
    availableSecondActivities: [],
    thisMonthAvailability: [],
    datePickerDate: "",
    openParticipantInfo: false,
    current_month_sessions: []
  };
  
  constructor(props) {
    super(props);
    this.toggleModalPartici = this.toggleModalPartici.bind(this);
    this.toggleModalDatetime = this.toggleModalDatetime.bind(this);
    this.openActivitys = this.openActivitys.bind(this);
    this.closeModal = this.closeModal.bind(this);
    selectDateTime = { date: null, time: null };
    selectSecondActivityDateTime = { date: null, time: null };
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  updateDimensions() {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth
    });
  }
  componentDidUpdate(prevProp, prevState) {
    if(prevProp.ParticipantCardCollapse !== this.props.ParticipantCardCollapse){
      this.setState({
        openParticipantInfo: false
      });
    }
    if (prevProp.productParticipant !== this.props.productParticipant) {
      this.setState({
        ticketOption: '',
        ticketOptionID: null,
        classFocus: "ro",
      })
      let two_exp = 0;
      let company_email = "";
      let timezone = "";

      totalOfparticipant = 0;
      totalAdult = 0;
      totalChild = 0;
      segmentQty = {}

      totalSupervisor = 0;
      totalSupervised = [];

      maxSupervisedExceeded = false;
      this.props.parks_all
        .filter(val => val.id === parseInt(this.props.park_id))
        .map(obj => {
          two_exp = obj.two_exp
          company_email = obj.email
          timezone = obj.timezone
      });
      setTimeout(() => this.openFirstCheck(), 500);
      this.setState({
        productParticipant: this.props.productParticipant,
        two_exp: two_exp,
        company_email: company_email,
        timezone: timezone
      });
    }
    if (prevState.show_group_discount_poper !== this.state.show_group_discount_poper) {
      const group_dc_popover = document.getElementById('group_discount');
      if (group_dc_popover) {
        if (this.state.width > SCREEN_WIDTH_LIMIT){
          document.getElementById('group_discount').parentElement.style.width = "12%"
        }
        else {
          document.getElementById('group_discount_mobile').parentElement.style.width = "50%%"
        }
      }
    }
    if (prevState.totalParticipant !== this.state.totalParticipant) {
      this.setState({ 
          dateParticipant: null,
          secondActivity: null,
      })
      selectDateTime = { time: null, date: null };
      selectSecondActivityDateTime = { time: null, date: null };
    }
    if (prevProp.enableTicketOptions !== this.props.enableTicketOptions) {
      const products = this.props.products_all
      .filter(obj => obj.company_id[0] === parseInt(this.props.park_id))
      
      const ticketOptions_all = [...new Set(products.map(item => item.activity_option_id )).values()]
      let ticketOptions = [...new Map(ticketOptions_all.map((item) => [item["id"], item])).values()]
      ticketOptions = ticketOptions.filter(function(obj) {
        return obj.id
      })

      this.setState({
        ticketOptions:ticketOptions
      })
    }
  }

  openFirstCheck() {
    if (this.props.ParticipantCardCollapse === true) {
      this.setState({
        popoverOpenPart: true
      });
    }
  }
  componentDidMount() {
    this.get_resource_consumption();
    this.get_active_discount();
    window.addEventListener("resize", this.updateDimensions);
  }
  toggleModalPartici() {
    if (this.state.selected_discount_package.length > 0 && this.state.discount_selected) {
      this.state.selected_discount_package.forEach(discount => {
        this.handleUpdatePackageQty(discount, discount.qty, true, true)
      })
      this.state.discount_selected = false;
    }
    displayPopUp = true;
    enableParticipantButton = true;
    this.setState({
      popoverOpenPart: !this.state.popoverOpenPart
    });
  }

  toggleDiscountSelector() {
    this.setState({
      discountSelectorModal: !this.state.discountSelectorModal
    });
  }
  closeModal() {
    this.setState(prevState => ({
      popoverOpenDate: false
    }));
  }

  toggleModalDatetime() {
    // let options = {
    //   timeZone: this.state.timezone,
    //   year: 'numeric',
    //   month: 'numeric',
    //   day: 'numeric',
    //   hour: 'numeric',
    //   minute: 'numeric',
    //   second: 'numeric',
    // },
    // formatter = new Intl.DateTimeFormat([], options);

    if (!this.state.participantMinimumAgeModal) {
      if (this.state.popoverOpenDate === false) {
        const date = selectDateTime.date
          ? new Date(selectDateTime.date)
          : new Date();
        this.gethighlightWithRanges(date, 0, true, true);
      } else {
        this.setState(prevState => ({
          modalDatetime: !prevState.modalDatetime
        }));
        this.setState({
          popoverOpenDate: !this.state.popoverOpenDate
        });
      }
    }
  }

  openActivitys() {
    // May used in the future
    // eslint-disable-next-line    
    let productParticipant = this.state.productParticipant.absale_product_price.filter(
      productParticipant => productParticipant.qty > 0
    );
    if (!this.props.enableTicketOptions || this.state.ticketOption) {
        if (!this.state.openpopoverSecondActivitys == true) {
          document.getElementById('secondActivity').classList.remove('d-none')
        }
        this.setState({
          openpopoverSecondActivitys: !this.state.openpopoverSecondActivitys
        });
    }
  }

  render() {
    const { cardStyle, numberCircle, cardClass } = this.props;
    const modalParticipant = this.getModalParticipant();
    const modalDatetime = this.getModalDateTime();
    const headCard = this.getheadCard();
    const cardCollapse = this.getCardCollapse();
    return (

      <React.Fragment>
        <AbsaleCard
          cardStyle={cardStyle}
          numberCircle={numberCircle}
          headCard={headCard}
          step="3"
          cardClass={cardClass}
          cardCollapse={cardCollapse}
          collapse={this.props.ParticipantCardCollapse}
          data={this.props.data}
          actionCollapse={this.props.actionCollapse}
          name="ParticipantCardCollapse"
          parks={this.props.parks_all}
        />
         {this.getMinimumAgeWarningModal()}
         {this.getParticipantWarningModal()}
         {this.getSecondActWarningModal()}
         {this.getSessionDatetimeWarningModal()}
         {this.getDiscountSelector()}
         {this.getSupervisionSuccessNotice()}
         {this.getMinimumAgeErrorModal()}
         {modalParticipant} {modalDatetime}
      </React.Fragment>
    );
  }

  getSupervisionSuccessNotice() {
    return (
      <div>
          <Modal 
              backdrop={"static"}
              keyboard={false}
              centered={true}
              autoFocus={true}
              zIndex={2000}
              isOpen={this.state.supervisionConditionMetNotice} toggle={this.toggleSupervisionConditionMetNotice}>
              <ModalBody className="modal-warning">
                  <div>
                      {this.state.participantMinimumAgeModalMsg}
                  </div>
                  <br/>
                  <div>
                      <b><i>{this.state.participantSupervisedMaximumQty > 1 ? this.getMinimumAgeNumberContent() : this.getMinimumAgeNumberContent(this.state.child_under_10)}</i></b>
                      <br/>
                  </div>
                  <br/>
                  <div>
                    {this.state.supervisionConditionMetNoticeMsg}
                    <br/>
                    Thank you!
                  </div>
              </ModalBody>
              <ModalFooter className="modal-warning">
                <Button 
                  className="btn btn-secondary" 
                  onClick={() => {
                    this.toggleSupervisionConditionMetNotice()
                    let classSecondactivity = this.checkSecondActivity()
                    if (this.props.enableTicketOptions && classSecondactivity != 'd-none') {
                      let ticketOptions = this.getTicketOptions()
                      
                      if (ticketOptions.length > 0) {
                        this.openTicketOptions();
                      }
                    }
                    else if (classSecondactivity != 'd-none') {
                      this.openActivitys()
                    }
                    else {
                      this.toggleModalDatetime()
                    }
                    }}>OK</Button>
              </ModalFooter>
          </Modal>
      </div >
    );
  }

  getSessionDatetimeWarningModal() {
    return (
        <div>
            <Modal 
                backdrop={"static"}
                keyboard={false}
                centered={true}
                autoFocus={true}
                zIndex={2000}
                isOpen={this.state.sessionDatetimeModal} toggle={this.toggleSessionDateTimeModal}>
                <ModalHeader className="modal-warning">
                    <div>
                        <b>Oops!</b>
                    </div>
                </ModalHeader>
                <ModalBody className="modal-warning">
                    <div>
                        {this.state.sessionDatetimeModalMsg}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-warning">
                    <Button className="btn btn-secondary" onClick={this.toggleSessionDateTimeModal}>OK</Button>
                </ModalFooter>
            </Modal>
        </div >
    );
  }

  get_active_discount = () => {
    const params = {
      jsonrpc: "2.0",
      method: "call",
      params: {
        park_id: this.props.park_id
      }
    };
    axios
      .post(api_get_active_discount, params, {
        headers: { "Content-Type": "application/json;charset=utf-8" }
      })
      .then(({ data }) => {
        this.setState({ discount_package: data.result });
      })
      .catch(({ err }) => {});
  };

  get_resource_consumption = async () => {
    this.props.toggleLoading(true);
    
    if (this.state.rc_start_date == null) {
      var current_date = new Date();
      this.state.rc_start_date = current_date;
    }
    if (this.state.rc_end_date == null) {
      var first_day_of_next_month = Moment().add(1, "months").date(1).startOf("day");
      this.state.rc_end_date = first_day_of_next_month["_d"];
    }
    
      const params = {
        jsonrpc: "2.0",
        method: "call",
        params: {
          model: "absale.resource.consumption",
          domain: [
            ["date", "<", this.state.rc_end_date],
            ["date", ">=", this.state.rc_start_date],
            ["booking_detail_id.park_id", "=", parseInt(this.props.park_id)]
          ],
          fields: [],
          limit: 0,
          offset: 0,
          sort: "id",
          context: {
            tz: "Asia/Jakarta",
            lang: "en_US",
            uid: 11471
          }
        }
      };
      try {
        let res = await axios.post(api_read_resource_consumption, params, {
          headers: { "Content-Type": "application/json;charset=utf-8" }
        });
        let { data } = res;
        if (data.error) {
          alert(data.error.data.message);
        } else {
          var all_data = this.state.resource_consumption.concat(data.result);
          for(var i=0; i<all_data.length; ++i) {
              for(var j=i+1; j<all_data.length; ++j) {
                  if(all_data[i].id === all_data[j].id)
                      all_data.splice(j--, 1);
              }
          }
          this.setState({ resource_consumption: all_data});
        }
        // this.gethighlightWithRanges();
        this.state.should_get_rc = false;
        this.props.toggleLoading(false);
              } catch (err) {
        this.state.should_get_rc = true;
        this.props.toggleLoading(false);
      }
  };

  minimumAgeValidation(child_under_10 = '') {
    let total_adult = this.state.productParticipant.absale_product_price.filter(pax => pax.segment_nature == 'adult' && pax.qty > 0)
    let supervisedRatio 

    // parks with 18+ grown up min age
    let parks = [6, 9, 20]
    
    if (total_adult.length == 0) {
      supervisedRatio = this.populateSupervisedRatio(0, 0)
      total_adult = 0
    }
    else {
      supervisedRatio = this.populateSupervisedRatio(totalAdult, total_adult[0].segment_id[0])

    }
    // only works for 1 segment with minimum age higher than 0
    supervisedRatio = supervisedRatio.filter(obj => obj.minimum_age > 0)
    supervisedRatio.map(sr => {
      sr.qty[0] = this.state.participantSupervisedInputAge
    })
    const populateRatio = this.checkSupervisedRatio(supervisedRatio, totalAdult);
    maxSupervisedExceeded = populateRatio.includes(false) ? false : true;
    if (maxSupervisedExceeded) {
      this.toggleParticipantMinimumAgeModal()
      this.setState({supervisionConditionMetNoticeMsg: 
        "You have met the supervision requirements."
      })
      this.toggleSupervisionConditionMetNotice()
    }
    else {
      let min_age_msg = "Supervision ratio requires 1 participating Grown-Up " 
      let related_segment = this.state.productParticipant.absale_product_price.filter(obj => obj.segment_id[0] == supervisedRatio[0].segmentIds[0])
      if (related_segment[0].segment_min_age == supervisedRatio[0].minimum_age){
        this.setState({participantMinimumAgeModalError: 
          parseInt(this.props.park_id) in parks ? min_age_msg + "(16+)" : min_age_msg + "(18+)" + " per up to " + supervisedRatio[0].ratio + " children " + "(" + supervisedRatio[0].minimum_age + ")."
        })
      }
      else {
        this.setState({participantMinimumAgeModalError: 
          parseInt(this.props.park_id) in parks ? min_age_msg + "(16+)" : min_age_msg + "(18+)" + " per up to " + supervisedRatio[0].ratio + " children " + "(" + related_segment[0].segment_min_age + "-" + supervisedRatio[0].minimum_age + ")."
        })
      }
      this.toggleParticipantMinimumAgeErrorModal()
    }
  }

  getMinimumAgeNumberContent(child_under_10=false) {
    if (child_under_10 == 'Yes') {
      return 'Yes'
    }
    else if (child_under_10 == 'No') {
      return 'No'
    }
    else {
      return this.state.participantSupervisedInputAge
    }
  }
  
  getMinimumAgeErrorModal() {
    return (
      <div>
          <Modal 
              backdrop={"static"}
              keyboard={false}
              centered={true}
              autoFocus={true}
              zIndex={2000}
              isOpen={this.state.participantMinimumAgeErrorModal} toggle={this.toggleParticipantMinimumAgeErrorModal}>
              <ModalBody className="modal-warning">
                  <div>
                      {this.state.participantMinimumAgeModalMsg}
                  </div>
                  <br/>
                  <div>
                      <b><i>{this.state.participantSupervisedMaximumQty > 1 ? this.getMinimumAgeNumberContent() : this.getMinimumAgeNumberContent(this.state.child_under_10)}</i></b>
                      <br/>
                  </div>
                  <br/>
                  <div>
                    {this.state.participantMinimumAgeModalError}
                    <br/>
                  </div>
              </ModalBody>
              <ModalFooter className="modal-warning">
                <Button 
                  className="btn btn-secondary" 
                  onClick={() => {
                    this.toggleParticipantMinimumAgeErrorModal();
                    this.toggleParticipantMinimumAgeModal();
                    this.toggleModalPartici();
                    this.setState({should_change_supervisor: true})
                    }}>OK</Button>
              </ModalFooter>
          </Modal>
      </div >
    );
  }

  checkDiscountSelector = () => {
    if (this.state.two_exp > 0){
      if (this.props.productParticipant.combo_discount) {
        this.setState({
          popoverOpenPart: false
        });
        this.toggleDiscountSelector()
      }
      else {
        this.toggleModalDatetime()
      }
    }
  }

  getDiscountSelector() {
    return (
      <div>
        <Modal 
          backdrop={"static"}
          keyboard={false}
          centered={true}
          autoFocus={true}
          zIndex={2000}
          isOpen={this.state.discountSelectorModal} toggle={this.toggleDiscountSelector}>
          <ModalBody className="modal-warning">
            Get {this.state.two_exp}% off when you book second activity!
          </ModalBody>
          <ModalFooter className="modal-warning">
            <Button 
              className="btn btn-secondary rounded" 
              onClick={() => {
                this.participantValidation('single');
              }}>
                Single Activity
            </Button>
            <Button 
              className="btn btn-secondary rounded" 
              onClick={() => {
                this.participantValidation('combo');
              }}>
                Multi Activity
            </Button>

          </ModalFooter>
        </Modal>
      </div >
    );
  }

  participantValidation = (discount_selector='') => {
    if (this.state.selected_discount_package.length > 0) {
      this.state.selected_discount_package.forEach(discount => {
        this.handleUpdatePackageQty(discount, discount.qty, true)
      })
      this.state.discount_selected = true;
    }
  
    let total_adult = this.state.productParticipant.absale_product_price.filter(pax => pax.segment_nature == 'adult' && pax.qty > 0)
    totalAdult = total_adult.reduce((total, obj) => obj.qty + total, 0)
    
    let supervisedRatio 
    if (total_adult.length == 0) {
      supervisedRatio = this.populateSupervisedRatio(0, 0)
    }
    else {
      supervisedRatio = this.populateSupervisedRatio(total_adult[0].qty, total_adult[0].segment_id[0])
    }
    const populateRatio = this.checkSupervisedRatio(supervisedRatio, totalAdult);
    maxSupervisedExceeded = populateRatio.includes(false) ? false : true;
    let participants = this.state.productParticipant.absale_product_price.filter(p_price => p_price.qty > 0).map(p_price => {
      return p_price.segment_name
    })
    let minimum_age_rule = supervisedRatio.filter(sr => sr.minimum_age > 0).map(sr => {
      if (sr.segment_name.some(r=> participants.includes(r))){
        return sr
      }
    }).filter(function( element ) {
      return element !== undefined;
    });
    let non_minimum_age_rule = supervisedRatio.filter(sr => sr.minimum_age == 0).map(sr => {
      if (sr.segment_name.some(r=> participants.includes(r))){
        return sr
      }
    }).filter(function( element ) {
      return element !== undefined;
    });
    if (!maxSupervisedExceeded && minimum_age_rule.length > 0 && non_minimum_age_rule.length == 0) { 
      let park = parseInt(this.props.park_id)
      let age_from = 0

      if (park == 9) {
        age_from = 8
      }
      else {
        age_from = 8
      }

      if (minimum_age_rule[0].qty > 1) {
        if (minimum_age_rule[0].minimum_age == age_from) {
          this.setState({
              participantMinimumAgeModalMsg:"How many children are aged " + minimum_age_rule[0].minimum_age + " yrs?"
          });
        }
        else {
          this.setState({
              participantMinimumAgeModalMsg:"How many children are aged " + age_from + "-" + minimum_age_rule[0].minimum_age + " yrs?"
          });
        }
      }
      else {
        if (minimum_age_rule[0].minimum_age == age_from) {
          this.setState({
              participantMinimumAgeModalMsg:"Is the child aged " + minimum_age_rule[0].minimum_age +" yrs?"
          });
        }
        else {
          this.setState({
              participantMinimumAgeModalMsg:"Is the child aged " + age_from + "-"  + minimum_age_rule[0].minimum_age +" yrs?"
          });
        }
      }
      this.state.participantSupervisedMaximumQty = minimum_age_rule[0].qty
      this.toggleParticipantMinimumAgeModal()
    }
    else {
      if (maxSupervisedExceeded === false && totalOfparticipant <= 15){
        this.setState({
            participantWarningModalMsg:"Please add a participating Grown-Up to your booking and try again."
        });
        this.toggleParticipantWarningModal()
        this.setState({should_change_supervisor: true})
      }
      else if (
        this.props.products_all.filter(
          obj => obj.company_id[0] === parseInt(this.props.park_id)
        ).length >= 1 && totalOfparticipant <= 15 
      ) {
        if (skipEmptyCompatibleActivity) {
          if (discount_selector == 'combo') {
            this.openActivitys()
          }
          else if (discount_selector == 'single') {
            this.removeSecActivity();
            this.toggleModalDatetime();
          }
        } else {
          if (this.props.enableTicketOptions) {
            if (discount_selector == 'combo') {
              this.openTicketOptions();
            }
            else if (discount_selector == 'single'){
              this.removeSecActivity();
              this.toggleModalDatetime();
            }
            else if (discount_selector == '' && this.getTicketOptions().length > 0) {
              if (this.state.productParticipant.combo_discount) {
                this.toggleDiscountSelector()
              }
              else {
                this.toggleModalDatetime();
              }
            }
          }
          else {
            if (discount_selector == 'single') {
              this.toggleModalDatetime();
            }
            else {
              this.openActivitys();
            }
          }
        }
      } 
      else {
        this.toggleModalDatetime();
      }
    }
    if (discount_selector != '') {
      this.toggleDiscountSelector()
      if (discount_selector == 'combo') {
        this.setState({combo_selected: true})
      }
      else {
        this.setState({combo_selected: false})
      }
    }
    else {
      if (this.state.openpopoverSecondActivitys || this.state.discount_selected){
        this.setState({combo_selected: false})
      }
      if (this.getTicketOptions().length == 0) {

        setTimeout(() => {
          const d = new Date();
          this.toggleModalDatetime()
        }, 500);
      }
    }
  }

  getPackageParticipant(dc_package) {
    let discount_participant = 0;
    if (!dc_package) {
      dc_package = this.state.selected_discount_package
    }
    if (dc_package.length > 0) {
      for (let i = 0; i < dc_package.length; i++) {
        let discount_package = dc_package[i].product.filter(product => product.id == this.state.productParticipant.id)
        if (discount_package.length > 0) {
          for (let j = 0; j < discount_package.length; j++) {
            discount_package[j].segment.forEach(segment => {
              discount_participant += segment.segment_id.min_qty * dc_package[i].qty
            })
          }
        }
      }
    }
    if ( (this.state.totalParticipant + discount_participant) > 15 && this.state.booking_id != null) {
      this.setState({ booking_id: null})
    }
    return discount_participant
  }
  
  getModalParticipant() {
    /*
      disabling eslint check due avoiding error of leak memory
      using setState() will causing never ending setState()
      it's happend if we setState() in rendering process
    */
    // eslint-disable-next-line
    this.state.productParticipant = this.state.productParticipant
      ? this.state.productParticipant
      : this.props.productParticipant;

    let partQty = 0,
      btnDis = true;
    let discount_participant = 0;

    if (!this.state.productParticipant) {
      return <div />;
    } else {
      // eslint-disable-next-line
      this.state.productParticipant.absale_product_price.map(ap => {
        if (ap.qty) {
          partQty += ap.qty;
        }
      });
      if (partQty > 0 || this.state.selected_discount_package.length > 0) {
        btnDis = false;
      }
    }
    const segment = this.getProductHtml();
    const discount = this.getDiscountHtml();
    const content = (
      <><PopoverBody id="segment_popover">
        <Form>
          {" "}
          {discount}
          {discount.length > 0 ? 
            <div className="text-center package-container">
              <h5 id="individual_ticket" name="individual_ticket">
                Individual Tickets
              </h5>
              {
                this.state.productParticipant.available_discount ?
                  this.state.width < SCREEN_WIDTH_LIMIT ? 
                    <Modal
                      id='group_discount_mobile'
                      trigger="legacy"
                      isOpen={this.state.show_group_discount_poper && !this.state.disable_group_discount_poper}
                      target="select-session"
                      className="modal-dialog modal-dialog-centered"
                      placement="bottom"
                    >
                      <ModalBody
                        className="text-center"
                      >
                        You are now eligible for our 10% group discount
                        <span>
                        {this.state.selected_discount_package.length > 0 ? <strong> instead! </strong> : ''}
                        </span>
                        <br/><br/>
                        <Button
                          className="btn btn-secondary"
                          type="submit"
                          onClick={() => {
                            this.setState({show_group_discount_poper: false, disable_group_discount_poper: true})
                          }}>
                          Ok
                        </Button>
                        <br/>
                      </ModalBody>{" "}
                    </Modal>
                    :
                    <Popover
                      id='group_discount'
                      trigger="legacy"
                      isOpen={this.state.show_group_discount_poper && !this.state.disable_group_discount_poper}
                      target="segment_popover"
                      className="not-available"
                    >
                      <PopoverBody
                        className="text-center"
                      >
                      You are now eligible for our 10% group discount
                      <span>
                      {this.state.selected_discount_package.length > 0 ? <strong> instead! </strong> : ''}
                      </span>
                        <br/><br/>
                        <Button
                          className="btn btn-secondary"
                          type="submit"
                          onClick={() => {
                            this.setState({show_group_discount_poper: false, disable_group_discount_poper: true})
                          }}>
                          Ok
                        </Button>
                        <br/>
                      </PopoverBody>{" "}
                    </Popover>
                  : 
                  <>
                  </>
              }
            </div> 
            : 
              this.state.productParticipant.available_discount ?
                this.state.width < SCREEN_WIDTH_LIMIT ? 
                <Modal
                  id='group_discount_mobile'
                  trigger="legacy"
                  isOpen={this.state.show_group_discount_poper && !this.state.disable_group_discount_poper}
                  target="select-session"
                  className="modal-dialog modal-dialog-centered"
                  placement="bottom"
                >
                  <ModalBody
                    className="text-center"
                  >
                    You are now eligible for our 10% group discount
                    <span>
                    {this.state.selected_discount_package.length > 0 ? <strong> instead! </strong> : ''}
                    </span>
                    <br/><br/>
                    <Button
                      className="btn btn-secondary"
                      type="submit"
                      onClick={() => {
                        this.setState({show_group_discount_poper: false, disable_group_discount_poper: true})
                      }}>
                      Ok
                    </Button>
                    <br/>
                  </ModalBody>{" "}
                </Modal>
                :
                <Popover
                  id='group_discount'
                  trigger="legacy"
                  isOpen={this.state.show_group_discount_poper && !this.state.disable_group_discount_poper}
                  target="segment_popover"
                  className="not-available"
                >
                  <PopoverBody
                    className="text-center"
                  >
                  You are now eligible for our 10% group discount
                  <span>
                  {this.state.selected_discount_package.length > 0 ? <strong> instead! </strong> : ''}
                  </span>
                    <br/><br/>
                    <Button
                      className="btn btn-secondary"
                      type="submit"
                      onClick={() => {
                        this.setState({show_group_discount_poper: false, disable_group_discount_poper: true})
                      }}>
                      Ok
                    </Button>
                    <br/>
                  </PopoverBody>{" "}
                </Popover>
              : 
              <>
              </>
          }
          {segment}{" "}
        </Form>{" "}
        </PopoverBody>

        {this.state.totalParticipant + this.getPackageParticipant() > 15 ? (
          <div className="group-notice">
            <b> Group Trip </b> ( more than 15 participants ) <br />
            The booking will be sent as request only.{" "}
          </div>
        ) : (
          ""
        )}{" "}
        <Button
          id='select-session'
          className="btn btn-popover"
          type="submit"
          disabled={btnDis || !enableParticipantButton}
        onClick={() => {
          this.setState({ popoverOpenPart: !this.state.popoverOpenPart });
          let classSecondactivity = this.checkSecondActivity();
          let two_exp = this.state.two_exp
          let allow_combo = this.state.productParticipant.combo_discount
          let scd_acts = this.getSecondActivity();
          if ((classSecondactivity != 'd-none' || (allow_combo && two_exp && scd_acts.length > 0 )) && (this.state.totalParticipant + this.getPackageParticipant()) <= 15) {
            this.checkDiscountSelector();
          }
          else{
            this.participantValidation()
          }
          // if (scd_acts.length == 0) {
          //   if (!this.state.participantMinimumAgeModal) {
          //     setTimeout(() => {
          //       const d = new Date();
          //       this.handleChangeDate(d);
          //     }, 800);
          //   }
          // }
        }}
        >
          CONTINUE AND SELECT A SESSION{" "}
          <i className="icon-on-input fa-angle-down">
            <FaAngleDown />
          </i>{" "}
        </Button>{" "}
      </>
    )
    var poper = (
      <Popover
        trigger="legacy"
        isOpen={this.state.popoverOpenPart && this.props.ParticipantCardCollapse}
        target="numberParticipant"
      >
            
            {content}
      </Popover>
    )

    var modal = (
      <Modal
      trigger="legacy" 
      className="main-activity"
      target="numberParticipant"
      isOpen={this.state.popoverOpenPart && this.props.ParticipantCardCollapse}
      >
        <ModalBody
        className="style-modal">
          {content}
        </ModalBody>
      </Modal>
    );

    if (!segment.length) {
      poper = (
        <Popover
          trigger="legacy"
          isOpen={this.state.popoverOpenPart}
          target="numberParticipant"
          toggle={this.toggleModalPartici}
          className="not-available"
        >
          <PopoverBody>
            <span style={{ color: "red" }}>
              {" "}
              Sorry participants are not available{" "}
            </span>{" "}
          </PopoverBody>{" "}
          <Button className="btn btn-popover" type="submit" disabled={true}>
            CONTINUE AND SELECT A SESSION{" "}
            <i className="icon-on-input fa-angle-down">
              <FaAngleDown />
            </i>{" "}
          </Button>{" "}
        </Popover>
      );

      modal = (
        <Modal
          trigger="legacy"
          isOpen={this.state.popoverOpenPart}
          target="numberParticipant"
          toggle={this.toggleModalPartici}
        >
          <ModalBody>
            <h5 style={{ color: "red", textAlign: "center" }}>
              {" "}
              Sorry participants are not available{" "}
            </h5>{" "}
            <Button className="btn btn-popover" type="submit" disabled={true}>
              CONTINUE AND SELECT A SESSION{" "}
              <i className="icon-on-input fa-angle-down">
                <FaAngleDown />
              </i>{" "}
            </Button>{" "}
          </ModalBody>{" "}
        </Modal>
      );
    }
    
    return (
      <React.Fragment>
        {" "}
        {this.state.width < SCREEN_WIDTH_LIMIT ? modal : poper}{" "}
        {/* {participantCard} */}
      </React.Fragment>
    );
  }

  toggleParticipantWarningModal = () => {
    this.setState({ participantWarningModal: !this.state.participantWarningModal });  
  } 

  toggleParticipantMinimumAgeErrorModal = () => {
    this.setState({ participantMinimumAgeErrorModal: !this.state.participantMinimumAgeErrorModal });  
  } 

  toggleParticipantMinimumAgeModal = () => {
    this.setState({ participantMinimumAgeModal: !this.state.participantMinimumAgeModal });  
  } 

  toggleSupervisionConditionMetNotice = () => {
    this.setState({ supervisionConditionMetNotice: !this.state.supervisionConditionMetNotice });  
  } 

  getMinimumAgeWarningModal() {
    return (
      <div>
        <Modal 
          backdrop={"static"}
          keyboard={false}
          centered={true}
          autoFocus={true}
          zIndex={2000}
          isOpen={this.state.participantMinimumAgeModal} toggle={this.toggleParticipantMinimumAgeModal}>
          <ModalBody className="modal-warning">
              <div>
                  {this.state.participantMinimumAgeModalMsg}
              </div>
          </ModalBody>
          <ModalFooter className="modal-warning">
            { 
              this.state.participantSupervisedMaximumQty > 1 
              ?
              <div className="div-input-number" style={{ width: "50%", paddingLeft: 0, display:"contents"}}>
                <span className="col-sm-3 col-3 input-group-btn span-min">
                  <div>
                    <button className="btn btn-min btn-krg" onClick={() => {
                      if (this.state.participantSupervisedInputAge != 0) {
                        this.setState({participantSupervisedInputAge: this.state.participantSupervisedInputAge - 1})} 
                      }
                    }>
                      -
                    </button>
                  </div>
                </span>{" "}
                <div className="col-sm-3 col-3 input-group">
                  <Input 
                    type="text"
                    // max={this.state.participantSupervisedMaximumQty}
                    value={this.state.participantSupervisedInputAge}
                    className="form-control input-number card quantity text-center " />
                </div>{" "}
                <span className="col-sm-3 col-3 input-group-btn span-plus">
                  <div>
                    <button className="btn btn-min btn-krg" onClick={ () => {
                      if (this.state.participantSupervisedInputAge != this.state.participantSupervisedMaximumQty) {
                        this.setState({participantSupervisedInputAge: this.state.participantSupervisedInputAge + 1})} 
                      }
                    }>
                      +
                    </button>
                  </div>
                </span>{" "}
              </div>
              : 
              <>
                <span className={`col-sm-3 col-3 input-group-btn span-plus`}>
                  <div>
                    <button className="btn btn-secondary" onClick={() => {
                        this.state.participantSupervisedInputAge = 1
                        this.setState({ child_under_10: 'Yes'})
                        this.minimumAgeValidation('Yes');
                    }}>
                      Yes
                    </button>
                  </div>
                </span>
                <span className={`col-sm-3 col-3 input-group-btn span-plus`}>
                  <div>
                    <button className="btn btn-secondary" onClick={()=>{
                      this.state.participantSupervisedInputAge = 0
                      this.setState({ child_under_10: 'No'})
                      this.minimumAgeValidation('No')
                      this.setState({supervisionConditionMetNoticeMsg: 
                        "You have met the supervision requirements."
                      })
                      this.toggleSupervisionConditionMetNotice()
                    }}
                    >
                      No
                    </button>
                  </div>
                </span>
              </>
            }
          </ModalFooter>
          {
            this.state.participantSupervisedMaximumQty > 1 ? 
            <Button className="btn btn-secondary" style={{margin:"20px 55px 15px 50px"}} onClick={() => {this.minimumAgeValidation()}}>
              Validate
            </Button> 
            : 
            ''
          }
        </Modal>
      </div >
    );
  }

  getParticipantWarningModal() {
    return (
        <div>
            <Modal 
                backdrop={"static"}
                keyboard={false}
                centered={true}
                autoFocus={true}
                zIndex={2000}
                onClosed={() => {
                    this.toggleModalPartici();
                }}
                isOpen={this.state.participantWarningModal} toggle={this.toggleParticipantWarningModal}>
                <ModalHeader className="modal-warning">
                    <div>
                        <b>Oops!</b>
                    </div>
                </ModalHeader>
                <ModalBody className="modal-warning">
                    <div>
                        {this.state.participantWarningModalMsg}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-warning">
                    <Button className="btn btn-secondary" onClick={this.toggleParticipantWarningModal}>OK</Button>
                </ModalFooter>
            </Modal>
        </div >
    );
  }

  toggleSecondActWarningModal = () => {
    this.setState({ secondActWarningModal: !this.state.secondActWarningModal });  
  } 

  toggleSessionDateTimeModal = () => {
    this.setState({ sessionDatetimeModal: !this.state.sessionDatetimeModal })
  }

  getSecondActWarningModal() {
    return (
        <div>
            <Modal 
                backdrop={"static"}
                keyboard={false}
                centered={true}
                autoFocus={true}
                zIndex={2000}
                onClosed={() => {
                    this.setState({
                      openpopoverSecondActivitys: !this.state.openpopoverSecondActivitys
                    });
                }}
                isOpen={this.state.secondActWarningModal} toggle={this.toggleSecondActWarningModal}>
                <ModalHeader className="modal-warning">
                    <div>
                        <b>Oops!</b>
                    </div>
                </ModalHeader>
                <ModalBody className="modal-warning">
                    <div>
                        {this.state.secondActWarningModalMsg}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-warning">
                    <div>
                        <Button className="btn btn-secondary modal-warning" onClick={ () => {
                            this.toggleSecondActWarningModal();
                        }}>OK</Button>
                    </div>
                </ModalFooter>
            </Modal>
        </div >
    );
  }

  actionSaveParticipan = (time, price, _is_save=true, ignore_discount_package=false) => {
    let okay = true;
    let mode = 0;

    if (!maxSupervisedExceeded && _is_save) {
      okay = false;
    }
    if (okay) {
      if (this.state.totalParticipant > 15) {
        mode = 2;
      } else {
        mode = 1;
      }
      this.props.action(
        time,
        price,
        this.state.preference,
        mode,
        selectSecondActivityDateTime,
        this.state.secondActivity,
        this.state.two_exp,
        _is_save,
        ignore_discount_package
      );
    } else {
      alert(
        "need at least " +
          Math.ceil(
            (totalChild * 1.0) /
              this.getSupervised().ratio
          ) +
          " adults."
      );
    }
  };

  participantSubmit = (e, time, price, ignore_discount_package=false) => {
    e.preventDefault();
    if ((time.time == '' || time.time == null) && this.state.totalParticipant <= 15) {
      this.toggleSessionDateTimeModal()
      this.setState({sessionDatetimeModalMsg: "To continue, please select a session by clicking on the date chosen for your adventure. "})
    }
    else {
      this.actionSaveParticipan(time, price, true, ignore_discount_package);
    }
  };


  openTicketOptions() {
    this.setState({openpopoverTicketOptions: !this.state.openpopoverTicketOptions, classFocus: "when-focus", ticketCollapse: !this.state.ticketCollapse})
  }

  changeTicketOptionSelected(id, ticketOptions, openTicket=true) {
    // eslint-disable-next-line
    ticketOptions.filter(obj => obj.id === parseInt(id)).map(prod => {
      this.setState({
        ticketOption: prod.name,
        ticketOptionID: prod.id,
        product_id: null,
        product_name: null,
        product_selected: null,
        ticketCollapse: !this.state.ticketCollapse,
      })
    })
    if (openTicket){
      this.openTicketOptions();
    }
    setTimeout(() => this.openActivitys(), 500)
  }

  getTicketOptions() {
    let segment_select = {};
    this.state.productParticipant.absale_product_price
      .filter(state_app => state_app.qty > 0)
      .map(o => segment_select[o.segment_id[0]] = o.qty);

    const currentProductSegment = this.state.productParticipant.absale_product_price.filter( obj =>
            obj.qty > 0
        ).map( obj => {
            return obj.segment_id[0];
        });
    let filteredActivities = this.props.products_all
      .filter(
        obj =>
          obj.company_id[0] === parseInt(this.props.park_id) &&
          obj !== this.props.productSelected &&
          obj.combo_discount == true // excluding the product that cannot be combined
      ).filter(obj => {
            const segments = obj.absale_product_price.map(pp => { return pp.segment_id[0]; });
            if (currentProductSegment.every(elem => segments.includes(elem))) {
              return true
            }
      })
    if (this.state.selected_discount_package.length > 0) {
      let discount_package_product_ids = [];
      let discount_product = [];
      const discount_package = this.state.selected_discount_package
      
      discount_package.forEach(pkg => 
        pkg.product.filter(prod => {
          if (prod.id == this.state.productParticipant.id) {
            discount_product = prod
          }
          discount_package_product_ids.push(prod.id)
        })
      )
      filteredActivities = filteredActivities.filter(obj => 
        discount_package_product_ids.includes(obj.id)
      )
    }
    let ticket_options_all = [...new Set(filteredActivities.map(item => item.activity_option_id )).values()]
    let ticketOptions_filtered = [...new Map(ticket_options_all.map((item) => [item["id"], item])).values()]
    ticketOptions_filtered = ticketOptions_filtered.filter(function(obj) {
      return obj.id
    })
    
    return ticketOptions_filtered
  }

  showPopoverTicketOptions() {
    let ticketOptions_filtered = this.getTicketOptions()
    const activitys = ticketOptions_filtered
    .map(prod => {
      return (
        <div className="no-underline" key={prod.id} >
          <div className="row margin-px">
            <div className="col-9 no-pad activity-text" onClick={() => this.changeTicketOptionSelected(prod.id, ticketOptions_filtered)}>
              <span>{prod.name}</span>
              <p>{prod.description}</p>
            </div>
            <div className="col-3 col-sm-3" style={{marginTop:'auto', marginBottom:'auto', padding:'auto'}}>
              <button className="activity-plus-icon" onClick={() => this.changeTicketOptionSelected(prod.id, ticketOptions_filtered)} style={{width: "35px",
  height: "35px"}}>
                  <b>+</b>
              </button>
            </div>
          </div>
        </div>
      );
    });

    const activitys2 = ticketOptions_filtered
    .map(prod => {
      return (
        <div className="row no-underline" style={{justifyContent:"space-around"}} key={prod.id} >
          <div className={`col-10 ${this.props.park_id != 9 ? 'bg-hover' : 'bg-hover2'}`} onClick={() => this.changeTicketOptionSelected(prod.id, ticketOptions_filtered)}>
            <div className="activity-text" onClick={() => this.changeTicketOptionSelected(prod.id, ticketOptions_filtered)}>
              <span className="">{prod.name}</span>
              <p className="">{prod.description}</p>
            </div>
          </div>
          <div className="no-pad m-auto " style={{maxWidth:"10.66%"}}>
              <button className="activity-plus-icon" onClick={() => this.changeTicketOptionSelected(prod.id, ticketOptions_filtered)} style={{width: "35px",
  height: "35px"}}>
                  <FaPlus style={{fontSize:'12px'}}/>
              </button>
          </div>
        </div>
      );
    });

    var poper = (
      <Popover
        trigger="legacy"
        isOpen={this.state.openpopoverTicketOptions}
        toggle={() => this.openTicketOptions()}
        target="ticketOptions2"
        className="main-activity"
        style={{paddingRight:"1rem"}}
      >
        <PopoverBody>
          {activitys2}
        </PopoverBody>
      </Popover>
    );

    var modal = (
      <Modal
      trigger="legacy" 
      className="main-activity"
      target="ticketOptions2"
      isOpen={this.state.openpopoverTicketOptions}
      toggle={() => this.openTicketOptions()}
      >
        <ModalBody
        className="style-modal">
        {activitys}
        </ModalBody>
      </Modal>
  ); 
    return (
      <React.Fragment>
        {" "}
        {this.state.width < 1000 ? modal : poper}{" "}
      </React.Fragment>
    );
  }

  checkSecondActivity() {
    let classSecondactivity = ''
    let discount_package = [];
    let discount_package_product_ids = [];
    let discount_product = [];
    let segment_ids = [];
    let park_products = [];
    let show_secondactivity = '';
    discount_package = this.state.selected_discount_package
    
    discount_package.forEach(pkg => 
      pkg.product.filter(prod => {
        if (prod.id == this.state.productParticipant.id) {
          discount_product = prod
        }
        discount_package_product_ids.push(prod.id)
      })
    )

    if (discount_package.length > 0) {
      segment_ids = discount_product.segment.map(prod => {
        return prod.segment_id.id
      })

      park_products = this.props.products_all.filter(
        obj => obj.company_id[0] === parseInt(this.props.park_id) && discount_package_product_ids.includes(obj.id) && obj.id != this.state.productParticipant.id
      )
      park_products.forEach(prod => {
        let product_segments = [];
        product_segments = prod.absale_product_price.map(price => {
          return price.segment_id[0]
        })
        if (segment_ids.every(elem => product_segments.includes(elem))) {
          show_secondactivity = true
        }
        else if (show_secondactivity != true) {
          show_secondactivity = false
        }
      })
      if (park_products.length == 0) {
        show_secondactivity = false
      }
    }
    else {
      show_secondactivity = true
    }

    if (show_secondactivity == false) {
      classSecondactivity = "d-none"
    }
    
    let discount_segment_ids = this.state.selected_discount_package.map(disc => {
      return disc.id
    })
    if(totalOfparticipant > 15 ||
      this.props.products_all.filter(
        obj => obj.company_id[0] === parseInt(this.props.park_id)
      ).length <= 1 ||
      this.props.productParticipant.combo_discount == false
    ){
      classSecondactivity = "d-none"
    }

    if (skipEmptyCompatibleActivity) {
        classSecondactivity = "d-none"
    }
    if (!this.state.combo_selected) {
      classSecondactivity = 'd-none' 
    }
    if (this.state.totalParticipant + this.getPackageParticipant() > 15) {
      classSecondactivity = 'd-none' 
    }
    return classSecondactivity
  }

  getCardCollapse() {
    let valDate = "";
    let partStatus = "";
    let dateStatus = "";
    let activityStatus = "";
    let totalPrice = 0;
    let valD = "",
      valT = "";
    let flagDisable = !this.props.enableSubmit;
    valQty = "";
    let valSecActivity = "";
    let discount_package = [];
    let discount_product;
    let ignore_discount_package = false

    let discount_participant;
    if (!this.state.productParticipant) {
      return (
        <Card>
          <CardBody />
        </Card>
      );
    }
    if (this.state.selected_discount_package.length > 0) {
      discount_package = this.state.selected_discount_package
      discount_participant = this.getPackageParticipant(discount_package)
      discount_package.forEach(pkg => 
        pkg.product.filter(prod => {
          if (prod.id == this.state.productParticipant.id) {
            discount_product = prod
          }
        })
      )
    }
    // eslint-disable-next-line
    this.state.productParticipant.absale_product_price.map(app => {
      if (app.qty || discount_product) {
        let dc
        let dc_qty = 0
        if (discount_product) {
          dc = discount_product.segment.filter(el => el.segment_id.id == app.segment_id[0])[0];
          if (dc) {
            dc_qty = dc.segment_id.min_qty * this.state.selected_discount_package[0].qty
          }
        } 
        if (app.qty + dc_qty > 0) {
          let qty = 0
          if (this.state.discount_selected) {
            qty = app.qty
          }
          else {
            qty = app.qty + dc_qty 
          }
          valQty =
            valQty.split(" ").join("_") +
            " " +
            app.segment_name +
            "_" +
            qty +
            " ";
          valQty = valQty
            .trim()
            .split(" ")
            .join(", ")
            .split("_")
            .join(" ");
          totalPrice = totalPrice + app.price * app.qty;
          if (this.state.secondActivity) {
            let secondActivityPrice = this.state.secondActivity.absale_product_price.filter(pl => pl.segment_id[0] == app.segment_id[0])
            totalPrice = totalPrice + secondActivityPrice[0].price * app.qty
          }
  
        }
      }
    });
    // Add total price from discount package
    if (discount_product) {
      if (!this.state.discount_selected) {
        if (discount_participant + totalOfparticipant >= 10) {
          let total = discount_product.total_price * this.state.selected_discount_package.filter(pkg => discount_product.discount_id == pkg.id)[0].qty 
          totalPrice = totalPrice + total
          totalPrice = totalPrice - (totalPrice * 10/100)
        }
        else {
          totalPrice += discount_product.discounted_price * this.state.selected_discount_package.filter(pkg => discount_product.discount_id == pkg.id)[0].qty
        }
      }
      else {
        if (!this.state.secondActivity) {
          if (totalOfparticipant >= 10 && this.state.productParticipant.available_discount) {
            totalPrice = totalPrice - (totalPrice * this.state.productParticipant.discount_product_experience / 100)
          }
          else {
            totalPrice = totalPrice - (discount_product.total_discount * this.state.selected_discount_package.filter(pkg => discount_product.discount_id == pkg.id)[0].qty)
          }
        }
      }
    }
    // Check if have second activity, disable participant discount and add multi discount
    if (this.state.secondActivity) {
      valSecActivity = this.state.secondActivity.name;
      totalPrice = totalPrice - (totalPrice * this.state.two_exp) / 100
    }

    if (selectDateTime.date || selectDateTime.time || this.state.preference) {
      if (selectDateTime.date) {
        valD = Moment(selectDateTime.date).format("D MMM Y");
      }
      if (selectDateTime.time) {
        valT = Moment(selectDateTime.date + " " + selectDateTime.time).format(
          "HH:mm"
        );
      }
      if (this.state.totalParticipant > 15 && this.state.preference) {
        valT = "In the " + this.state.preference;
      }
      valDate = valD + " " + valT;
    }

    if (
      selectSecondActivityDateTime.date ||
      selectSecondActivityDateTime.time &&
      this.state.preference
    ) {
      if (selectSecondActivityDateTime.date) {
        valD = Moment(selectSecondActivityDateTime.date).format("D MMM Y");
      }
      if (selectSecondActivityDateTime.time) {
        valT = Moment(
          selectSecondActivityDateTime.date +
            " " +
            selectSecondActivityDateTime.time
        ).format("HH:mm");
      }
      if (this.state.totalParticipant > 15 && this.state.preference) {
        valT = "In the " + this.state.preference;
      }
      if (valDate.length > 20 && totalOfparticipant <= 15) {
        valDate = valDate + " and " + valT;
      } else {
        valDate = valDate + " " + valT;
      }
    }

    if (this.state.popoverOpenPart) {
      partStatus = 1;
    } else if (!this.state.popoverOpenPart && valQty !== "") {
      partStatus = 2;
    } else {
      partStatus = 0;
    }

    if (this.state.popoverOpenDate) {
      dateStatus = 1;
    } else if (!this.state.popoverOpenDate && valDate !== "") {
      dateStatus = 2;
    } else {
      dateStatus = 0;
    }

    if (this.state.openpopoverSecondActivitys) {
      activityStatus = 1;
    } else if (
      !this.state.openpopoverSecondActivitys &&
      valSecActivity !== ""
    ) {
      activityStatus = 2;
    } else {
      activityStatus = 0;
    }

    if (valQty !== "" && valD !== "" && valT !== "") {
      flagDisable = false;
    }
    if (this.state.secondActivity) {
      if (valDate.length < 18 && totalOfparticipant <= 15) {
        flagDisable = true;
      }
    }
    var place_holder_second_activity = "";

    if (this.state.two_exp > 0) {
      place_holder_second_activity = "Select Activity";
    }
    let classSecondactivity = this.checkSecondActivity()
    if (this.state.combo_selected && this.state.two_exp > 0 && this.state.secondActivity != null) {
      ignore_discount_package = true
    }
    const showPopoverActivitys = this.showPopoverActivitys();
    if (valDate == 'Invalid date ') {
      valDate = ""
    }
    return (
      <Card>
        <CardBody>
          <Form
            onSubmit={e => {
              this.participantSubmit(
                e,
                selectDateTime,
                this.state.productParticipant.absale_product_price,
                ignore_discount_package
              )
              flagDisable = true;
            }}
          >
            <FormGroup>
              {" "}
              {/* <Label for="numberParticipant">Choose Your Participants</Label> */}
              <div className="input-group-prepend">
                <div className="input-group">
                  <Input
                    type="text"
                    onClick={() => this.toggleModalPartici()}
                    name="numberParticipant"
                    id="numberParticipant"
                    className={
                      partStatus === 1
                        ? "when-focus"
                        : partStatus === 2
                        ? "done-focus"
                        : ""
                    }
                    value={valQty}
                    required
                    placeholder="Number of participants"
                    onChange={function() {}}
                    autoComplete="off"
                  />
                  <i className="icon-on-input fa-user-alt">
                    <FaUserAlt />
                  </i>{" "}
                </div>{" "}
              </div>{" "}
              { //popover or modal for participant info
              this.props.ParticipantCardCollapse ?
              this.state.width < SCREEN_WIDTH_LIMIT ? 
                this.state.productParticipant.participant_information && <Modal
                  id='participant_info'
                  trigger="legacy"
                  isOpen={this.state.openParticipantInfo}
                  target="participants_info"
                  className="not-available"
                  backdrop={true}
                >
                  <div className="close-participant-info" onClick={()=> this.setState({openParticipantInfo: !this.state.openParticipantInfo})}>X</div>
                  <ModalBody>
                  {this.state.productParticipant.participant_information &&
                    <div dangerouslySetInnerHTML={ {__html: this.state.productParticipant.participant_information} } /> 
                  }
                  </ModalBody>{" "}
                </Modal> : 
                this.state.productParticipant.participant_information && <Popover
                  id='participant_info'
                  trigger="legacy"
                  isOpen={this.state.openParticipantInfo}
                  target="participants_info"
                  className="not-available"
                >
                  <div className="close-participant-info" onClick={()=> this.setState({openParticipantInfo: !this.state.openParticipantInfo})}>X</div>
                  <PopoverBody
                    style={{padding:"0 25px", width:"250px"}}
                  >
                  {this.state.productParticipant.participant_information && 
                    <div dangerouslySetInnerHTML={ {__html: this.state.productParticipant.participant_information} } /> 
                  }
                  </PopoverBody>{" "}
                </Popover> : ""}{" "}
            </FormGroup>{" "}
            <FormGroup id="secondActivity" className={classSecondactivity}>
              <Label for="secondActivity" className="font-weight-bold">
                {" "}
                Choose a second activity{" "}
              </Label>{" "}
              { this.props.enableTicketOptions ?
                <div>
                  <div className="input-group-prepend">
                    <div className="input-group">
                      <Input
                      type="text"
                      onClick={() => this.openTicketOptions()}
                      name="ticketOptions2"
                      id="ticketOptions2"
                      className={this.state.classFocus}
                      value={this.state.ticketOption}
                      required
                      placeholder="Select Experience"
                      readOnly
                      autoComplete="off"
                      />
                      <i className="icon-on-input fa-user-alt float-right silver-color-icon">
                        <FaPlus onClick={() => this.openTicketOptions()} />
                      </i>
                    </div>
                  </div>
                  { this.props.enableTicketOptions && this.state.canOpenTicketOption ? this.showPopoverTicketOptions() : ""}
                </div>:
                ""
              }
              <br/>
              <div className="input-group-prepend">
                <div className="input-group">
                  <Input
                    type="text"
                    autoComplete="off"
                    name="secondActivity"
                    id="secondActivity"
                    placeholder={place_holder_second_activity}
                    value={valSecActivity}
                    onClick={() => this.openActivitys()}
                    className={
                      activityStatus === 1
                        ? "form-control from-when when-focus"
                        : activityStatus === 2
                        ? "form-control from-when done-focus"
                        : "form-control from-when"
                    }
                    onChange={function() {}}
                  />{" "}
                  <i
                    className={
                      activityStatus === 1
                        ? "icon-on-input fa-calendar-day when-focuss"
                        : activityStatus === 2
                        ? "icon-on-input fa-calendar-day done-focuss"
                        : "icon-on-input fa-calendar-day"
                    }
                  >
                    {valSecActivity ? (
                      <FaWindowClose
                        onClick={() => this.removeSecActivity()}
                      />
                    ) : (
                      <FaPlus onClick={() => this.openActivitys()} />
                    )}
                  </i>{" "}
                </div>{" "}
              </div>{" "}
              {showPopoverActivitys}{" "}
            </FormGroup>
              
            <FormGroup>
              <Label for="date" className="font-weight-bold">
                {" "}
                When{" "}
              </Label>{" "}
              <div className="input-group-prepend">
                <div className="input-group">
                  <Input
                    type="text"
                    autoComplete="off"
                    name="date"
                    id="date"
                    placeholder="Select session"
                    required
                    onClick={() => this.toggleModalDatetime()}
                    className={
                      dateStatus === 1
                        ? "form-control from-when when-focus"
                        : dateStatus === 2
                        ? "form-control from-when done-focus"
                        : "form-control from-when"
                    }
                    value={valDate || ""}
                    onChange={function() {}}
                  />{" "}
                  <i
                    className={
                      dateStatus === 1
                        ? "icon-on-input fa-calendar-day when-focus"
                        : dateStatus === 2
                        ? "icon-on-input fa-calendar-day done-focus"
                        : "icon-on-input fa-calendar-day"
                    }
                  >
                    <FaCalendarDay onClick={() => this.toggleModalDatetime()} />
                  </i>{" "}
                </div>{" "}
              </div>{" "}
            </FormGroup>
            {this.state.totalParticipant > 15 ? (
              <div className="group-text">
                {" "}
                Group booking is sent as request only.{" "}
              </div>
            ) : (
              ""
            )}
            <Button className="btn" type="submit" disabled={flagDisable || !maxSupervisedExceeded}>
              CONTINUE{" "} {flagDisable} {maxSupervisedExceeded}
            </Button>
            <span className="style-total">
              {" "}
              {totalPrice !== 0 && "Total $" + totalPrice.toFixed(2)}{" "}
            </span>{" "}
          </Form>{" "}
        </CardBody>{" "}
      </Card>
    );
  }

  getheadCard() {
    let detail = [];
    if (this.state.productParticipant) {
      var participant = "";
      var date = "";
      var time = "";
      var secondactivitytime = "";
      // eslint-disable-next-line
      var pref = "";
      participant = valQty;

      date = selectDateTime.date
      time = selectDateTime.time
      secondactivitytime = selectSecondActivityDateTime.time

      if (this.state.totalParticipant > 15 && this.state.preference) {
        pref = "In the " + this.state.preference;
      }

      if (!this.props.ParticipantCardCollapse) {
        detail.push(
          <div
            key={"participant-card-1"}
            className="text-secondary row col-md-12"
          >
            <div className="col-md-12 small-text div-flex">
              <div className="icon-on-left small-text">
                {" "}
                {participant !== "" && <FaUserAlt />}{" "}
              </div>{" "}
              <div className="small-text"> {participant} </div>{" "}
            </div>{" "}
            <div className="col-md-12 small-text div-flex">
              <div className="icon-on-left small-text">
                {" "}
                {date !== "" && <FaCalendarDay />}{" "}
              </div>{" "}
              <div className="small-text"> {date} </div>{" "}
            </div>{" "}
            {time && (
              <div className="col-md-12 small-text div-flex">
                <div className="icon-on-left small-text">
                  {" "}
                  {time !== "" && <FaCheck />}{" "}
                </div>{" "}
                <span className="small-text">
                  {" "}
                  {this.state.productParticipant.experience_id.name}{" "}
                </span>
                <span> at {time}</span>
              </div>
            )}
            {secondactivitytime && (
              <div className="col-md-12 small-text div-flex">
                <div className="icon-on-left small-text">
                  {" "}
                  {secondactivitytime !== "" && <FaCheck />}{" "}
                </div>{" "}
                <span className="small-text">
                  {" "}
                  {this.state.secondActivity.experience_id.name}{" "}
                </span>
                <span> at {secondactivitytime}</span>
              </div>
            )}
          </div>
        );
      }
    }

    if (this.props.ParticipantCardCollapse) {
      hasActive = true;
    }

    return (
      <React.Fragment>
        <div
          className={
            hasActive
              ? "font-weight-bold title"
              : "font-weight-bold title head-dis"
          }
        >
          {" "}
          Choose your participants { this.props.productParticipant ? this.props.productParticipant.participant_information && <i style={{cursor: "pointer"}} onClick={()=> this.setState({openParticipantInfo: !this.state.openParticipantInfo})} id="participants_info"><FaExclamationCircle /></i> : ""}

        </div>{" "}
        {detail}{" "}
      </React.Fragment>
    );
  }

  sum(obj) {
    var sum = 0,
        child = 0,
        adult = 0;
    obj.map(qty => {
      sum += qty[0]
      let segId = qty[qty.length - 1];
      let supervisor = qty[qty.length - 2];
      if (this.getSupervised().segmentIds.includes(segId)) {
        child += qty[1];
      }
      if (supervisor.includes(segId)) {
        adult += qty[2];
      }
    })
    return [sum, child, adult];
  }

    populateSupervisedRatio(newQty, newSegment) {
        let productParticipant = this.state.productParticipant;
        let experience_obj = productParticipant.experience_id;
        let supervision_rule = experience_obj.segment_supervision_rule_ids.filter(rule => rule.segment_ids.length > 0 && rule.ticket_required === true);
        let result = []

        let currectParticipantQuantity = {};
        productParticipant.absale_product_price.filter(pp => {
            return pp.qty > 0;
        }).map(pp => {
            currectParticipantQuantity[pp.segment_id[0]] = pp.qty;
        });
        currectParticipantQuantity[newSegment] = newQty;
        supervision_rule.map((item) => {
            let qty = item.segment_ids.map(sid => {
                return currectParticipantQuantity[sid] ? currectParticipantQuantity[sid] : 0;
            });
            result.push({"ratio": item.ratio, "segmentIds": item.segment_ids, "qty": qty, "minimum_age": item.minimum_age, "segment_name": item.segment_names});
        });

        return result;
    }

    checkSupervisedRatio(supervisedRatio, totalAdult) {
        let result = [];
        let adults = 0;
        supervisedRatio.map(sr => {
            for (let i = 0; i < sr.segmentIds.length; i++) {
                var max_per_adult = Math.ceil((sr.qty[i]) * 1.0) / (sr.ratio);
                if (isNaN(max_per_adult)) {
                    max_per_adult = 0;
                }
                let exceeded = totalAdult >= max_per_adult ? true : false;
                // adults +=  Math.ceil(max_per_adult);
                // exceeded = adults > totalAdult ? false : true;
                result.push(exceeded);
            }
        });
        return result;
    }

    handleUpdateQty(state, new_qty) {
        new_qty = new_qty !== "" ? parseInt(new_qty) : 0;

        let productParticipant = this.state.productParticipant;
        let obj = productParticipant.absale_product_price.indexOf(state);
        let experience_obj = productParticipant.experience_id;
        let product = productParticipant.absale_product_price[obj];

        let supervisor = experience_obj.supervisor_segment_ids;
        let supervision_rule = experience_obj.segment_supervision_rule_ids.filter(rule => rule.segment_ids.length > 0);

        let sumChild, sumAdult = 0;


        if (this.getSupervised().segmentIds.includes(state.segment_id[0])) {
            sumChild = new_qty;
        }

        if (supervisor.includes(state.segment_id[0])) {
            sumAdult = new_qty;
        }

        // Store id and qty to global variable
        segmentQty[state.id] = [new_qty, sumChild, sumAdult, supervisor, state.segment_id[0]];
        // Convert values to array for summary loop
        let summary = this.sum(Object.values(segmentQty))

        totalChild = summary[1]
        totalAdult = summary[2]
        totalOfparticipant = summary[0]

        new_qty = parseInt(new_qty) | 0;
        product.qty = parseInt(new_qty) | 0;
        const supervisedRatio = this.populateSupervisedRatio(new_qty, state.segment_id[0]);
        const populateRatio = this.checkSupervisedRatio(supervisedRatio, totalAdult);

        maxSupervisedExceeded = populateRatio.includes(false) ? false : true;

        this.setState({ totalParticipant: totalOfparticipant }); 
        if(this.props.booking_id != null){
            this.actionSaveParticipan(selectDateTime, this.state.productParticipant.absale_product_price, false);
        }
        if (this.getPackageParticipant() + totalOfparticipant >= 10) {
          this.setState({show_group_discount_poper: true})
        }
        else {
          this.setState({show_group_discount_poper: false, disable_group_discount_poper: false})
        }
    }

  getProductHtml() {
    let segment = [];
    if (this.state.productParticipant) {
      this.state.productParticipant.absale_product_price
        .map(ap => {
          const tempProduct = this.getProductTemplate(ap);
          segment.push(tempProduct);
        })
        .sort((a, b) => {
          if (a.sequence > b.sequence) return 1;
          else if (a.sequence < b.sequence) return -1;
          return 0;
        });
    }
    return segment;
  }

  getDiscountHtml() {
    let discount = [];
    for (let i = 0; i < this.state.discount_package.length; i++) {
      let discount_package = this.state.discount_package[i].product.filter(product => product.id == this.state.productParticipant.id)
      
      if (discount_package.length > 0) {
        for (let j = 0; j < discount_package.length; j++) {
          const tempDiscount = this.getDiscountTemplate(discount_package[j], this.state.discount_package[i])
          discount.push(tempDiscount)
        }
      }
    }
    return discount;
  }

  getDiscountTemplate(data, discount) {
    let classIncreaseQty = "btn btn-min btn-krg"
    let classDecreaseQty = "btn btn-min btn-krg"
    let classInputQty = "form-control input-number card quantity text-center"
    let park = this.props.parks_all.find(park => park.id == this.props.park_id)
    if (this.state.selected_discount_package.length > 0) {
      discount = this.state.selected_discount_package.filter(dc => dc.id == discount.id)[0]
    }
    
    discount.qty = discount.qty === undefined ? 0 : discount.qty;

    if (enableParticipantButton == false){
      classIncreaseQty = classIncreaseQty+ " dis";
      classDecreaseQty = classDecreaseQty+ " dis";
      classInputQty = classInputQty + " dis";
    }
    if (discount.qty === 0) {
      classDecreaseQty = classDecreaseQty+ " dis";
    }
    let description = discount.description && <>{discount.description}<br/></>;
    return (
      <div key={discount.id}>
        <FormGroup key={discount.id} className="row s-width" id="asdasd" name="asdasd">
          <Label className="col-sm-6 col-form-label" style={{ width: "50%"}}>
            <div className="s-color">
              {" "}
              {discount.name}{" "}
            </div>{" "}
              <div style={this.props.park_id == 9 ? {color: park.t_color3 } : {color:park.t_color4}} className="font-weight-bold">
                Save {discount.discount_amount_type == 'percentage' ? discount.discount_amount + "%" : '$' + discount.discount_amount}
              </div>
            <div className="b-color s-linew">
              <div className="child-detail">
                {" "}
                {description}
              </div>{" "}
              <small> $ {data.discounted_price.toFixed(2)} </small>{" "}
            </div>{" "}
          </Label>{" "}
          <div className="col-sm-5 div-input-number" style={{ width: "50%"}}>
            <span className="col-sm-7 col-7 input-group-btn span-min">
              {this.getDecreaseButton(discount, classDecreaseQty, false, false, true)}
            </span>{" "}
            <div className=" col-sm-7 col-6 input-group">

              {this.getInputField(discount, classInputQty, false, false, true)}

            </div>{" "}
            <span className="col-sm-7 col-6 input-group-btn span-plus">
              {this.getIncreaseButton(discount, classIncreaseQty, false, false, true)}
            </span>{" "}
          </div>{" "}
        </FormGroup>
      </div>
    );
  }

  onPopUpButtonClick(event) {
      setTimeout(() => {
        displayPopUp = false;
        event.target.parentNode.classList.remove('show');
        enableParticipantButton = true;
        ReactTooltip.hide();
      }, 0);
  }

  getSupervised() {
    let productParticipant = this.state.productParticipant;
    let experience_obj = productParticipant.experience_id;
    let supervision_rule = experience_obj.segment_supervision_rule_ids.filter(rule => rule.segment_ids.length > 0 && rule.ticket_required === true);
    let ratio = 0;
    let segmentIds = []
    supervision_rule.map((item) => {
        ratio = ratio + item.ratio
        segmentIds.push(item.segment_ids[0])
    });
    return {"ratio": ratio, "segmentIds": segmentIds};
  }

  shouldChangeSupervisorColor(data) {
    let productParticipant = this.state.productParticipant;
    let experience_obj = productParticipant.experience_id;
    let segment = data.segment_id[0]
    let supervisor = experience_obj.supervisor_segment_ids;
    let supervision_rule = experience_obj.segment_supervision_rule_ids.filter(rule => rule.segment_ids.length > 0 && rule.ticket_required === true);

    let shouldChangeColor = false;
    if (supervisor.includes(segment)) {
        productParticipant.absale_product_price.map(pp => {
            supervision_rule.map(sr => {
                if (sr.segment_ids.includes(pp.segment_id[0])) {
                    if (pp.qty > 0 && !maxSupervisedExceeded) {
                        shouldChangeColor = true;
                    }
                }
            })
        })
    } 
    return shouldChangeColor
  }

  getProductTemplate(data) {
    let classIncreaseQty = "btn btn-min btn-krg"
    let classDecreaseQty = "btn btn-min btn-krg"
    let classInputQty = "form-control input-number card quantity text-center"
    data.qty = data.qty === undefined ? 0 : data.qty;

    if (this.shouldChangeSupervisorColor(data) && this.state.should_change_supervisor) {
      classIncreaseQty = classIncreaseQty + " btn-secondary btn-supervision-change";
    }

    if (enableParticipantButton == false){
      classIncreaseQty = classIncreaseQty+ " dis";
      classDecreaseQty = classDecreaseQty+ " dis";
      classInputQty = classInputQty + " dis";
    }
    if (data.qty === 0) {
      classDecreaseQty = classDecreaseQty+ " dis";
    }

    let description = data.segment_description && <>{data.segment_description}<br/></>;

      const _afterShow = (event) => {
          enableParticipantButton = false;

          const targets = event.target.id.split("_")
          const product_id = parseInt(targets[1])
          const segment_id = parseInt(targets[3])
          const products = this.state.productParticipant.absale_product_price.filter(p => p.product_id[0]===product_id)
          const new_data = products.filter(s => s.segment_id[0]===segment_id)[0]

          this.handleUpdateQty(new_data, parseInt(new_data.qty) + 1)
          let btn = document.getElementById("tooltip_button");
          btn.onclick = this.onPopUpButtonClick;

          if (currentSegment != segment_id) {
              displayPopUp = true;
          }

          if (displayPopUp == true) {
              displayPopUp = false
              currentSegment = segment_id
          } else {
              event.target.parentNode.classList.remove('show');
              ReactTooltip.hide();
          }

      };

      const _afterShowInput = (event) => {
          enableParticipantButton = false;

          const targets = event.target.id.split("_")
          const product_id = parseInt(targets[1])
          const segment_id = parseInt(targets[3])
          const products = this.state.productParticipant.absale_product_price.filter(p => p.product_id[0]===product_id)
          const new_data = products.filter(s => s.segment_id[0]===segment_id)[0]
          this.handleUpdateQty(new_data, parseInt(new_data.qty))
          let btn = document.getElementById("input_tooltip_button");
          btn.onclick = this.onPopUpButtonClick;
          if (currentSegment != segment_id) {
              displayPopUp = true;
          }
          lastInputId = event.target.id;
          // disable then enable to deselect
          document.getElementById(lastInputId).disabled = true;    

          if (displayPopUp == true) {
              displayPopUp = false
              currentSegment = segment_id
          } else {
              event.target.parentNode.classList.remove('show');
              ReactTooltip.hide();
          }
      };

      const _afterHide = () => {
        enableParticipantButton = true;
        this.setState(this.state);
      };

      const _afterHideInput = (event) => {
        enableParticipantButton = true;
        this.setState(this.state);
          document.getElementById(lastInputId).disabled = false;
      };
    
    return (
      <FormGroup key={data.id} className="row s-width">
        <Label className="col-sm-6 col-form-label" style={{ width: "50%"}}>
          <div className="s-color">
            {" "}
            {data.segment_name}{" "}
          </div>{" "}
          <div className="b-color s-linew">
            <div className="child-detail">
              {" "}
              {description}
            </div>{" "}
            <small> $ {data.price.toFixed(2)} </small>{" "}
          </div>{" "}
        </Label>{" "}
        <div className="col-sm-5 div-input-number" style={{ width: "50%"}}>
          <span className="col-sm-7 col-7 input-group-btn span-min">
            <button
              type="button"
              onClick={() => this.handleUpdateQty(data, parseInt(data.qty) - 1)}
              className={classDecreaseQty}
            >
              {" "}
              {/* <FaMinus /> */} -
            </button>{" "}
          </span>{" "}
          <div className="col-sm-7 col-6 input-group">

            {/*
            <input
              type="text"
              id={`quantity-${data.id}`}
              name="quantity"
              className={classInputQty}
              value={data.qty}
              min="1"
              max="100"
              onChange={e => this.handleUpdateQty(data, e.target.value)}
            />{" "}
            */}

            {this.getInputField(data, classInputQty, _afterShowInput, _afterHideInput, false)}

          </div>{" "}
          <span className="col-sm-7 col-6 input-group-btn span-plus">
            {this.getIncreaseButton(data, classIncreaseQty, _afterShow, _afterHide, false)}
          </span>{" "}
        </div>{" "}
      </FormGroup>
    );
  }
  handleUpdatePackageQty(state, new_qty, handle_qty, reset_pax) {
    const product = this.state.productParticipant
    const package_qty = new_qty !== "" ? parseInt(new_qty) : 0;

    let selected_discount = []
    this.state.discount_package.map(pkg => {
      pkg.product.filter(prod => {
        if (prod.id == this.state.productParticipant.id) {
          selected_discount.push(pkg)
        }
      })
    });

    const product_discount = state.product.filter(prod => {
      if (prod.id == product.id) {
          return prod
      }
    })
    
    let updated_discount_package = selected_discount.map(prod => {
      if (prod.id == state.id) {
        prod.qty = parseInt(package_qty)  
      }
      return prod
    })
    if (parseInt(package_qty) == 0) {
      updated_discount_package = updated_discount_package.filter(obj => {
        return obj.qty != 0
      })
    }

    this.setState({ selected_discount_package: updated_discount_package })
    let discount_participant = this.getPackageParticipant(updated_discount_package)

    product_discount[0].segment.map(segment => {
      let qty = segment.segment_id.min_qty
      let updated_qty = 0
      let pax = product.absale_product_price.filter(
        prod => prod.segment_id[0] == segment.segment_id.id
      )[0]
      if (!reset_pax) {
        if (pax.qty == 0 && package_qty != 0) {
          updated_qty = qty * package_qty
        }
        else {
          updated_qty = pax.qty + (qty * package_qty)
        }
      }
      else {
        updated_qty = pax.qty - (qty * package_qty)
      }
      if (handle_qty) {
        this.handleUpdateQty(pax, updated_qty)
      }
    });
    
    if ( (discount_participant + this.state.totalParticipant >= 10) && this.state.popoverOpenPart) {
      this.setState({show_group_discount_poper: true})
    }
    else {
      this.setState({show_group_discount_poper: false, disable_group_discount_poper: false})
    }
  }
    getInputField(data, classInputQty, afterShow, afterHide, discount=false) {
      if (discount) {
        let discount_product = data['product']
        let inpDiscountId = "ipid_" + discount_product[0].id + "_did_" + data.id
        return (
          <div>
            <input
              type="text"
              id={inpDiscountId}
              name="quantity"
              className={classInputQty}
              value={data.qty}
              min="1"
              max="100"
              onChange={e => this.handleUpdatePackageQty(data, e.target.value)}
            />{" "}
          </div>
        )
      }
      else {
        let inpProductId = "ipid_"+data.product_id[0]+"_sid_"+data.segment_id[0];
        let productParticipant = this.state.productParticipant;
        let obj = productParticipant.absale_product_price.indexOf(data);
        let experience_obj = productParticipant.experience_id;
        let product = productParticipant.absale_product_price[obj];
        let supervision_rule = experience_obj.segment_supervision_rule_ids;
        let rule = supervision_rule.filter(rule => {
            if (rule.segment_ids.includes(product.segment_id[0])) {
                return rule;
            }
        });

        if (rule.length > 0 && rule[0].pop_up_message != false) {
            let popUpMessage = rule[0].pop_up_message;
            return (
                <div>

              <input
                data-tip={"<p>"+popUpMessage+"</p>"}
                data-html={true}
                data-for={"segment_input_tooltip"}
                data-event={"click"}

                type="text"
                id={inpProductId}
                name="quantity"
                className={classInputQty}
                value={data.qty}
                min="1"
                max="100"
                onChange={e => this.handleUpdateQty(data, e.target.value)}
              />{" "}

                <ReactTooltip
                id="segment_input_tooltip"
                getContent={(dataTip) => `${dataTip}<a class='btn btn-secondary' id="input_tooltip_button">OK</a>` }
                overridePosition={ (
                    { left, top },
                    currentEvent, currentTarget, node, 
                    place, desiredPlace, effect, offset) => {
                        const d = document.documentElement;
                        if (this.state.width > SCREEN_WIDTH_LIMIT - 1) {
                            left = Math.min(d.clientWidth - node.clientWidth, left);
                            top = Math.min(d.clientHeight - node.clientHeight, top);
                            left = Math.max(0, left);
                            return { top, left }
                        } else {
                            left = Math.min(d.clientWidth - node.clientWidth, left);
                            top = Math.min(d.clientHeight - node.clientHeight, top);
                            left = Math.max(0, left);
                            top = Math.max(0, top) + 100;
                            return { top, left }
                        }
                    } }
                place={this.state.width > SCREEN_WIDTH_LIMIT ? "right" : "left"}
                clickable={true}
                type="light"
                border={true}
                multiline={true}
                resizeHide={false}
                scrollHide={false}
                wrapper="span"
                effect="solid"
                afterShow={afterShow}
                afterHide={afterHide}
                />
                </div>
            )
        } else {
            return (
              <div>
              <input
                type="text"
                id={inpProductId}
                name="quantity"
                className={classInputQty}
                value={data.qty}
                min="1"
                max="100"
                onChange={e => this.handleUpdateQty(data, e.target.value)}
              />{" "}
                </div>
            )
        }
      }
    }
    getDecreaseButton(data, classDecreaseQty, afterShow, afterHide, discount=false) {
      let discount_product = data['product']
      let inpDiscountId = "ipid_" + discount_product[0].id + "_did_" + data.id
      return (
        <div>
          <button
            id={inpDiscountId}

            type="button"
            className={classDecreaseQty}
            onClick={() => {this.handleUpdatePackageQty(data, parseInt(data.qty) - 1)}}
            >
            {" "}
            {/* <FaPlus /> */} -
          </button>
          {" "}
        </div>
      )
      
    }

  getIncreaseButton(data, classIncreaseQty, afterShow, afterHide, discount=false) {
    if(discount) {
      let discount_product = data['product']
      let inpDiscountId = "ipid_" + discount_product[0].id + "_did_" + data.id

      return (
        <div>
          <button
            id={inpDiscountId}
            type="button"
            className={classIncreaseQty}
            onClick={() => {this.handleUpdatePackageQty(data, parseInt(data.qty) + 1)}}
          >
            {" "}
            {/* <FaPlus /> */} +
          </button>
          {" "}
        </div>
      )
    }
    else {
      let btnProductId = "bpid_"+data.product_id[0]+"_sid_"+data.segment_id[0];
      let productParticipant = this.state.productParticipant;
      let obj = productParticipant.absale_product_price.indexOf(data);
      let experience_obj = productParticipant.experience_id;
      let product = productParticipant.absale_product_price[obj];
      let supervision_rule = experience_obj.segment_supervision_rule_ids;
      let rule = supervision_rule.filter(rule => {
          if (rule.segment_ids.includes(product.segment_id[0])) {
              return rule;
          }
      });

      if (rule.length > 0 && rule[0].pop_up_message != false) {
          let popUpMessage = rule[0].pop_up_message;
          return (
              <div>
              <button
              data-tip={"<p>"+popUpMessage+"</p>"}
              data-html={true}
              data-for={"segment_tooltip"}
              data-event={"click"}
              id={btnProductId}

              type="button"
              style={{padding: 0}}
              className={classIncreaseQty}
              >
              {" "}
              {/* <FaPlus /> */} +
              </button>
              {" "}
              <ReactTooltip
              id="segment_tooltip"
              getContent={(dataTip) => `${dataTip}<a class='btn btn-secondary' id="tooltip_button">OK</a>` }
              overridePosition={ (
                  { left, top },
                  currentEvent, currentTarget, node, 
                  place, desiredPlace, effect, offset) => {
                      const d = document.documentElement;
                      if (this.state.width > SCREEN_WIDTH_LIMIT - 1) {
                          left = Math.min(d.clientWidth - node.clientWidth, left);
                          top = Math.min(d.clientHeight - node.clientHeight, top);
                          left = Math.max(0, left);
                          return { top, left }
                      } else {
                          left = Math.min(d.clientWidth - node.clientWidth, left);
                          left = Math.max(0, left);
                          top = currentEvent.clientY + 30;
                          return { top, left }
                      }
                  } }
              place={this.state.width > SCREEN_WIDTH_LIMIT ? "right" : "left"}
              className="tool-tip-background"
              clickable={true}
              type="light"
              border={true}
              multiline={true}
              resizeHide={false}
              scrollHide={false}
              wrapper="span"
              effect="solid"
              afterShow={afterShow}
              afterHide={afterHide}
              />
              </div>
          )
      } else {
          return (
              <div>
                <button
                  id={btnProductId}
                  style={{padding: 0}}
                  type="button"
                  className={classIncreaseQty}
                  onClick={() => {
                      if (currentSegment != btnProductId.split("_")[3]) {
                          displayPopUp = true;
                      }
                      this.handleUpdateQty(data, parseInt(data.qty) + 1)}
                  }
                  >
                  {" "}
                  {/* <FaPlus /> */} +
                </button>
                {" "}
              </div>
          )
      }
    }
  }

  gethighlightWithRanges = (
    date,
    month_number,
    initial = false,
    popover = false
  ) => {
    if (date == null) {
      if (isNaN(date.getTime())) {
        date = new Date()
      }
    }
    else if ((new Date(date)).getTime() > 0) {
      if (date == 'Invalid date') {
        date = new Date()
      }
    }
    else {
      if (isNaN(date.getTime())) {
        date = new Date()
      }
    }
    date = Moment(date)
      .set({
        date: 1
      })
      .add(month_number, "month")
      .format("YYYY-MM-DD");
    let params = {
      jsonrpc: "2.0",
      method: "call",
      id: null,
      params: {
        date: date,
        experience: this.state.productParticipant.experience_id["id"],
        location: this.state.productParticipant.company_id[0],
        second_experience:
          this.state.secondActivity &&
          this.state.secondActivity.experience_id.id,
      }
    };
    console.log(params);
    const kuotaSchedule = axios.post(api_start_time, params, {
      headers: { "Content-Type": "application/json;charset=utf-8" }
    });
    this.props.toggleLoading(true);
    kuotaSchedule
      .then(({ data }) => {
        var start_time = [];
        var second_start_time = [];
        let unvailable_sessions = [];
        data.result.main_activity.start_time.map(d => {
          start_time.push(d);
        });
        if (data.result.second_activity) {
          data.result.second_activity.start_time.map(d => {
            second_start_time.push(d);
          });

          second_start_time.forEach((sst, index) => {
            if (start_time.filter(x => x.date == sst.date).length == 0) {
              if (!unvailable_sessions.includes(sst.date)) {
                unvailable_sessions.push(sst.date)
              }
            }
          })

          start_time.forEach((st, index) => {
            if (second_start_time.filter(x => x.date == st.date).length == 0) {
              if (!unvailable_sessions.includes(st.date)) {
                unvailable_sessions.push(st.date)
              }
            }
          })

          start_time = start_time.concat(second_start_time)          
          start_time = start_time.filter(x => !unvailable_sessions.includes(x.date))
        }

        this.setState({ start_time: start_time });
        this.setState({datePickerDate: date});

        this.props.toggleLoading(false);

        this.startTimeResult(start_time, true, cek_onchange, 0, true, this.state.timezone);
        this.setState({ popoverOpenDate: false ? initial : true });
        console.log("First Date of Month: " + date)
        console.log("Month: " + parseInt(date.split("-")[1]))
        let current_month = parseInt(date.split("-")[1])
        let available_sessions = start_time.filter(val => val.status != "Full")
        let current_month_sessions = available_sessions.filter(val => parseInt(val.date.split("-")[1]) == current_month)
        this.setState({current_month_sessions: current_month_sessions})
      })
      .catch(err => {
        this.props.toggleLoading(false);
        console.log(err);
      });
  };
  startTimeResult(start_time, checked = true, cek_onchange, value, fe = true, timezone) {
    let state_start_time = startTime(
      start_time,
      checked,
      cek_onchange,
      this.state.resource_consumption,
      value,
      fe,
      totalOfparticipant,
      this.state.productParticipant,
      this.state.secondActivity,
      timezone
    );
    this.setState(state_start_time);
  }
  
  update_rc_date(date) {
    if (date >= this.state.rc_end_date) {
      let start_date = Moment(date).date(1)["_d"];
      let end_date = Moment(date).add(1,"months").date(1)["_d"];
      if (this.state.rc_start_date == start_date && this.state.rc_end_date == end_date) {
        this.state.should_get_rc = false;
      } else {
        this.state.should_get_rc = true;
        this.state.rc_start_date = start_date;
        this.state.rc_end_date = end_date;
      }
    }
  }

  handleChangeDate = async date => {
    this.update_rc_date(date);
    if (this.state.should_get_rc) {
      await this.get_resource_consumption();
    }

    let state = { ...this.state };
    let selected = state.dateParticipant ? Moment(state.dateParticipant) : Moment();
    if (selected.format('M') > Moment(date).format('M')) {
      this.gethighlightWithRanges(date, 0);
    }else if (selected.format('M') < Moment(date).format('M')) {
      this.gethighlightWithRanges(date, 0);
    }
    state.dateParticipant = date;
    selectDateTime = { time: null, date: null };
    selectSecondActivityDateTime = { time: null, date: null };
    this.setState(state);
  };

  getModalDateTime() {
    if (!this.state.productParticipant) {
      return <div />;
    }

    let timeAvalaible = "";
    const todayDate = new Date();
    
    if (this.state.totalParticipant > 15) {
      timeAvalaible = this.getPreferences();
    } else {
      timeAvalaible = this.getTimeAvalaible();
    }

    const poperDate = (
      <Popover
        trigger="legacy"
        isOpen={this.state.popoverOpenDate}
        target="date"
        toggle={this.toggleModalDatetime}
      >
        <PopoverBody className="modal-date-body">
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
                <div className="row col-md-12 col-xs-12 s-head">
                  <div className="col-md-1 col-xs-1 p-0">
                    <button
                      onClick={() => {
                        decreaseMonth();
                        this.gethighlightWithRanges(date, -1);
                      }}
                      disabled={prevMonthButtonDisabled}
                      className="btn-cal prev"
                    >
                      {" "}
                      {<FaAngleLeft />}{" "}
                    </button>{" "}
                  </div>{" "}
                  <div className="col-md-5 colxs-5">
                    {" "}
                    {months[getMonth(date)]} {getYear(date)}{" "}
                  </div>
                  <div className="col-md-1 col-xs-1 float-right p-0">
                    <button
                      className="btn-cal float-right"
                      onClick={() => {
                        increaseMonth();
                        this.gethighlightWithRanges(date, 1);
                      }}
                      disabled={nextMonthButtonDisabled}
                    >
                      {" "}
                      {<FaAngleRight />}{" "}
                    </button>{" "}
                  </div>{" "}
                </div>
              )
              }
            inline
            highlightDates={this.state.highlightWithRanges}
            selected={this.state.dateParticipant ? this.state.dateParticipant : this.state.current_month_sessions.length > 0 ? '' : new Date()}
            onChange={this.handleChangeDate}
            name="dateParticipant"
            includeDates={this.state.includeDates}
            locale={enGB}
            minDate={todayDate}
            calendarStartDay={1}
          />{" "}
          {timeAvalaible}{" "}
        </PopoverBody>{" "}
      </Popover>
    );

    const modalDate = (
      <Modal
        trigger="legacy"
        isOpen={this.state.popoverOpenDate}
        target="date"
        toggle={this.toggleModalDatetime}
      >
        <ModalBody>
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled
            }) => (
              <div className="row d-inline-flex">
                <div className="col-xs-1 pr-2">
                  <button
                    onClick={() => {
                      decreaseMonth();
                      this.gethighlightWithRanges(date, -1);
                    }}
                    disabled={prevMonthButtonDisabled}
                    className="btn-cal prev"
                  >
                    {" "}
                    {"<"}{" "}
                  </button>{" "}
                </div>{" "}
                <div className="col-xs-10">
                  {" "}
                  {months[getMonth(date)]} {getYear(date)}{" "}
                </div>
                <div className="col-xs-1 pl-2">
                  <button
                    className="btn-cal "
                    onClick={() => {
                      increaseMonth();
                      this.gethighlightWithRanges(date, 1);
                    }}
                    disabled={nextMonthButtonDisabled}
                  >
                    {" "}
                    {">"}{" "}
                  </button>{" "}
                </div>{" "}
              </div>
            )}
            inline
            highlightDates={this.state.highlightWithRanges}
            selected={this.state.dateParticipant ? this.state.dateParticipant : this.state.current_month_sessions.length > 0 ? '' : new Date()}
            onChange={this.handleChangeDate}
            name="dateParticipant"
            includeDates={this.state.includeDates}
            locale={enGB}
            minDate={todayDate}
            calendarStartDay={1}
          />{" "}
          {timeAvalaible}{" "}
          { 
            this.state.width < SCREEN_WIDTH_LIMIT && this.state.current_month_sessions.length > 0 ?
              <button
                className="btn btn-popover btn-secondary"
                onClick={this.closeModal}
              >
                {" "}
                Submit{" "}
              </button>
            :
              <></>
          }
        </ModalBody>{" "}
      </Modal>
    );

    return (
      <React.Fragment>
        {" "}
        {this.state.width < SCREEN_WIDTH_LIMIT ? modalDate : poperDate}{" "} 
      </React.Fragment>
    );
  }
  removeSecActivity() {
    let state = { ...this.state };
    state.secondActivity = null;
    state.valSecActivity = "";
    // selectDateTime = { date: null, time: null };
    // state.dateParticipant = null;
    selectSecondActivityDateTime = { date: null, time: null };
    this.setState(state);
  }
    async handleChangesecondActivity(data, price) {
        // check it segments conflict
        let allowed = true;
        let alertMsg = "default"

        let secondProductPrice = data.absale_product_price;

        // check for the second activity rule
        let supervisedRule = data.experience_id.segment_supervision_rule_ids.filter(ruleLine => {
            return ruleLine.ticket_required === true
        });

        let firstProductPrice = this.state.productParticipant.absale_product_price;
        if (supervisedRule.length > 0) {
            // validate each rule for current participants
            supervisedRule.map(ruleLine => {
                const supervisedSegments = ruleLine.segment_ids;
                let total_supervised = 0
                firstProductPrice.filter(fp => supervisedSegments.indexOf(fp.segment_id[0]) >= 0)
                    .map(fp => {
                        total_supervised += fp.qty;
                    })
                let ratio = ruleLine.ratio;
                let maxPerAdult = Math.ceil((total_supervised) * 1.0) / ratio;
                if (ruleLine.minimum_age > 0) {
                  maxPerAdult = 0
                }
                if (isNaN(maxPerAdult)) {
                    maxPerAdult = 0;
                }

                let secondTotalAdult = 0
                const supervisorSegments = data.experience_id.supervisor_segment_ids;
                firstProductPrice.filter(fp => supervisorSegments.indexOf(fp.segment_id[0]) >= 0)
                    .map(fp => {
                        secondTotalAdult += fp.qty;
                    })

                let exceedingStatus = secondTotalAdult >= maxPerAdult ? true : false;
                if (exceedingStatus === false){
                    allowed = false;
                    alertMsg = (
                        <React.Fragment>
                            <div>
                        {secondProductPrice[0].product_id[1]} Supervision: 1 participating Grown-Up per up to {ratio} children is required. Please update your booking.
                            </div>
                        </React.Fragment>
                    );
                }
            });
        }

        if (!allowed) {
            this.setState({
                secondActWarningModalMsg: alertMsg
            })
            this.toggleSecondActWarningModal();
        } else {
            data["price"] = price;
            await this.setState({
                secondActivity: data,
                openpopoverSecondActivitys: false
            });
            this.toggleModalDatetime();
        }
    }
  
  getSecondActivity() {
    const currentProductSegment = this.state.productParticipant.absale_product_price.filter( obj =>
        obj.qty > 0
    ).map( obj => {
        return obj.segment_id[0];
    });

    let filteredActivities = this.props.products_all
      .filter(
        obj =>
          obj.company_id[0] === parseInt(this.props.park_id) &&
          obj !== this.props.productSelected &&
          obj.combo_discount == true // excluding the product that cannot be combined
      ).filter(obj => {
            let valid = true;
            const segments = obj.absale_product_price.map(pp => { return pp.segment_id[0]; });
            for (let i = 0; i < currentProductSegment.length; i++) {
                valid = segments.includes(currentProductSegment[i]);
            }
            return valid
      })
    if (this.state.selected_discount_package.length > 0) {
      let discount_package_product_ids = []
      let discount_package = this.state.selected_discount_package
      discount_package.forEach(pkg => 
        pkg.product.filter(prod => {
          discount_package_product_ids.push(prod.id)
        })
      )
      filteredActivities = filteredActivities.filter(obj => discount_package_product_ids.includes(obj.id))
    }
    return filteredActivities
  }

  showPopoverActivitys() {
    const date = new Date();    
    let segment_select = {};
    this.state.productParticipant.absale_product_price
      .filter(state_app => state_app.qty > 0)
      .map(o => segment_select[o.segment_id[0]] = o.qty);

    const currentProductSegment = this.state.productParticipant.absale_product_price.filter( obj =>
            obj.qty > 0
        ).map( obj => {
            return obj.segment_id[0];
        });

    let filteredActivities = this.getSecondActivity()
    
    skipEmptyCompatibleActivity = false;
    if (filteredActivities < 1) {
        skipEmptyCompatibleActivity = true;
    }

    if (this.state.ticketOption && this.props.enableTicketOptions) {
      filteredActivities = filteredActivities.filter(obj => obj.activity_option_id.id == this.state.ticketOptionID)
    }

    const activitys = filteredActivities.map(prod => {
        let shouldShow = true
        let price = 0;
        prod.absale_product_price
          .filter(
            app =>
              // filter second activity by selected segment
              this.state.productParticipant.absale_product_price
                .filter(state_app => state_app.qty > 0)
                .map(o => o.segment_id[0])
                .includes(app.segment_id[0])
          )
          .map(
            app => {
                let supervisedRule = prod.experience_id.segment_supervision_rule_ids.filter(ruleLine => {
                    return ruleLine.ticket_required === true
                });
                supervisedRule = supervisedRule.sort((a, b) => a.minimum_age - b.minimum_age);
                let firstProductPrice = this.state.productParticipant.absale_product_price;
                if (supervisedRule.length > 0) {
                    // validate each rule for current participants
                    supervisedRule.map(ruleLine => {
                        const supervisedSegments = ruleLine.segment_ids;
                        let total_supervised = 0
                        firstProductPrice.filter(fp => supervisedSegments.indexOf(fp.segment_id[0]) >= 0)
                            .map(fp => {
                                total_supervised += fp.qty;
                            })
                        let ratio = ruleLine.ratio;
                        let maxPerAdult = Math.ceil((total_supervised) * 1.0) / ratio;
                        if (ruleLine.minimum_age > 0) {
                          maxPerAdult = 0
                        }
                        if (isNaN(maxPerAdult)) {
                            maxPerAdult = 0;
                        }
                        let secondTotalAdult = 0
                        const supervisorSegments = prod.experience_id.supervisor_segment_ids;
                        firstProductPrice.filter(fp => supervisorSegments.indexOf(fp.segment_id[0]) >= 0)
                            .map(fp => {
                                secondTotalAdult += fp.qty;
                            })
                        let exceedingStatus = secondTotalAdult >= maxPerAdult ? true : false;
                        shouldShow = exceedingStatus;
                    });
                }
            }
          )
          .filter(app => app !== undefined)
          .map(ap => price = price + ap.price * segment_select[ap.segment_id[0]]);
        if (shouldShow) {
            return (
              <div className="row margin-px" key={prod.id}>
                <div className="col-8 no-pad activity-text" onClick={() =>
                      this.handleChangesecondActivity(prod, price)
                    }>
                  <span> {prod.name} </span>
                  <p> {prod.description} </p>{" "}
                </div>{" "}
                <div className="col-4 no-pad">
                  <button
                    className={`btn btn btn-plus-popover ${this.props.park_id != 9 ? 'bg-hover' : 'bg-hover2'}`}
                    style={{ "minHeight": '3rem' }}
                    onClick={() =>
                      this.handleChangesecondActivity(prod, price)
                    }
                  >
                    <span>ADD</span>
                    <br />
                    <i className="plus-silver-popover">
                      <FaPlus />
                    </i>{" "}
                  </button>{" "}
                </div>{" "}
              </div>
            );
        }
      });

    let isSecAvailable = false;
    activitys.map(act => {
        if (act !== undefined) {
            isSecAvailable = true
        }
    })
    if (!isSecAvailable) {
        skipEmptyCompatibleActivity = true;
    }

    let second_exp_html = (
      <div className="no-underline">
        <p className="font-weight-bold tittlee">
          Save {this.state.two_exp}% on your booking when you book an extra activity
        </p>
        {activitys}

        <div className="row margin-px">
          {/* <div className="col-8 no-pad activity-text">
            <span>Interested in 3 or more activities? Request <a href={"mailto:"+this.state.company_email+"?subject=Booking%20Multiple%20Activities"}>here</a>!</span>
          </div>
          <div className="col-4 no-pad">
        <span className="input-group-btn span-min">
          <button className="btn btn-min btn-krg dis"></button>
        </span>
      </div> */}
        </div>

        <div className="row margin-px">
          <div className="col-12 no-pad">
            <button
              className="btn btn btn-plus-popover bg-hover"
              onClick={() => {
                this.setState({
                  openpopoverSecondActivitys: !this.state
                    .openpopoverSecondActivitys
                });
                setTimeout(() => {
                  this.toggleModalDatetime();
                }, 0);
              }}
            >
              SKIP AND SELECT A SESSION
            </button>
          </div>
        </div>
      </div>
    );
    var poper = (
      <Popover
        trigger="legacy"
        isOpen={this.state.openpopoverSecondActivitys}
        target="secondActivity"
        toggle={this.openActivitys}
      >
        <PopoverBody className="popoverSize"> {second_exp_html} </PopoverBody>{" "}
      </Popover>
    );

    var modal = (
      <Modal
        trigger="legacy"
        isOpen={this.state.openpopoverSecondActivitys}
        target="secondActivity"
        toggle={this.openActivitys}
      >
        <ModalBody className="modalSize"> {second_exp_html} </ModalBody>{" "}
      </Modal>
    );

    return (
      <React.Fragment>
        {" "}
        {this.state.width < SCREEN_WIDTH_LIMIT ? modal : poper}{" "}
      </React.Fragment>
    );
  }

  handleChangeSelectDateTime = data => {
    let state = { ...this.state };
    selectDateTime = data;
    // clear second activity on change
    selectSecondActivityDateTime = { date: null, time: null };
    this.setState(state);
  };

  handleChangeSelectDateTimeSecondActivity = data => {
    let state = { ...this.state };
    selectSecondActivityDateTime = data;
    this.setState(state);
  };

  getTimeAvalaible = () => {
    let res = [];
    let res_second_activity = [];
    let noSession = [];
    // used to put sessions and prevent sessions duplicate displayed
    let sessions = [];
    let sessions2 = [];

    if (this.state.datePickerDate) {
      const dateStr = Moment(this.state.dateParticipant).format("YYYY-MM-DD");
      selectDateTime.date = dateStr;
      let choose_date = this.state.dateAvalaible.filter(
        d =>
          d.date === dateStr &&
          d.experience === this.state.productParticipant.experience_id.name
      );
      if (this.state.current_month_sessions.length > 0) {
        // eslint-disable-next-line
        choose_date.sort().map(cd => {
          let classButton = "btn " + cd.hex_st_color;
          if (cd === selectDateTime) {
            classButton = classButton + "_dark";
          }
          // define needs variables
          let { productParticipant } = this.state;
          // reconstruct date time data
          let main_exp = `${selectDateTime.date} ${selectDateTime.time}`;
          let main_dt = new Date(main_exp);
          // delta session time (buffered before, session+duration, buffered after)
          // main act
          let buff_before = new Date(main_dt.getTime() - productParticipant.experience_id.buffer_time_before*mins)
          let s_duration = new Date(main_dt.getTime() + productParticipant.duration*mins)
          let buff_after = new Date(s_duration.getTime() + productParticipant.experience_id.buffer_time_after*mins)
          mainDelta = { tbuff_before: buff_before, tbuff_after: buff_after };
          // skip sessions if exists
          if (sessions.includes(cd.id)) {
            return false;
          }

          const time_html = (
            <div key={cd.id} className="col-md-3 timeavail-pad">
              <button
                key={cd.id}
                className={classButton}
                disabled={!cd.available_booking}
                onClick={() => this.handleChangeSelectDateTime(cd)}
              >
                <div className="date_day_item">
                  <p style={{ marginBottom: 0 }}> {cd.time} </p>{" "}
                  <small> {cd.status} </small>{" "}
                </div>{" "}
              </button>{" "}
            </div>
          );
          sessions.push(cd.id)
          res.push(time_html);
        });
      } else {
        const time_html = (
          <div key="empty_time" className="col-md-12 timeavail-pad no-session">
            <p className="text-empty-time">
              Sorry! There are no sessions available!
            </p>
          </div>
        );
        const showNextAvail = ( this.state.current_month_sessions.length > 0 ? 
          "" : 
          <div className="btn-next-availability" onClick={() => {
              const d = new Date(this.state.datePickerDate);
              this.handleChangeDate(d.setMonth(d.getMonth() + 1));
          }}>View next month</div>
        );

        res.push(time_html);
        noSession.push(showNextAvail);
      }

      if (this.state.secondActivity && selectDateTime.time != null) {
        let choose_date2 = this.state.dateAvalaible.filter(
          d =>
            d.date === dateStr &&
            d.experience === this.state.secondActivity.experience_id.name
        );
        if (choose_date2.length > 0) {
          // eslint-disable-next-line
          choose_date2.sort().map(cd => {
            let classButton = "btn " + cd.hex_st_color;
            if (cd === selectSecondActivityDateTime) {
              classButton = classButton + "_dark";
            }
            // define needs variables
            let { secondActivity } = this.state;
            let availability = !cd.available_booking;
            let { tbuff_before, tbuff_after } = mainDelta;
            // reconstruct date time data
            let sec_exp = `${cd.date} ${cd.time}`;
            let sec_dt = new Date(sec_exp);
            // delta session time (buffered before, session+duration, buffered after)
            // sec act
            let sec_buff_before = new Date(sec_dt.getTime() - secondActivity.experience_id.buffer_time_before*mins)
            let sec_s_duration = new Date(sec_dt.getTime() + secondActivity.duration*mins)
            let sec_buff_after = new Date(sec_s_duration.getTime() + secondActivity.experience_id.buffer_time_after*mins)
            // disable time
            if (sec_buff_before < tbuff_after && sec_buff_after > tbuff_before) {
              if (!availability) { // check if already not available
                availability = !availability;
              }
            }
            // skip sessions if exists
            if (sessions2.includes(cd.id)) {
              return false;
            }

            const time_html = (
              <div key={cd.id} className="col-md-3 timeavail-pad">
                <button
                  key={cd.id}
                  className={classButton}
                  disabled={availability}
                  onClick={() =>
                    this.handleChangeSelectDateTimeSecondActivity(cd)
                  }
                >
                  <div className="date_day_item">
                    <p style={{ marginBottom: 0 }}> {cd.time} </p>{" "}
                    <small> {cd.status} </small>{" "}
                  </div>{" "}
                </button>{" "}
              </div>
            );
            sessions2.push(cd.id)
            res_second_activity.push(time_html);
          });
        } else {
          const time_html = (
            <div key="empty_time" className="col-md-12 timeavail-pad no-session">
              <p className="text-empty-time">
                Sorry! There are no sessions available!
              </p>
            </div>
          );
          const showNextAvail = ( this.state.start_time.length > 0 ? 
            "" : 
            <div className="btn-next-availability" onClick={() => {
                const d = new Date(this.state.datePickerDate);
                this.handleChangeDate(d.setMonth(d.getMonth() + 1));
            }}>View next month</div>
          );

          noSession.push(showNextAvail);
          res_second_activity.push(time_html);
        }
      }else {
        const time_html = (
          <div key="empty_time" className="col-md-12 timeavail-pad">
            <p className="text-second-need-activate">
              Please select activity above first!
            </p>
          </div>
        );
        res_second_activity.push(time_html);
      }
    }

    if (res.length > 0 || res_second_activity > 0) {
      let titleClass = selectDateTime.time != null ? "text-bold-checkout1" : "text-bold-not-active";
      let second_experience_html = this.state.secondActivity && (
        <React.Fragment>
          <h4 className={titleClass}>
            {" "}
            {this.state.secondActivity.name}{" "}
          </h4>{" "}
          <div className="row mpstyle"> {res_second_activity} </div>{" "}
        </React.Fragment>
      );

      res = (
        <div>
          <hr />
          <div className={this.state.start_time.length > 0 ? "time-available" : "time-available no-session"}>
            <h4 className="text-bold-checkout1">
              {" "}
              {this.state.productParticipant.name}{" "}
            </h4>{" "}
            <div className="row mpstyle"> {res} {noSession}</div> {second_experience_html}{" "}
            <div className="row mpstyle color-status" style={{justifyContent: 'center', paddingLeft: '0px', marginTop: '12px'}}>
              <span className="date_day_green">Available</span>&nbsp; / &nbsp;
              <span className="date_day_orange">Limited</span>&nbsp; / &nbsp;
              <span className="date_day_red">Full</span>
            </div> 
          </div>{" "}
        </div>
      );
    }
    return res;
  };

  handleChangeSelectPref = data => {
    let state = { ...this.state };
    state.preference = data;
    this.setState(state);
  };

  getPreferences = () => {
    let res = [];

    let pref = ["morning", "midday", "afternoon"];

    if (this.state.dateParticipant) {
      const dateStr = Moment(this.state.dateParticipant).format("YYYY-MM-DD");
      selectDateTime.date = dateStr;

      // eslint-disable-next-line
      pref.map(cd => {
        let classButton = "btn date_day_green";
        if (cd === this.state.preference) {
          classButton = classButton + "_dark";
        }

        const time_html = (
          <div key={cd.id} className="col-md-4 group-avail-container">
            <button
              key={cd.id}
              className={classButton}
              onClick={() => this.handleChangeSelectPref(cd)}
            >
              <div className="date_day_item pref-item">
                <p style={{ marginBottom: 0 }}> {cd} </p>{" "}
                <small> Available </small>{" "}
              </div>{" "}
            </button>{" "}
          </div>
        );
        res.push(time_html);
      });
    }
    return (
      <div>
        <hr />
        <div className="time-available">
          <center>
            Select session time (
            {this.props.productParticipant &&
              this.props.productParticipant.duration / 60}{" "}
            h session){" "}
          </center>{" "}
          <div className="row m-0"> {res} </div>{" "}
        </div>{" "}
      </div>
    );
  };
}

export default ParticipantCard;
