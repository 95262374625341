import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import {
    Navbar,
} from 'reactstrap';

class Header extends Component {
    render() {
        return (
            <Navbar className="nav-app" expand="md">
                <Link to="/">TREES ADVENTURE</Link>
                <div className="col-md-10 text-center">
                    <Link to="/">ABOUT US</Link>
                    <Link to="/">LOCATIONS</Link>
                    <Link to="/">GROUPS</Link>
                    <Link to="/">VOUCHERS</Link>
                    <Link to="/">PRICE</Link>
                    <Link to="/">CONTACT</Link>
                </div>
            </Navbar>
        );
    }
}
export default Header