import { FETCH_PRODUCT, IS_FETCHING_PRODUCT, FETCH_PRODUCT_DETAIL, ERROR } from "../actions/actionTypes";

const initialState = {
    data: [],
    fetching: false,
    fetched: false,
    error: null
};

export const productReducer = (state = initialState, action) => {
	switch (action.type) {
		case IS_FETCHING_PRODUCT:
			return { ...state, fetching: true };
		case FETCH_PRODUCT:
			return {
				...state,
				fetched: true,
				fetching: false,
				data: action.payload
			};
		case FETCH_PRODUCT_DETAIL:
			return {
				...state,
				fetched: true,
				fetching: false,
				data: action.payload
			}
		case ERROR:
			return {
				...state,
				fetching: false,
				error: action.payload
			};
		default:
			return state;
	}
};
