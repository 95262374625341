import { IS_FETCHING_PRODUCT, FETCH_PRODUCT, ERROR } from "./actionTypes";
import { api_product_cache } from "../api/api";
import axios from "axios";

export const params= {
  jsonrpc: "2.0",
  method: "call",
  params: {
    published: false
  }
};
export const fetchProducts = (fe) => {
  if (fe) {
    params['params']['published'] = true
  }

  const products = axios.get(api_product_cache);

  return dispatch => {
    dispatch({ type: IS_FETCHING_PRODUCT });
    products
      .then(({ data }) => {
        dispatch({ type: FETCH_PRODUCT, payload: data.result });
      })
      .catch(err => {
        dispatch({ type: ERROR, payload: err });
      });
  };
};