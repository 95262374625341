import React, { Component } from "react";
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, FormFeedback} from "reactstrap";
import { fetchParks, fetchSegment } from "../../actions/global";
import { fetchProducts } from "../../actions/products";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ReactDOM from "react-dom";
import Moment from "moment";
import DatePicker from "react-datepicker";
import axios from "axios";
import { api_start_time } from "../../api/api";

let list_product = null, totalPrice=0, valQty = "", maxChild = false, maxAdult = false, fields=[];//NEXT_CODE

class GroupBooking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            park_id: null, product_id: null, pref: null, productById: null, dateParticipant: null,
            list_product: [], highlightWithRanges: [], includeDates: [], dateAvalaible: [],
            isDisabled: false, isLoading: false, redirect: false,
            name: "", phone: "", email: "", first_name: "",notes: "",
            validate: { emailState: '', fnState: '', nameState: '', phoneState: ''}
        };
        this.handleChangeParks = this.handleChangeParks.bind(this);
        this.handleChangeProduct = this.handleChangeProduct.bind(this);
        this.handleChangeNotes = this.handleChangeNotes.bind(this);
    }
    back = () => {
    }
    
    componentDidMount() {
        const { fetchParks, fetchProducts, fetchSegment } = this.props;
        
        fetchProducts();
        fetchParks();
        fetchSegment();
        this.toggleLoading(false);
    }
    
    handleChange(e) {
        fields[e.target.name] = e.target.value.length;
        this.setState({ [e.target.name]: e.target.value });
        this.fieldCheck(fields);
    }
    handleChangeNumber(e) {
        const value = e.target.value.replace(/[^0-9]+/g, "");
        fields[e.target.name] = e.target.value.length;
        this.setState({ [e.target.name]: value });
        this.fieldCheck(fields);
    }    
    handleChangeDate = date => {
        let state = { ...this.state };
        state.dateParticipant = date;
        this.setState(state);
    };
    handleChangeParks(v, e) {
        this.setState({ park_id: e.target.value });
        ReactDOM.findDOMNode(this.refs[v]).focus();
    }
    handleChangeProduct(v, e) {
        this.setState({ product_id: e.target.value });
        // eslint-disable-next-line
        this.state.list_product[0] = this.props.products
          .filter(obj => obj.id === e.target.value)
          .map(product => {
            return product;
        })
        this.setState({isDisabled: true});
        
        const date = this.state.dateParticipant
        ? new Date(this.state.dateParticipant)
        : new Date();
        this.gethighlightWithRanges(date, 0, true);
    }
    handleChangePref(e) {
        console.log(e.target.value);
        this.setState({ pref: e.target.value });
    }
    handleChangeNotes(e) {
        console.log(e.target.value);
        this.setState({ notes: e.target.value });
    }
    handleUpdateQty(new_qty, key) {
        new_qty = new_qty !== "" ? new_qty : 0;
        const states = { ...this.state };
        list_product = states.list_product;
        new_qty = parseInt(new_qty) | 0;
        new_qty = new_qty > 0 ? new_qty : 0;
        list_product[0].absale_product_price[key].qty = parseInt(new_qty) | 0;   
        this.setState(states);
        
        let childQty = 0;
        let adultQty = -1;
        console.log(list_product);
        // eslint-disable-next-line
        list_product[0].absale_product_price.map(ap => {       
            if(ap.segment_name === "Child"){
                childQty = ap.qty;            
            }
            if(ap.segment_name === "Adult"){
                adultQty = ap.qty;            
            };
            
            if(adultQty >= (Math.ceil(((childQty + 1)*1.0) / list_product[0].experience_id.max_children_per_adult)) && adultQty > 0){
                maxChild = true;
            }else{
                maxChild = false;
            }
            
            if(adultQty > (Math.ceil(((childQty)*1.0) / list_product[0].experience_id.max_children_per_adult)) && adultQty > 0){
                maxAdult = true;
            }else{
                maxAdult = false;
            }
        });
    }
    
    validBlur (e) {
        const { validate } = this.state
        validate.fnState = 'has-success'
        this.setState ({ validate })
    }
    validBlurName (e) {
        const { validate } = this.state
        validate.nameState = 'has-success'
        this.setState ({ validate })
    }
    validBlurPhone (e) {
        const { validate } = this.state
        validate.phoneState = 'has-success'
        this.setState ({ validate })
    }

    toggleLoading(value) {
        this.setState({isLoading: value});
    }
    fieldCheck(fields){
        if(fields['first_name'] != null && fields['name'] != null && fields['email'] != null && fields['phone'] != null){
            if(fields['first_name'] > 0 && fields['name'] > 0 && fields['email'] > 0 && fields['phone'] > 1){
              this.setState({ isDisabled: true });
            }else{
              this.setState({ isDisabled: false });
            }
        }
    }
    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success'
        } else {
            validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }
    
    gethighlightWithRanges = (date, month_number, initial = false) => {
        let state = { ...this.state };
        state.highlightWithRanges = [];
        state.includeDates = [];
        this.setState(state);
        state = { ...this.state };
        date = Moment(date)
          .set({
            date: 1
          })
          .add(month_number, "month")
          .format("YYYY-MM-DD");
        let params = {
          jsonrpc: "2.0",
          method: "call",
          id: null,
          params: {
            month: date,
            experience: list_product[0].experience_id['id'],
            location: list_product[0].company_id[0]
          }
        };
        const kuotaSchedule = axios.post(api_start_time, params, {
          headers: { "Content-Type": "application/json;charset=utf-8" }
        });
        let color = {};
        this.toggleLoading(true);
        kuotaSchedule
          .then(({ data }) => {
            state.dateAvalaible = data.result;
            state.includeDates.push(new Date("1000-01-01"));
            // eslint-disable-next-line
            data.result.map(d => {
              if (!color.hasOwnProperty(d.hex_date_color)) {
                color[d.hex_date_color] = [];
              }
              color[d.hex_date_color].push(new Date(d.date));
              state.includeDates.push(new Date(d.date));
            });
            state.includeDates.push(new Date("3000-01-01"));
            // eslint-disable-next-line
            Object.keys(color).map(key => {
              let val = {};
              val[key] = color[key];
              state.highlightWithRanges.push(val);
            });
            state.popoverOpenDate = true;

            this.setState(state);
            this.toggleLoading(false);
          })
          .catch(err => {
            console.log(err);
          });
    };
    getProductTemplate(data,key,segment) {
        //data.qty = data.qty === undefined ? 0 : data.qty 
        let buttonPlus, buttonMin, input, name, desc;
        data.absale_product_price[key].qty = data.absale_product_price[key].qty === undefined ? 0 : data.absale_product_price[key].qty;
        
        buttonPlus =  <button type="button"
                        disabled={ false }
                        onClick={() => this.handleUpdateQty(parseInt(data.absale_product_price[key].qty) + 1, key)}
                        className={
                          data.absale_product_price[key].segment_nature === "children" && maxChild === false
                            ? "btn btn-min btn-plus dis"
                            : "btn btn-min btn-plus"
                        }> + </button>

        buttonMin =  <button type="button"
                        onClick={() => this.handleUpdateQty(parseInt(data.absale_product_price[key].qty) - 1, key)}
                        className={
                            data.absale_product_price[key].segment_nature === "adult" && maxAdult === false
                              ? "btn btn-min btn-krg dis" 
                              : data.absale_product_price[key].qty === 0 ? "btn btn-min btn-krg dis" 
                              : "btn btn-min btn-krg"
                        }
                        disabled={ false }> - </button>
        input =   <input
                    type="text"
                    id="quantity"
                    name="quantity"
                    className="form-control input-number card quantity"
                    value={data.absale_product_price[key].qty}
                    min="1"
                    max="100"
                    style={{ textAlign: "center" }}
                    onChange={e => this.handleUpdateQty(e.target.value, key)}/>
        name = segment.name;
        desc = segment.description !== "" ? " ("+segment.description.replace("yrs","").trim()+")":"";
        
        
        return (
            <FormGroup key={segment.id} className="row">
                <Col sm={6}>
                    <Label className="col-form-label" for="numberParticipant">
                        <div style={{ color: "#005559" }}>{name}{desc}</div>
                            <div style={{ color: "#009682" }}>
                                <div className="child-detail">
                                {data.absale_product_price[key].segment_nature === "children" && data.experience_id.max_children_per_adult > 0 ? 
                                    "At least 1 adult required per up to "+data.experience_id.max_children_per_adult+" children" : ""
                                }
                                </div>
                                <small>${(data.absale_product_price[key].price).toFixed(2)}</small>
                            </div>
                    </Label>
                </Col>
                <Col sm={5}>
                    <div className="div-input-number" style={{ display: "flex", paddingRight: "0px" }}>
                        <span className="col-sm-7 input-group-btn span-min">
                           {buttonMin} 
                        </span>
                        <div className=" col-sm-7 input-group">
                            {input}
                        </div>
                        <span className="col-sm-7 input-group-btn span-plus">
                            {buttonPlus} 
                        </span>
                    </div>
                </Col>
            </FormGroup>
        );
    }
    
        createNewBooking = async () => {
        this.toggleLoading(true);
        let product = JSON.parse(window.localStorage.getItem('product_data'));
        console.log(product);
        
        try {
            
        } catch(err) {
            console.log(err)
        }

    }
    
    
    render() {
        list_product = this.state.list_product;
        let participants = [];
        // eslint-disbale-next-line
        const segment = this.props.segment.map(segment => {
            if (list_product.length > 0) {
                totalPrice = 0;
                valQty = "";
                // eslint-disable-next-line
                Object.keys(list_product[0].absale_product_price).map(key => {
                    if (list_product[0].absale_product_price[key].segment_id[0] === segment.id) {
                        participants.push(this.getProductTemplate(list_product[0],key,segment));
                    }
                    
                    
                    if (list_product[0].absale_product_price[key].qty) {
                        valQty = valQty.split(' ').join('-') +" "+ list_product[0].absale_product_price[key].segment_name + "-" + list_product[0].absale_product_price[key].qty + " ";
                        valQty = valQty.trim().split(' ').join(', ').split('-').join(' ');
                        totalPrice = totalPrice + list_product[0].absale_product_price[key].qty * list_product[0].absale_product_price[key].price;
                    }
                })
            }
        })
        const parks = this.props.parks.map(park => {
            return (
                <option value={park.id} key={park.id}>
                    {park.name}
                </option>
            );
          });
        const products = this.props.products
            .filter(obj => obj.company_id[0] === this.state.park_id)
            .map(product => {
                return (
                    <option value={product.id} key={product.id}>
                        {product.name}
                    </option>
                );
        });
        return (
            <div className={this.state.isLoading === true ? "loading" : ""}>
                <div className="nav">
                    <div>
                        <Link to="/" > TREES ADVENTURE </Link>
                    </div>
                    <div className={"pointer xxx"} style={{zIndex : "6"}}>
                        <div className="item-beard"></div>
                    </div>
                    <div className={"pointer active"} style={{zIndex : "5"}}>
                        <div className="item-beard">GROUP BOOKING</div>
                    </div>
                    <div className={"pointer active"} style={{zIndex : "4"}} >
                        <div className="item-beard">CONTACT</div>
                    </div>
                </div>
                <div className="group-booking">
                    <Container style={{ marginTop: 20 }}>
                      <Form >
                        <Row>
                          <Col sm={12}>
                            <Row>
                              <Col sm={6}>
                                <FormGroup row>
                                  <Col sm={9}>  
                                    <div className="label-title">Park Location</div>
                                    <Input
                                        type="select"
                                        name="park"
                                        ref="park"
                                        onChange={event =>
                                            this.handleChangeParks("product", event)
                                        }
                                        >
                                        <option value="">Select a park</option>
                                        {parks}
                                     </Input>
                                   </Col>
                                 </FormGroup>
                                 <FormGroup row>
                                  <Col sm={9}>  
                                   <div className="label-title">Product</div>
                                     <Input
                                        type="select"
                                        name="product"
                                        ref="product"
                                        onChange={event =>
                                            this.handleChangeProduct("btnbook", event)
                                        }
                                        >
                                        <option value="">Select a product</option>
                                        {products}
                                     </Input>
                                   </Col>
                                 </FormGroup>
                              </Col>
                              <Col sm={6}>
                                 <div className="label-title">Contact Details</div>
                                 <FormGroup className="field-step3">
                                   <Input
                                     valid={ this.state.validate.fnState === 'has-success' }
                                     autoComplete="off"
                                     type="text"
                                     name="first_name"
                                     id="first_name"
                                     className="form-control step3"
                                     placeholder="First Name*"
                                     required
                                     value={this.state.first_name}
                                     onBlur={ (e) => {
                                       this.validBlur(e)
                                     }}
                                     onChange={ (e) => {
                                       // this.validateText(e)
                                       this.handleChange(e)
                                     }}
                                   />
                                   <FormFeedback className="ftnamevalid" valid>
                                     First Name *
                                   </FormFeedback>
                                 </FormGroup>
                                 <FormGroup className="field-step3">
                                     <Input
                                        valid={ this.state.validate.nameState === 'has-success' }
                                        autoComplete="off"
                                        type="text"
                                        name="name"
                                        id="name"
                                        className="form-control step3"
                                        placeholder="Last Name*"
                                        required
                                        value={this.state.name}
                                        onBlur={ (e) => {
                                          this.validBlurName(e)
                                        }}
                                        onChange={this.handleChange.bind(this)}
                                     />
                                     <FormFeedback className="namevalid" valid>
                                        Last Name *
                                     </FormFeedback>
                                  </FormGroup>
                                  <FormGroup className="field-step3">
                                      <Input
                                        autoComplete="off"
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="form-control step3"
                                        placeholder="Email*"
                                        required
                                        value={this.state.email}
                                        onChange={ (e) => {
                                          // force email to be lower case
                                          e.target.value = e.target.value.toLowerCase();
                                          this.validateEmail(e)
                                          this.handleChange(e)
                                        } }
                                        valid={ this.state.validate.emailState === 'has-success' }
                                        invalid={ this.state.validate.emailState === 'has-danger' }
                                      />
                                      <FormFeedback className="emailvalid" valid>
                                        Email *
                                      </FormFeedback>
                                      <FormFeedback>
                                        Provide a valid email
                                      </FormFeedback>
                                  </FormGroup>
                                  <FormGroup className="field-step3">
                                      <Input
                                        valid={ this.state.validate.phoneState === 'has-success' }
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        className="form-control step3"
                                        placeholder="Mobile*"
                                        required
                                        value={this.state.phone}
                                        onBlur={ (e) => {
                                          this.validBlurPhone(e)
                                        }}
                                        onChange={this.handleChangeNumber.bind(this)}
                                      />
                                      <FormFeedback className="phonevalid" valid>
                                        Phone *
                                      </FormFeedback>
                                      <FormFeedback>
                                        Provide a valid mobile number
                                      </FormFeedback>
                                  </FormGroup>
                              </Col>
                            </Row>
                          </Col> 
                        </Row>
                        <Row>
                           <Col sm={12}>
                             <Row>
                               <Col sm={12}>
                                 <div className="label-title fg-margin-bottom">Participants and Date</div>
                                   </Col>
                                     <Col sm={6}>
                                        <div>Participants</div>
                                         <div className="div-participant">
                                            {participants}
                                         </div>
                                        <span className="style-total">
                                          {totalPrice !== 0 && "Total $" + totalPrice.toFixed(2)}
                                        </span>
                                     </Col>
                                     <Col sm={6}>
                                            <FormGroup>
                                                <Label for="dateParticipant" sm={4}>Desired Date</Label>
                                                <DatePicker
                                                    highlightDates={this.state.highlightWithRanges}
                                                    selected={this.state.dateParticipant}
                                                    onChange={this.handleChangeDate}
                                                    includeDates={this.state.includeDates}
                                                    name="dateParticipant"
                                                    className="form-control"
                                                    autoComplete="false" />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="preferences" sm={4}>Preferences</Label>
                                                <Input
                                                    type="select"
                                                    name="preferences"
                                                    ref="preferences"
                                                    className="col-sm-8 inline"
                                                    onChange={event =>
                                                        this.handleChangePref(event)
                                                    }>
                                                    <option value="">Choose session</option>
                                                    <option value="morning" key="pref1">Morning</option>
                                                    <option value="midday" key="pref2">Mid Day</option>
                                                    <option value="afternoon" key="pref3">Afternoon</option>
                                                </Input>
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="Notes" sm={4}>Notes</Label>
                                                <textarea className="col-sm-8 inline" value={this.state.notes} onChange={this.handleChangeNotes} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col sm={10}>
                                    <Button className="float-right btn font-weight-bold" onClick={this.createNewBooking} disabled={!this.state.isDisabled}>
                                        Create Booking Request
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      parks: state.parkReducer.data,
      products: state.productReducer.data,
      segment: state.segmentReducer.data
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
      fetchParks: data => dispatch(fetchParks(data)),
      fetchProducts: data => dispatch(fetchProducts(data)),
      fetchSegment: data => dispatch(fetchSegment(data))
    };
  }
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupBooking);
