import React, { Component } from 'react';
import { Container, Row, Col, Button, FormGroup, Input, Label, Form, FormFeedback,} from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { fetchPartnerCategory } from "../../actions/global";
import { connect } from "react-redux";
import BreadCrumb from '../Layout/Breadcrumb';
import Moment from "moment";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
import { Redirect } from "react-router-dom";
import {numberSpinner} from "../../helpers/helpers"
import {
    FaArrowLeft,
    FaAngleLeft,
    FaAngleRight
  } from "react-icons/fa";
import {
    url,
    api_read_resource_consumption,
    api_start_time,
    api_customer,
    api_user_token,
    api_create_new_booking
  } from "../../api/api";
import axios from "axios";
import { startTime } from "../../helpers/helpers"

import {AsyncPaginate} from "react-select-async-paginate";

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];
let fields = [];
var isChecked1 = true, isChecked2 = true, isChecked3=true;
var cek_onchange = {
    '1': true,
    '2': true,
    '3': true
}
// eslint-disable-next-line
var change = false
var timeSpinner = ""
class Step2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isDisabled: true,
            dateAvalaible: [],
            includeDates: [],
            dateParticipant: null,
            highlightWithRanges: [],
            selectDateTime: { date: null, time: null },
            back: false,
            customer: null,
            customer_list: null,
            isAccess: false,
            isHidden: true,
            isAccessForbidden: true,
            btnPay: false,
            isCompany: false,
            checkCompanyTags: false,
            companyTags: 'Company',
            checkTerm: false,
            contact_name: '',
            contact_first_name: '',
            contact_email: '',
            contact_phone:'',
            name: '',
            phone: '',
            email: '',
            tag_first_name: '',
            tag_name: '',
            tag_email: '',
            tag_phone: '',
            first_name: '',
            category_id: '',
            validate: { emailState: '', fnState: '', nameState: '', phoneState: '', cnemailState: '', cnfnState: '', cnnameState: '', cnphoneState: ''},
            start_time: [],
            resource_consumption: [],
            isSelect: false,
            customerError: false
        }
        this.handleChangeCustomer = this.handleChangeCustomer.bind(this);
    }

    get_resource_consumption = async () => {
        this.toggleLoading(true)
        var current_date = new Date()
        var first_day_of_next_month = Moment().add(1, 'months').date(1)
        var stepData = this.props.location.state
        var park_id = stepData ? stepData.step1_state.userPark_id : false

        const params = {
            jsonrpc: "2.0",
            method: "call",
            params: {
              model: "absale.resource.consumption",
              domain: [
                  ['date', '<', first_day_of_next_month],
                  ['date', '>=', current_date],
                  ["booking_detail_id.park_id", "=", park_id]
                ],
              fields: [],
              limit: 0,
              offset: 0,
              sort: "id",
              context: {
                tz: "Asia/Jakarta",
                lang: "en_US",
                uid: 11471
              }
            }
        };
        try {
            let res = await axios.post(api_read_resource_consumption, params, {headers: { "Content-Type": "application/json;charset=utf-8" }})
            let { data } = res
            if (data.error) {
                alert('Please Contact admin !!!, ERROR get resource consumption')
            } else {
                this.setState({resource_consumption: data.result})
            }
            this.gethighlightWithRanges()
            this.toggleLoading(false)
        } catch(err) {
            console.log(err)
            this.toggleLoading(false)
        }
    }
    clean_state() {
        const { validate } = this.state
        this.setState({
            name: '',
            first_name: '',
            phone: '',
            email: '',
            contact_name: '',
            contact_first_name: '',
            contact_email: '',
            contact_phone:'',
            btnPay: false
        })
        validate.fnState = ''
        validate.emailState = ''
        validate.nameState = ''
        validate.phoneState = ''
        validate.cnfnState = ''
        validate.cnemailState = ''
        validate.cnnameState = ''
        validate.cnphoneState = ''
        this.setState ({ validate })
    }

    componentDidMount() {
        /* 
            get start_time from local storage 
            local storage for start_time is made in the first step
        */
        const { fetchPartnerCategory } = this.props
        fetchPartnerCategory()
        let start_time = window.localStorage.getItem('start_time')
        if (start_time) {
            let val_start_time = JSON.parse(start_time)
            let date_conv = new Date(val_start_time.date)
            let value = {
                'id': val_start_time.id,
                'date': val_start_time.date,
                'time': val_start_time.time
            }
            this.handleChangeDate(date_conv)
            this.handleChangeSelectDateTime(value)
        }
        this.get_resource_consumption()
    }

    back = () => {
        this.setState({
            back: true
        });
    }
    checkCustomerValidation() {
        let form = {
            'first_name': this.state.contact_first_name,
            'name': this.state.contact_name,
            'email': this.state.contact_email,
            'phone': this.state.contact_phone
        }
        let status = false
        let customer = this.state.customer_list
        if (!customer) {
            if (this.state.isCompany) {
                let tag = {
                    'category_id': this.state.category_id,
                    'tag_first_name': this.state.tag_first_name,
                    'tag_name': this.state.tag_name,
                    'tag_email': this.state.tag_email,
                    'tag_phone': this.state.tag_phone
                }
                if (form.name !== "" && form.email !== "" && form.phone !== "" && tag.tag_first_name !== "" && tag.tag_name !== "" && tag.tag_email !== "" && tag.tag_phone !== "" && tag.category_id !== "") {
                    status = true
                    customer = {...form, ...tag}
                }
            } else {
                if (form.first_name !== "" && form.name !== "" && form.email !== "" && form.phone !== "") {
                    status = true
                    customer = {...form}
                }
            }
        } else {
            status = true
        }
        return {'status': status, 'customer': customer,}
    }
    createNewBooking = async (draft) => {
        let value = {}
        let value_draft = !draft ? 1 : ""
        let product = JSON.parse(window.localStorage.getItem('product_data'))
        let cek_validate = this.checkCustomerValidation()
        if (!cek_validate.status) {
            this.setState({isHidden: false})
        } else {
            this.setState({isHidden: true})
            value['location_id'] = product[0].company_id[0]
            value['participant_segment_ids'] = []
            let list_participant = []
            var total_participant = 0
            // eslint-disable-next-line
            product[0].absale_product_price.map(prod => {
                if (prod.qty > 0) {
                    list_participant.push({ segment_id: prod.segment_id[0], quantity: prod.qty })
                    value['participant_segment_ids'].push([0,0,{ segment_id: prod.segment_id[0], quantity: prod.qty }])
                    total_participant = total_participant + prod.qty
                }
            })
            value['booking_detail_ids'] = [[0, 0, { product_id: product[0].id, start_session_id: this.state.selectDateTime.id }]]
            const params = {
                jsonrpc: "2.0",
                method: "call",
                params: {
                    "booking": value,
                    "customer": cek_validate.customer,
                    "company": this.state.isCompany
                }
            };
            try {
                this.setState({ isDisabled: true })
                let res = await axios.post(api_create_new_booking, params, {headers: { "Content-Type": "application/json;charset=utf-8" }})
                let { data } = res
                if (data.error || data.result.error) {
                    let error = data.error ? data.error.data.message : data.result.message
                    this.setState({ isDisabled: false })
                    this.setState({customerError: error})
                } else {
                    window.open(url+"web?validate="+value_draft+"#id="+data.result.data+"&model=absale.booking&view_type=form", "_parent")
                }
                this.toggleLoading(false)
            } catch(err) {
                console.log(err)
            }  
        }
    }
    forbiddenPage() {
        return (
            <div className={this.state.isLoading === true ? "loading" : ""}>
                <div className={this.state.isAccessForbidden ? "d-none": ""}>
                    <Container style={{ marginTop: "20%", textAlign: "center"}}>
                        <Row>
                            <Col sm={12}>
                                <div className="text-forbidden">
                                    <h2>Sorry !!! you don't have access for this page</h2>
                                    <a href={url} className="back-button font-weight-bold">
                                        <FaArrowLeft /> Back
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        )
    }
    getUsers = async (token) => {
        const params = {
            jsonrpc: "2.0",
            method: "call",
            params: {
                "token": token
            }
        };
        try {
            let res = await axios.post(api_user_token, params, {headers: { "Content-Type": "application/json;charset=utf-8" }})
            let { data } = res
            if (data.result.data.length > 0) {
                this.toggleLoading(false);
                this.setState({isAccess: true})
            } else {
                this.setState({isAccessForbidden: false})
            }
        } catch(error) {
            alert(error)
        }
    }
    fieldCheck(fields){
        if(this.state.checkCompanyTags) {
            if(fields['contact_first_name'] != null && fields['contact_name'] != null && fields['contact_email'] != null && fields['contact_phone'] != null && fields['name'] != null && fields['email'] != null && fields['phone'] != null){
                if(fields['contact_name'] > 0 && fields['contact_email'] > 0 && fields['contact_phone'] > 1 && fields['name'] > 0 && fields['email'] > 0 && fields['phone'] > 1){
                    this.setState({ btnPay: true });
                }else{
                    this.setState({ btnPay: false });
                }
            }
        } else {
            if(fields['first_name'] != null && fields['name'] != null && fields['email'] != null && fields['phone'] != null){
                if(fields['first_name'] > 0 && fields['name'] > 0 && fields['email'] > 0 && fields['phone'] > 1){
                    this.setState({ btnPay: true });
                }else{
                    this.setState({ btnPay: false });
                }
            }
        }
    }
    validateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if (emailRex.test(e.target.value)) {
        validate.emailState = 'has-success'
        } else {
        validate.emailState = 'has-danger'
        }
        this.setState({ validate })
    }
    cnvalidateEmail(e) {
        const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const { validate } = this.state
        if (emailRex.test(e.target.value)) {
        validate.cnemailState = 'has-success'
        } else {
        validate.cnemailState = 'has-danger'
        }
        this.setState({ validate })
    }
    getCustomer = async (search=false, prevOptions) => {
        if (search.length > 3) {
            this.toggleLoading(true)
            const limit = 10;
            const params = {
                jsonrpc: "2.0",
                method: "call",
                params: {
                    "customer": true,
                    "name": search
                }
            };
            try {
                let filteredOptions;
                if (!search) {
                    filteredOptions = [];
                } else {
                    let res = await axios.post(api_customer, params,  {headers: { "Content-Type": "application/json;charset=utf-8" }})
                    let { data } = res
                    for(var i = 0; i < data.result.length; i++){
                        data.result[i].label = data.result[i]['display_name'];
                        delete data.result[i].display_name;
                    }
                    filteredOptions = data.result;
                    this.setState({customer: data.result})
                }
                const hasMore = filteredOptions.length > prevOptions.length + limit;
                const slicedOptions = filteredOptions.slice(
                    prevOptions.length,
                    prevOptions.length + limit
                )
                this.toggleLoading(false)
                return {
                    options: slicedOptions,
                    hasMore
                }
            } catch(error) {
                console.log(error)
            }
        }
    }
    renderRedirect = () => {
        if (this.state.back) {
            const token = window.localStorage.getItem('token')
            const product = JSON.parse(window.localStorage.getItem('product_data'))

            let back_value = {...this.props.location.state.step1_state}
            back_value.redirect = false
            return (
                <Redirect
                to={{
                    pathname:
                    "/create-booking/step1",
                    state: {token: token, product: product, step1_state:back_value}
                }}
                />
            );
        }
    }

    spinnerTimeout() {
        timeSpinner = setTimeout(
          function() {
            this.setState({isLoading: false})
          }
          .bind(this),
          numberSpinner()
        );
    }
    stopSpinnerTimout() {
        clearTimeout(timeSpinner)
    }

    toggleLoading(value) {
        this.setState({isLoading: value});
    }
    handleChangeDate = date => {
        let state = { ...this.state };
        let selected = state.dateParticipant ? Moment(state.dateParticipant) : Moment();
        if (selected.format('M') > Moment(date).format('M')) {
          this.gethighlightWithRanges(date, 0);
        }else if (selected.format('M') < Moment(date).format('M')) {
          this.gethighlightWithRanges(date, 0);
        }
        state.dateParticipant = date;
        this.setState(state);
    };
    handleChangeNumber(e) {
        const value = e.target.value.replace(/[^0-9]+/g, "");
        fields[e.target.name] = e.target.value.length;
        this.setState({ [e.target.name]: value });
        this.fieldCheck(fields);
    }
    cnhandleChangeNumber(e) {
        const value = e.target.value.replace(/[^0-9]+/g, "");
        fields[e.target.name] = e.target.value.length;
        this.setState({ [e.target.name]: value });
        this.fieldCheck(fields);
    }
    validBlurName (e) {
        const { validate } = this.state
        validate.nameState = 'has-success'
        this.setState ({ validate })
    }
    cnvalidBlurName (e) {
        const { validate } = this.state
        validate.cnnameState = 'has-success'
        this.setState ({ validate })
    }
    validBlur (e) {
        const { validate } = this.state
        validate.fnState = 'has-success'
        this.setState ({ validate })
    }
    cnvalidBlur (e) {
        const { validate } = this.state
        validate.cnfnState = 'has-success'
        this.setState ({ validate })
    }
    validBlurPhone (e) {
        const { validate } = this.state
        validate.phoneState = 'has-success'
        this.setState ({ validate })
    }
    cnvalidBlurPhone (e) {
        const { validate } = this.state
        validate.cnphoneState = 'has-success'
        this.setState ({ validate })
    }
    handleChangeSelectDateTime = data => {
        // eslint-disable-next-line
        let state = { ...state };
        state.selectDateTime = data;
        state.isDisabled = false
        this.setState(state);
    };
    enforce_capacity(data, total_participant) {
        /* Rule 1 Enforce capacity
       True if number of (participants + start_time.participants_count) > start_time.checkin_capacity AND is_available is true */
        var total = total_participant + data.checkin_participants
        if (total > data.checkin_capacity && data.is_available) {
            return false
        }
        return true
    }
    current_resource_consumption_rule(product) {
        /* Calculate resource consumption rule for (selected experience, list of participants) for all timeblocks related to this experience; i.e.
        between start_time and start_time + duration.
        This gives a table booking_resource_consumption that shows quantity for (date, timeblock, resource_id) */
        var resource_id = []
        product[0].experience_id.resource_consumptionrule_ids.map(rcr => {
            // eslint-disable-next-line
            return product[0].absale_product_price.map(pp => {
                if (pp.qty > 0) {
                    var check_segment = rcr.segment_ids.some(function(obj){
                        return obj.id === pp.segment_id[0]
                    });
                    if (check_segment) {
                        resource_id.push({'segment_id': pp.segment_id[0],'resource_id':rcr.resource_id, 'participant_quantity': pp.qty, 'quantity': rcr.quantity})
                    }
                }
            })
        })
        return resource_id
    }

    handleCheckbox = (e) => {
        this.setState({
            checkTerm: !this.state.checkTerm,
            isSelect: e
        });
        this.clean_state();
    }
    changeToCustomerType = (e) => {
        this.setState({
            isCompany: false,
            checkCompanyTags: false
        })
        this.clean_state()
    }
    changeToCompanyType = (e) => {
        this.setState({
            isCompany: true,
            checkCompanyTags: true,
        })
        this.clean_state()
    }
    changeCompanyCategory(v, e) {
        this.setState({
            companyTags: v,
            category_id: e.target.value
        })
    }
    checkRules(value, checked) {
        change = true
        cek_onchange[value] = checked
        if (value === '1') {
            isChecked1 = checked
            this.startTimeResult(this.state.start_time, checked, cek_onchange, value, false)
        } else if (value === '2') {
            isChecked2 = checked
            this.startTimeResult(this.state.start_time, checked, cek_onchange, value, false)
        } else {
            isChecked3 = checked
            this.startTimeResult(this.state.start_time, checked, cek_onchange, value, false)
        }
    }
    handleRulesEnforce(v, e) {
        if (e.target.value === '1') {
            this.checkRules(e.target.value, e.target.checked)
        } else if (e.target.value === '2') {
            this.checkRules(e.target.value, e.target.checked)
        } else {
            this.checkRules(e.target.value, e.target.checked)
        }
    }

    handleChange(e) {
        fields[e.target.name] = e.target.value.length;
        this.setState({ [e.target.name]: e.target.value });
        this.fieldCheck(fields);
    }

    handleChangeCustomer = customer => {
        console.log(customer)
        try {
            this.setState({customer_list: customer});
            if (customer.id){
                this.setState({
                    isHidden: true
                });
            } else {
                const { validate } = this.state
                validate.nameState = 'has-success'
                this.setState({validate})
                this.setState({
                    name: customer.label
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    gethighlightWithRanges = (date, month_number, initial = false) => {
        const product = JSON.parse(window.localStorage.getItem('product_data'))
        const token = window.localStorage.getItem('token')
        date = Moment(date)
          .set({
            date: 1
          })
          .add(month_number, "month")
          .format("YYYY-MM-DD");
        let params = {
          jsonrpc: "2.0",
          method: "call",
          id: null,
          params: {
            date: date,
            experience: product[0].experience_id.id,
            location: product[0].company_id[0]
          }
        };
        const kuotaSchedule = axios.post(api_start_time, params, {
          headers: { "Content-Type": "application/json;charset=utf-8" }
        });
        this.toggleLoading(true);
        kuotaSchedule
          .then(({ data }) => {
            var start_time = []
            data.result.main_activity.start_time.map(d => start_time.push(d));
            this.setState({start_time: start_time})
            // this.getCustomer();
            this.toggleLoading(false);
            this.getUsers(token);
            this.startTimeResult(start_time, true, cek_onchange, 0, false)
          })
          .catch(err => {
            console.log(err);
          });
    };
    startTimeResult(start_time, checked=true, cek_onchange, value, fe=false) {
        let state_start_time = startTime(start_time, checked, cek_onchange, this.state.resource_consumption, value, false, null, null)
        this.setState(state_start_time)
    }
    render() {
        const forbiddenPage = this.forbiddenPage()
        const product = JSON.parse(window.localStorage.getItem('product_data'))
        const step = "step2"
        const productD = product.map(prod => {
            return (
                <table key={prod.id}>
                    <tbody>
                        <tr>
                            <td>Park</td>
                            <td>{prod.company_id[1]}</td>
                        </tr>
                        <tr>
                            <td>Experience</td>
                            <td>{prod.experience_id.name}</td>
                        </tr>
                        <tr>
                            <td>Participants</td>
                            <td>
                                {prod.absale_product_price.map(p_price => {
                                    if (p_price.qty > 0) {
                                        return (
                                            <span key={p_price.segment_id[0]}>{p_price.segment_name}: {p_price.qty} </span>
                                        )
                                    }
                                    return (<span key={p_price.segment_id[0]}></span>)
                                })}
                            </td>
                        </tr>
                    </tbody>
                </table>
            )
        })
        const timeAvalaible = this.getTimeAvalaible();
        let partner_category = "Category is empty."
        if (this.props.partnercategory) {
            partner_category = this.props.partnercategory.map(obj => {
                return (
                    <FormGroup key={obj.id} check>
                        <Label check>
                            <Input type="radio" value={obj.id} onChange={e => this.changeCompanyCategory(obj.name, e)} name="company_tag"/>{' '}
                            {obj.name}
                        </Label>
                    </FormGroup>
                )
            })
        }

        return (
            <div className={this.state.isLoading === true ? "loading" : ""}>
                <div className={!this.state.isAccess ? "d-none": ""}>
                {this.renderRedirect()}
                <BreadCrumb step={step}/>
                <Container fluid style={{ marginTop: 20 }}>
                    <Row>
                        <Col sm={6}>
                            {productD}
                        </Col>
                        <Col sm={6} >
                            <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" value="1" checked={isChecked1} onChange={event =>this.handleRulesEnforce("1", event)}/>{' '}
                                    Apply tour capacity
                                </Label>
                                </FormGroup>
                                <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" value="2" checked={isChecked2} onChange={event =>this.handleRulesEnforce("2", event)}/>{' '}
                                    Apply resource capacity
                                </Label>
                                </FormGroup>
                                <FormGroup check>
                                <Label check>
                                    <Input type="checkbox" value="3" checked={isChecked3} onChange={event =>this.handleRulesEnforce("3", event)}/>{' '}
                                    Apply resource override
                                </Label>
                            </FormGroup>
                        </Col>
                        <Col sm={6} style={{ marginTop: 30 }}>
                            <DatePicker
                                inline
                                renderCustomHeader={({
                                    date,
                                    changeYear,
                                    changeMonth,
                                    decreaseMonth,
                                    increaseMonth,
                                    prevMonthButtonDisabled,
                                    nextMonthButtonDisabled
                                    }) => (
                                        <div className="row col-md-12 col-xs-12" style={{ margin: "auto", alignItems: "center", justifyContent: "center" }}>
                                        <div
                                            className="col-md-1 col-xs-1"
                                            style={{ margin: 0, padding: 0 }}
                                        >
                                            <button
                                            onClick={() => {
                                                decreaseMonth();
                                                this.gethighlightWithRanges(date, -1);
                                            }}
                                            disabled={prevMonthButtonDisabled}
                                            className="btn-cal prev"
                                            >
                                            {<FaAngleLeft />}
                                            </button>
                                        </div>
                                        <div
                                            className="col-md-5 colxs-5"
                                            style={{ textAlign: "center", margin: 0, padding: 0 }}
                                        >
                                            {months[getMonth(date)]} {getYear(date)}
                                        </div>

                                        <div
                                            className="col-md-1 col-xs-1 float-right"
                                            style={{ margin: 0, padding: 0 }}
                                        >
                                            <button
                                            className="btn-cal float-right"
                                            onClick={() => {
                                                increaseMonth();
                                                this.gethighlightWithRanges(date, 1);
                                            }}
                                            disabled={nextMonthButtonDisabled}
                                            >
                                            {<FaAngleRight />}
                                            </button>
                                        </div>
                                        </div>
                                    )}
                                highlightDates={this.state.highlightWithRanges}
                                selected={this.state.dateParticipant}
                                onChange={this.handleChangeDate}
                                name="dateParticipant"
                                includeDates={this.state.includeDates}
                                />
                                <Col sm={10} className="ck_exst_cust">
                                    <Label check>
                                        <Input type="checkbox" checked={this.state.checkTerm} onChange={e => this.handleCheckbox(e.target.checked)}/>
                                        <span className="font-color-step5 letterspace">Select an Existing Customer</span>
                                    </Label>
                                </Col>
                                <FormGroup row>
                                    <Label for="exampleSelect" sm={3}>Customer</Label>
                                    <Col sm={9}>
                                        <AsyncPaginate
                                            isClearable
                                            className={this.state.checkTerm ? "" : "d-none"}
                                            value={this.state.customer_list}
                                            loadOptions={this.getCustomer}
                                            onChange={this.handleChangeCustomer}
                                            noOptionsMessage={()=>"Type Customer Name"}
                                        />
                                        <Form
                                            onSubmit={e => {
                                            e.preventDefault();
                                            }}
                                            className={!this.state.checkTerm ? "" : "d-none"}
                                        >
                                            <Label for="numberParticipant" className="g-color">
                                                Please enter your personal info
                                            </Label>
                                            <FormGroup tag="fieldset">
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" onChange={e => this.changeToCustomerType(e.target.value)} name="partner_type" defaultChecked/>{' '}
                                                        Individual
                                                    </Label>
                                                </FormGroup>
                                                <FormGroup check>
                                                    <Label check>
                                                        <Input type="radio" onChange={e => this.changeToCompanyType(e.target.value)} name="partner_type"/>{' '}
                                                        Company
                                                    </Label>
                                                </FormGroup>
                                            </FormGroup>
                                            <FormGroup tag="fieldset" className={!this.state.isCompany ? "d-none" : ""}>
                                                <Label for="numberParticipant" className="g-color">
                                                    Please Select your Category
                                                </Label>
                                                {partner_category}
                                            </FormGroup>
                                            <FormGroup className={this.state.isCompany ? "d-none" : ""}>
                                                <Input
                                                    valid={ this.state.validate.cnfnState === 'has-success' }
                                                    autoComplete="off"
                                                    type="text"
                                                    name="contact_first_name"
                                                    id="contact_first_name"
                                                    className="form-control step3"
                                                    placeholder="First Name*"
                                                    required
                                                    value={this.state.contact_first_name}
                                                    onBlur={ (e) => {
                                                    this.cnvalidBlur(e)
                                                    }}
                                                    onChange={ (e) => {
                                                    // this.validateText(e)
                                                    this.handleChange(e)
                                                    } }
                                                />
                                                <FormFeedback className="ftnamevalid" valid>
                                                    First Name *
                                                </FormFeedback>
                                            </FormGroup>
                                            <FormGroup className="field-step3">
                                                <Input
                                                    valid={ this.state.validate.nameState === 'has-success' }
                                                    autoComplete="off"
                                                    type="text"
                                                    name="contact_name"
                                                    id="contact_name"
                                                    className="form-control step3"
                                                    placeholder={this.state.isCompany ? "Company/School Name*" : "Last Name *"}
                                                    required
                                                    value={this.state.contact_name}
                                                    onBlur={ (e) => {
                                                    this.validBlurName(e)
                                                    }}
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                                <FormFeedback className="namevalid" valid>
                                                    {this.state.isCompany ? "Company/School Name *" : "Last Name *"}
                                                </FormFeedback>
                                            </FormGroup>
                                            <FormGroup className="field-step3">
                                                <Input
                                                    autoComplete="off"
                                                    type="email"
                                                    name="contact_email"
                                                    id="contact_email"
                                                    className="form-control step3"
                                                    placeholder="Email*"
                                                    required
                                                    value={this.state.contact_email}
                                                    onChange={ (e) => {
                                                    // force email to be lower case
                                                    e.target.value = e.target.value.toLowerCase();
                                                    this.validateEmail(e)
                                                    this.handleChange(e)
                                                    } }
                                                    valid={ this.state.validate.emailState === 'has-success' }
                                                    invalid={ this.state.validate.emailState === 'has-danger' }
                                                />
                                                <FormFeedback className="emailvalid" valid>
                                                    Email *
                                                </FormFeedback>
                                                <FormFeedback>
                                                    Provide a valid email
                                                </FormFeedback>
                                            </FormGroup>
                                            <FormGroup className="field-step3">
                                                <Input
                                                    valid={ this.state.validate.cnphoneState === 'has-success' }
                                                    type="text"
                                                    name="contact_phone"
                                                    id="contact_phone"
                                                    className="form-control step3"
                                                    placeholder={this.state.isCompany ? "Company/School Phone*" : "Phone*"}
                                                    required
                                                    value={this.state.contact_phone}
                                                    onBlur={ (e) => {
                                                        this.cnvalidBlurPhone(e)
                                                    }}
                                                    onChange={this.cnhandleChangeNumber.bind(this)}
                                                />
                                                <FormFeedback className="phonevalid" valid>
                                                    {this.state.isCompany ? "Company/School Phone" : "Phone*"}
                                                </FormFeedback>
                                            </FormGroup>
                                            <FormGroup className={!this.state.isCompany ? "d-none" : ""}>
                                                <Label for="numberParticipant" className="g-color">
                                                    Please enter your contact info
                                                </Label>
                                                <FormGroup className="field-step3">
                                                    <Input
                                                        valid={ this.state.validate.cnfnState === 'has-success' }
                                                        autoComplete="off"
                                                        type="text"
                                                        name="tag_first_name"
                                                        id="tag_first_name"
                                                        className="form-control step3"
                                                        placeholder="First Name*"
                                                        required
                                                        value={this.state.tag_first_name}
                                                        onBlur={ (e) => {
                                                        this.cnvalidBlur(e)
                                                        }}
                                                        onChange={ (e) => {
                                                        // this.validateText(e)
                                                        this.handleChange(e)
                                                        } }
                                                    />
                                                    <FormFeedback className="ftnamevalid" valid>
                                                        First Name *
                                                    </FormFeedback>
                                                </FormGroup>
                                                <FormGroup className="field-step3">
                                                    <Input
                                                        valid={ this.state.validate.cnnameState === 'has-success' }
                                                        autoComplete="off"
                                                        type="text"
                                                        name="tag_name"
                                                        id="tag_name"
                                                        className="form-control step3"
                                                        placeholder="Last Name*"
                                                        required
                                                        value={this.state.tag_name}
                                                        onBlur={ (e) => {
                                                        this.cnvalidBlurName(e)
                                                        }}
                                                        onChange={this.handleChange.bind(this)}
                                                    />
                                                    <FormFeedback className="namevalid" valid>
                                                        Last Name *
                                                    </FormFeedback>
                                                </FormGroup>
                                                <FormGroup className="field-step3">
                                                    <Input
                                                        autoComplete="off"
                                                        type="email"
                                                        name="tag_email"
                                                        id="tag_email"
                                                        className="form-control step3"
                                                        placeholder="Email*"
                                                        required
                                                        value={this.state.tag_email}
                                                        onChange={ (e) => {
                                                        // force email to be lower case
                                                        e.target.value = e.target.value.toLowerCase();
                                                        this.cnvalidateEmail(e)
                                                        this.handleChange(e)
                                                        } }
                                                        valid={ this.state.validate.cnemailState === 'has-success' }
                                                        invalid={ this.state.validate.cnemailState === 'has-danger' }
                                                    />
                                                    <FormFeedback className="emailvalid" valid>
                                                        Email *
                                                    </FormFeedback>
                                                    <FormFeedback>
                                                        Provide a valid email
                                                    </FormFeedback>
                                                </FormGroup>
                                                <FormGroup className="field-step3">
                                                    <Input
                                                        valid={ this.state.validate.cnphoneState === 'has-success' }
                                                        type="text"
                                                        name="tag_phone"
                                                        id="tag_phone"
                                                        className="form-control step3"
                                                        placeholder="Mobile Number*"
                                                        required
                                                        value={this.state.tag_phone}
                                                        onBlur={ (e) => {
                                                        this.cnvalidBlurPhone(e)
                                                        }}
                                                        onChange={this.cnhandleChangeNumber.bind(this)}
                                                    />
                                                    <FormFeedback className="phonevalid" valid>
                                                        Phone *
                                                    </FormFeedback>
                                                </FormGroup>
                                            </FormGroup>
                                        </Form>
                                        <Label for="exampleSelect" className={this.state.isHidden ? "d-none warning-customer" : "warning-customer"}>You need complete your personal info or select the existing customer.</Label>                        
                                        <Label for="exampleSelect" className={!this.state.customerError ? "d-none" : "warning-customer"}>{this.state.customerError}</Label>
                                    </Col>
                                </FormGroup>
                        </Col>
                        <Col sm={6} style={{ marginTop: "30px"}}>
                        {timeAvalaible}
                        </Col>
                        <Col sm={12} style={{ marginBottom: "20px"}}>
                            <Button className="btn font-weight-bold" onClick={this.back} type="submit">
                                <FaArrowLeft /> BACK
                            </Button>
                            <Button className="float-right btn font-weight-bold" onClick={() => this.createNewBooking()} disabled={this.state.isDisabled}>
                                CREATE BOOKING
                            </Button>
                            <Button className="float-right btn font-weight-bold" onClick={() => this.createNewBooking(true)} style={{marginRight: 10}} disabled={this.state.isDisabled}>
                                CREATE DRAFT
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            {forbiddenPage}
            </div>
        );
    }

getTimeAvalaible = () => {
    let res = "";
    const product = JSON.parse(window.localStorage.getItem('product_data'))
    if (this.state.dateParticipant ) {
        const dateStr = Moment(this.state.dateParticipant).format("YYYY-MM-DD");
        // eslint-disable-next-line
        this.state.selectDateTime.date = dateStr;
        res = this.state.dateAvalaible.filter(d => d.date === dateStr).map(cd => {
            let classButton = "btn " + cd.hex_st_color;
            if (cd.time === this.state.selectDateTime.time) {
                classButton = classButton + "_dark";
            }
            return (
                <div
                    key={cd.id}
                    className="col-md-3"
                    style={{ padding: 1, height: 70 }}
                >
                    <button
                    key={cd.id}
                    style={{ width: "100%", height: "100%" }}
                    className={classButton}
                    disabled={!cd.available_booking}
                    onClick={() => this.handleChangeSelectDateTime(cd)}
                    >
                    <div className="date_day_item">
                        <p style={{ marginBottom: 0 }}>{cd.time}</p>
                        <small>{cd.status}</small>
                        <br/>
                        <small>{cd.checkin_participants}/{cd.checkin_capacity}</small>
                    </div>
                    </button>
                </div>
            )
        });
    }
   return (
        <div>
            <div className="time-available" style={{ width: "40vw", margin: "auto" }}>
            <div style={{ color: "#009682"}}>
                Select Session <span style={{ fontSize: "12px"}}>(
                {product[0] &&
                product[0].duration / 60}{" "}
                hours session)</span>
            </div>
            <div className="row" style={{ margin: "0" }}>
                {res}
            </div>
            </div>
        </div>
        );
    };
}


const mapStateToProps = state => {
    return {
      partnercategory: state.partnercategoryReducer.data,
    };
  };
  
  function mapDispatchToProps(dispatch) {
    return {
        fetchPartnerCategory: data => dispatch(fetchPartnerCategory(data)),
    };
  }
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Step2);
