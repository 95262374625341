import React, { Component } from "react";
import {
  CardBody,
  Card,
  Button,
  Form,
  FormGroup,
  FormFeedback,
  Label,
  Input
} from "reactstrap";

class WindcaveIFrame extends Component {

  render() {
    return (
      <React.Fragment>
        <div>
          <script>
          {
            window.top.postMessage({
              trigger: this.props.match.params.windcave_status,
              booking_id: this.props.match.params.booking_id,
              voucher_code: this.props.match.params.voucher_code,
              success: false //to_be_checked
            }, window.top.origin)
          }
          </script>
        </div>
        {/* <div>Windcave redirects to {this.props.match.params.windcave_status} {this.props.match.params.booking_code}</div> */}
        <div className="loading"></div>
      </React.Fragment>
    );
  }
}

export default WindcaveIFrame;

