import React, { Component } from 'react';
import {FaTimes, FaArrowLeft} from "react-icons/fa";
import axios from "axios";
import Moment from "moment";
import {
    Form,
    Input
} from "reactstrap";
import { api_get_country_list, api_get_states_list, api_get_user } from '../../../api/api';

const google = window.google;
let fields = [];
const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
];

class PersonalInformationCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullName: null,
            year: null,
            month: null,
            age: null,
            gender: null,
            occupation: null,
            email: null,
            suburb: null,
            postcode: null,
            phone: null,

            park: null,
            country: "Australia",
            state: "New South Wales",
            isEmergencyContact: false,
            isMedicalCondition: null,
            medicalConditionReason: null,
            countryList: null,
            stateList: null,
            subscribe: null,
            session_month: null,
            input_month: null,
            tickbox_rule_ids: [],
            error: {
                email: false,
                year: false,
                month: false,
                age: false,
                fullName: false,
                gender: false,
                occupation: false,
                country: false,
                state: false,
                suburb: false,
                postcode: false,
                phone: false,
                isEmergencyContact: false,
                isMedicalCondition: null,
                medicalConditionReason: false,
                tickbox_rule_ids: false,
            }
        }
        this.handlePlaceSelect = this.handlePlaceSelect.bind(this)
        // this.handleSubmit = this.handleSubmit.bind(this)
        this.autocomplete = null
        this.sessionToken = null
      }

    checkEmail(e) {
        let pattern = []
        let email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        let email = e.target.value
        pattern = email.toLowerCase().match(email_regex)
        if (pattern != null) {
            this.get_user(email)
        }
    }

    handlePlaceSelect() {
        // Get the place details from the autocomplete object.
        let state = { ...this.state };

        var place = this.autocomplete.getPlace();

        var addressComponents = place.address_components;
        var floor = "";
        var number = "";
        var premise = "";
        var route = "";
        var sublocality_level_1 = "";
        var neighborhood = "";
        var postal_town = "";
        var locality = "";
        var zip_code = "";
        var state_name = "";
        var state_name2 = "";
        var country_name = "";

        if (!addressComponents) {
            return;
        }
        // loop through addressComponents and find the route and street_number types
        for (var i = 0, i_len = addressComponents.length; i < i_len; i++) {
            var addressType = addressComponents[i].types[0];
            switch(addressType) {
                case "floor":
                    floor = addressComponents[i]["long_name"];
                    break;
                case "street_number":
                    number = addressComponents[i]["long_name"];
                    break;
                case "premise":
                    premise = addressComponents[i]["long_name"];
                    break;
                case "route":
                    route = addressComponents[i]["long_name"];
                    break;

                case "sublocality_level_1":
                    sublocality_level_1 = addressComponents[i]["long_name"];
                    break;
                case "neighborhood":
                    neighborhood = addressComponents[i]["long_name"];
                    break;

                case "postal_town":
                    postal_town = addressComponents[i]["long_name"];
                    break;
                case "locality":
                    locality = addressComponents[i]["long_name"];
                    break;

                case "postal_code_prefix":
                    zip_code = addressComponents[i]["long_name"];
                    break;
                case "postal_code":
                    zip_code = addressComponents[i]["long_name"];
                    break;

                case "administrative_area_level_1":
                    state_name = addressComponents[i]["long_name"];
                    break;
                case "administrative_area_level_2":
                    state_name2 = addressComponents[i]["long_name"];
                    break;
                case "country":
                    country_name = addressComponents[i]["long_name"];
                    break;
            }
        }
        var city = "";
        if (postal_town) city = postal_town;
        if (locality) city = city + " " + locality;

        state.state = state_name
        
        if (!this.state.countryList.filter(obj => obj.name === country_name)[0]) {
            country_name = '.'
        }
        state.country = country_name 
        state.suburb = city
        state.postcode = zip_code

        this.setState(state)
    }
      
    componentDidUpdate(prevProp, prevState) {
        if ((this.state.year != null && (this.state.year != prevState.year) && this.state.month != null) ||
            (this.state.year != null && this.state.month != null && (this.state.month != prevState.month))) {
            let input_month = parseInt(this.state.month)
            let session_month = parseInt(Moment(this.props.booking.session_date).month())
            let age = Moment().year() - this.state.year

            this.setState({input_month: input_month, session_month: session_month})

            if (input_month >= session_month) {
                this.setState({age: age - 1})
            }
            else {
                this.setState({age: age})
            }
        }
        if (prevProp.tickbox_rule_ids != this.props.tickbox_rule_ids){
            this.setState({tickbox_rule_ids: this.props.tickbox_rule_ids})
        }
        if ((this.state.countryList == null && this.props.PersonalInformationShow != prevProp.PersonalInformationShow) && this.props.park) {
            this.get_country_list()
        }
        if ((this.state.stateList == null && this.props.PersonalInformationShow != prevProp.PersonalInformationShow) && this.props.park) {
            this.get_states_list()
        }
        if (this.state.country != prevState.country) {
            this.setState({state: document.getElementById('state').value})
        }
    }

    validateWaiver() {
        let state = { ...this.state };
        let error = false

        state.error.email = false
        state.error.year = false
        state.error.month = false
        state.error.age = false
        state.error.fullName = false
        state.error.gender = false
        state.error.occupation = false
        state.error.country = false
        state.error.state = false
        state.error.suburb = false
        state.error.postcode = false
        state.error.phone = false
        state.error.isEmergencyContact = false
        state.error.isMedicalCondition = false
        state.error.medicalConditionReason = false
        state.error.tickbox_rule_ids = false

        let unchecked_tickbox = this.state.tickbox_rule_ids.filter( item => {
            return item.check == false
        })

        if (unchecked_tickbox.length > 0) {
            unchecked_tickbox.map(item => {
                state.error.tickbox_rule_ids = "Please confirm the " + item.message
            })
            
            error = true
        }

        if (!this.state.email) {
            state.error.email = "Email is Required"
            error = true
        }
        else {
            let pattern = []
            let email_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            pattern = this.state.email.toLowerCase().match(email_regex)
            if (pattern == null) {
                state.error.email = "Please enter a correct email address"
                error = true
            }
        }
        
        if (!this.state.fullName) {
            state.error.fullName = "Full Name is Required"
            error = true
        }
        else {
            if (this.state.fullName.split(' ').length == 1) {
                state.error.fullName = "Please complete your full name"
                error = true
            }
        }
        if (!this.state.year) {
            state.error.year = "Year is Required"
            error = true
        }
        if (!this.state.month) {
            state.error.month = "Month is Required"
            error = true
        }
        if (!this.state.age) {
            state.error.age = "Age is Required"
            error = true
        }
        if (this.state.age < 17) {
            state.error.age = "Oops! Please enter the correct date of birth for 18yrs+ participant."
            error = true
        }
        if (!this.state.suburb) {
            state.error.suburb = "Suburb is Required"
            error = true
        }
        if (!this.state.gender) {
            state.error.gender = "Gender is Required"
            error = true
        }
        if (!this.state.occupation) {
            state.error.occupation = "Occupation is Required"
            error = true
        }
        if (!this.state.country || this.state.country == '.') {
            state.error.country = "Country is Required"
            error = true
        }
        if (!this.state.postcode) {
            state.error.postcode = "Postcode is Required"
            error = true
        }
        if (!this.state.phone) {
            state.error.phone = "Phone is Required"
            error = true
        }
        if (this.state.isMedicalCondition == null) {
            state.error.isMedicalCondition = "Medical Condition is Required"
            error = true
        }
        if (this.state.isMedicalCondition && !this.state.medicalConditionReason) {
            state.error.medicalConditionReason = "Medical Condition reason is Required"
            error = true
        }
        this.setState(state)
        if (!error) {
            this.props.saveWaiver(this.state)
        }
        else {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    }

    get_user = (email) => {
        const params = {
            jsonrpc: "2.0",
            method: "call",
            params: {
                'email': email
            }
        };

        // "email": customer.email,
        // "full_name": full_name,
        // "phone": customer.phone or '',
        // "zip": customer.zip or '',
        // "street": customer.street or '',
        // "city": customer.city or '',
        // "subscribe": customer.subscribe,
        axios
          .post(api_get_user, params, {
            headers: { "Content-Type": "application/json;charset=utf-8" }
          })
          .then(({ data }) => {
            if (data.result.data) {
                let customer_data = data.result.data
                this.setState({
                    fullName: customer_data.full_name,
                    phone: customer_data.phone,
                    postcode: customer_data.zip,
                    suburb: customer_data.suburb,
                    gender: customer_data.gender,
                    occupation: customer_data.occupation,
                    country: customer_data.country,
                    state: customer_data.state
                })
            }
          })
          .catch(({ err }) => {});
    };

    get_country_list = () => {
        const params = {
          jsonrpc: "2.0",
          method: "call",
          params: {}
        };
        axios
          .post(api_get_country_list, params, {
            headers: { "Content-Type": "application/json;charset=utf-8" }
          })
          .then(({ data }) => {
            this.setState({ 
                countryList: data.result,
            });
          })
          .catch(({ err }) => {});
    };

    get_states_list = () => {
        const params = {
          jsonrpc: "2.0",
          method: "call",
          params: {}
        };
        axios
          .post(api_get_states_list, params, {
            headers: { "Content-Type": "application/json;charset=utf-8" }
          })
          .then(({ data }) => {
            this.setState({ 
                stateList: data.result,
            });
          })
          .catch(({ err }) => {});
    };
    
    previousPage() {
        this.props.actionShow("WaiverTypeShow")
    }

    getCountryListContent() {
        const countryListContent = this.state.countryList.map(country => {
            return (
                <option value={country.name}>
                    {country.name}
                </option>
            )
        })
        return countryListContent
    }

    getStateListContent() {
        const country = this.state.stateList.filter((obj => obj.country === this.state.country))[0]
        if (country) {
            const stateListContent = country.states.map(item => {
                return (
                    <option value={item}>
                        {item}
                    </option>
                )
            
            })
            return stateListContent
        }
        else {
            return false
        }
    }

    handleChange(e) {
        let value;
        fields[e.target.name] = e.target.value.length;
        if (e.target.type == 'checkbox') {
            if (e.target.id.split('_')[0] == 'tickbox') {
                let state = { ...this.state };
                let index = this.state.tickbox_rule_ids.findIndex(el => el.id === parseInt(e.target.id.split('_')[1]))

                state.tickbox_rule_ids[index].check = !state.tickbox_rule_ids[index].check

                this.setState(state)

            }
            value = e.target.checked
        }
        else if(e.target.type == 'radio') {
            if (parseInt(e.target.value) == 1) {
                value = 1
            } 
            else {
                value = 0
            }
        }
        else {
            value = e.target.value;
        }
        this.setState({ [e.target.name]: value });
    }

    getData() {
        let countryList = []
        let stateList = [];

        if (this.state.countryList != null) {
            countryList = this.state.countryList
        }

        if (this.state.stateList != null) {
            stateList = this.state.stateList
        }

        return {countryList, stateList}
    }
    getMonthDropdownContent() {
        const content = months.map((key, index) => {
            return (
                <option value={index + 1}>
                    {key}
                </option>
            )
        })
        return content
    }

    getYearDropdownContent() {
        const current_year = new Date().getFullYear()
        const years = []
        for (var i = current_year, i_len = 1930; i >= i_len; i--) {
            years.push(i)
        }
        const content = years.map(key => {
            return (
                <option value={key}>
                    {key}
                </option>
            )
        })
        return content
    }

    getAgeDropdownContent() {
        let age = Moment().year() - this.state.year
        const content = (
            <>
                <option value={age}>
                    {age}
                </option>
                <option value={age - 1}>
                    {age - 1}
                </option>
            </>
        )
        return content
    }

    getTickBoxContent() {
        const tickboxContent = this.state.tickbox_rule_ids.map(rule => {
            return (                
                <div className="checkbox-container" style={{ display: "flex", flexDirection: "row", marginLeft: "5px"}} id={rule.id}>
                    <Input
                        style={{ marginBottom: "1px", width: "14px"}}
                        className="form-check-input"
                        type="checkbox"
                        id={"tickbox_" + rule.id}
                        name={"tickbox_" + rule.id}
                        onChange={(e) => {
                            this.handleChange(e)
                        }}
                    />
                    <span className="checkmark-check"></span>
                    <label style={{ fontSize: "13px" }} className="form-check-label fs-subtitle" for={"tickbox_" + rule.id}>
                        {rule.message}    
                    </label>
                </div>
            )
        })
        return (

            <div className="form-check">
                {tickboxContent}
            </div>
        )
    }

    getFormErrorContent() {
        return (
            <div class="errorTxt fs-subtitle">
                {   this.state.error.email ?
                    <div class="error">
                        {this.state.error.email}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.year ?
                    <div class="error">
                        {this.state.error.year}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.month ?
                    <div class="error">
                        {this.state.error.month}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.age ?
                    <div class="error">
                        {this.state.error.age}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.fullName ?
                    <div class="error">
                        {this.state.error.fullName}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.gender ?
                    <div class="error">
                        {this.state.error.gender}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.occupation ?
                    <div class="error">
                        {this.state.error.occupation}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.country ?
                    <div class="error">
                        {this.state.error.country}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.suburb ?
                    <div class="error">
                        {this.state.error.suburb}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.tickbox_rule_ids ?
                    <div class="error">
                        {this.state.error.tickbox_rule_ids}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.postcode ?
                    <div class="error">
                        {this.state.error.postcode}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.isMedicalCondition ?
                    <div class="error">
                        {this.state.error.isMedicalCondition}
                    </div>
                    :
                    <></>
                }
                {   this.state.error.phone ?
                    <div class="error">
                        {this.state.error.phone}
                    </div>
                    :
                    <></>
                }
            </div>
        )
    }

    render() {
        const {countryList, stateList} = this.getData()
        const FormErrorContent = this.getFormErrorContent()
        const MonthDropdownContent = this.getMonthDropdownContent()
        const YearDropdownContent = this.getYearDropdownContent()
        const AgeDropdownContent = this.getAgeDropdownContent()
        const TickBoxContent = this.getTickBoxContent()
        const showAgeDropdown = this.state.input_month == this.state.session_month + 1 && this.state.input_month != null
        const dropdownWidth = {
            width: showAgeDropdown ? '30%' : '40%'
        };
        let countryListContent, stateListContent;
        if (countryList.length > 0){
            countryListContent = this.getCountryListContent()
        }
        if (stateList.length > 0) {
            stateListContent = this.getStateListContent()
        }
        return (
            this.props.PersonalInformationShow ?
            <>
                <Form 
                    onSubmit={e => {
                        e.preventDefault();
                        this.validateWaiver();
                    }}
                    className="waiver-personal-info"
                >
                    <div className="form-card">
                        <FaTimes 
                            type='button'
                            className="close"
                            value="Cancel"
                            name="btn_cancel" id="btn_cancel"
                        />
                        <div className="title-content text-center fs-subtitle">
                            <h2 className="fs-title"><span>{this.props.park.name}</span></h2>
                            {
                                this.props.waiverType == 'minor_child' ?
                                <h4><strong><span>Participant Over 18 Years or Parent/Guardian Details</span></strong></h4>
                                :
                                <></>
                            }
                            {FormErrorContent}
                        </div>

                        <div className="btn-group" style={{ width: "100%", justifyContent: "center", alignItems: "center" }}>
                            <div className="col-md-9 full-responsive" style={{ width: "50%" }}>

                                <label for="email" className="col-sm-4 fs-label" style={{ paddingLeft: 0 }}>Email Address</label>
                                <Input 
                                    type="text" 
                                    className={`form-control fs-input ${this.state.error.email ? 'form-error ' : ''}`}
                                    id="email" 
                                    name="email"
                                    value={this.state.email}
                                    onBlur={(e) => {
                                        this.checkEmail(e)
                                    }}
                                    onChange={(e) => {
                                        this.handleChange(e)
                                    }}
                                />

                                <div className="row justify-content-between" style={{ paddingLeft: "17px", paddingRight: "17px"}}>
                                    <div style={dropdownWidth}>
                                        <label for="month" style={{ paddingLeft: 0 }} className="fs-label">Birth Month</label>
                                        <select 
                                            type="number" 
                                            className={`form-control exclude adult-dob-field ${this.state.error.month ? 'form-error ' : ''}`}
                                            id="month" 
                                            data-toggle="tooltip" 
                                            data-placement="top" 
                                            data-html="true" 
                                            data-trigger="manual" 
                                            name="month" 
                                            value={this.state.month}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        >
                                            <option value="." disabled selected>
                                                ...
                                            </option>
                                            {MonthDropdownContent}
                                        </select>
                                    </div>

                                    <div style={dropdownWidth}>
                                        <label for="year" style={{ paddingLeft: 0 }} className="fs-label">Birth Year</label>
                                        <select
                                            type="number" 
                                            className={`form-control exclude adult-dob-field ${this.state.error.year ? 'form-error ' : ''}`}
                                            id="year" 
                                            data-toggle="tooltip" 
                                            data-placement="top" 
                                            data-html="true" 
                                            data-trigger="manual" 
                                            name="year"
                                            value={this.state.year}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        >
                                            <option value="." disabled selected>
                                                ...
                                            </option>
                                            {YearDropdownContent}
                                        </select>
                                    </div>
                                    {
                                        showAgeDropdown ?
                                        <div style={{ width: "20%" }}>
                                            <label id="label_age" for="age" style={{ paddingLeft: 0 }} className="fs-label">Age (on session day)</label>
                                            <select
                                                type="number" 
                                                className={`form-control exclude adult-dob-field ${this.state.error.age ? 'form-error ' : ''}`}
                                                id="age" 
                                                data-toggle="tooltip" 
                                                data-placement="top" 
                                                data-html="true" 
                                                data-trigger="manual" 
                                                name="age"
                                                value={this.state.age}
                                                onChange={(e) => {
                                                    this.handleChange(e)
                                                }}
                                            >
                                                <option value="." disabled selected>
                                                    ...
                                                </option>
                                                {AgeDropdownContent}
                                            </select>
                                        </div>
                                        :
                                        <></>
                                    }
                                </div>
                                <div style={{marginBottom: '27px' }}>
                                    {this.state.age == 18 || this.state.age == 19 ?<p style={{color:'red'}}>Proof of age (Photo ID) will be required on arrival</p> : ''}
                                </div>
                                <div>
                                    <label for="fullName" className="col-sm-4 fs-label" style={{ paddingLeft: "0px" }}>Full Name</label>
                                    <Input 
                                        type="text"
                                        className={`form-control input-lg exclude ${this.state.error.fullName ? 'form-error ' : ''}`}
                                        id="fullName"
                                        name="fullName"
                                        value={this.state.fullName}
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}                
                                    />
                                </div>

                                <div className="row justify-content-between" style={{ paddingLeft: "17px", paddingRight: "17px" }}>
                                    <div style={{width: "20%"}} className="mb-4 full-responsive">
                                        <label for="gender" className="col-sm-4 fs-label" style={{ paddingLeft: "0px" }}>Gender</label>
                                        <select 
                                            className={`form-control mt-2 ${this.state.error.gender ? 'form-error ' : ''}`}
                                            id="gender"
                                            name="gender"
                                            value={this.state.gender}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        >
                                            <option value="." disabled selected>
                                                ...
                                            </option>
                                            <option value="Male">
                                                Male
                                            </option>
                                            <option value="Female">
                                                Female
                                            </option>
                                            <option value="Rather not say">
                                                Rather not say
                                            </option>
                                        </select>
                                    </div>
                                    <div style={{width: "50%"}} className="mb-4 full-responsive">
                                        <label for="occupation" className="col-sm-4 fs-label" style={{ paddingLeft: "0px" }}>Occupation</label>
                                        <select 
                                            className={`form-control mt-2 ${this.state.error.occupation ? 'form-error ' : ''}`}
                                            id="occupation"
                                            name="occupation"
                                            value={this.state.occupation}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        >
                                            <option value="" disabled selected>
                                                ...
                                            </option>
                                            <option value="Admin">Admin</option>
                                            <option value="Accounting / Fincance">Accounting / Finance</option>
                                            <option value="Advertising / Marketing">Advertising / Marketing</option>
                                            <option value="Aviation">Aviation</option>
                                            <option value="Building / Construction">Building / Construction</option>
                                            <option value="Defence force">Defence force</option>
                                            <option value="Education">Education</option>
                                            <option value="Health and Fitness">Health and Fitness</option>
                                            <option value="Healthcare">Healthcare</option>
                                            <option value="Hospitality and Tourism">Hospitality and Tourism</option>
                                            <option value="Law Enforcement">Law Enforcement</option>
                                            <option value="Management / Executive">Management / Executive</option>
                                            <option value="Other Trades and Services">Other Trades and Services</option>
                                            <option value="Public Service">Public Service</option> 
                                            <option value="Retail">Retail</option>
                                            <option value="Retired">Retired</option>
                                            <option value="Sales">Sales</option>
                                            <option value="Student (University or TAFE)">Student (University or TAFE)</option>
                                            <option value="Unemployed">Unemployed</option> 
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="row justify-content-between mb-4" style={{ paddingLeft: "17px", paddingRight: "17px" }}>
                                    <div style={{ width: "45%", marginBottom: "16px" }} className="full-responsive">
                                        <div className="w-100"></div>
                                        <label id="label_country" for="country" style={{ paddingLeft: 0 }} className="fs-label">Country</label>
                                        <select 
                                            className={`form-control mt-2 " ${this.state.error.country ? 'form-error ' : ''}`}
                                            id="country" 
                                            name="country" 
                                            value={this.state.country}
                                            onChange={(e) => {
                                                this.getStateListContent()
                                                this.handleChange(e)
                                            }}>
                                            <option value="." disabled>
                                                ...
                                            </option>
                                            {countryListContent}
                                        </select>
                                    </div>
                                    <div style={{ width: "45%" }} className="full-responsive">
                                        <label id="label_state" for="state" style={{ paddingLeft: 0 }} className="fs-label">State</label>
                                        <select
                                            className={`form-control mt-2 ${stateListContent ? '' : 'disabled'}`}
                                            id="state"
                                            name="state"
                                            disabled={stateListContent ? '' : 'disabled'}
                                            value={this.state.state}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        >
                                            {stateListContent}
                                        </select>
                                    </div>
                                </div>

                                <div className="row justify-content-between" style={{ paddingLeft: "17px", paddingRight: "17px" }}>
                                    <div style={{ width: "75%" }}>
                                        <label for="suburb" style={{ paddingLeft: 0 }} className="fs-label">Suburb</label>
                                        <Input 
                                            type="text"
                                            className={`form-control exclude" ${this.state.error.suburb ? 'form-error ' : ''}`}
                                            id="suburb"
                                            name="suburb"
                                            placeholder="" 
                                            value={this.state.suburb}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        />
                                    </div>
                                    <div style={{ width: "20%" }}>
                                        <label for="postcode" style={{ paddingLeft: 0 }} className="fs-label">Postcode</label>
                                        <Input 
                                            type="tel"
                                            className={`number-only form-control exclude" ${this.state.error.suburb ? 'form-error ' : ''}`}
                                            id="postcode"
                                            name="postcode"
                                            placeholder=""
                                            value={this.state.postcode}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label for="phone" style={{ marginLeft: "5px" }} className="fs-label">Phone Number</label>
                                    <Input 
                                        type="tel"
                                        className={`number-only form-control exclude" ${this.state.error.phone ? 'form-error ' : ''}`}
                                        id="phone"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={(e) => {
                                            this.handleChange(e)
                                        }}
                                    />
                                </div>
                                <div className="row col-md-12">
                                    <div>
                                        <label
                                            for="is_medical_condition"
                                            className="col-sm-12 fs-label"
                                            style={{ 
                                                paddingLeft: 0,
                                                marginBottom: "9px"
                                            }}
                                        >
                                            Do you suffer from any medical conditions?
                                        </label>

                                        <div className="row" id="medical-content">
                                            <div className="col-md-8">
                                                <div className="row">
                                                    <div className="col-md-8">
                                                        <div className="row">
                                                            <div className="col-md-4 radio-container" style={{ display: "flex", flexDirection: "row" }}>
                                                                Yes
                                                                <Input
                                                                    className={`checkmark ${this.state.error.isMedicalCondition == 1 ? 'form-checkmark-error' : ''}`}
                                                                    type="radio"
                                                                    name="isMedicalCondition"
                                                                    id="is_medical_condition_yes"
                                                                    style={{ marginTop: "10px" }}
                                                                    value={1}
                                                                    onChange={(e) => {
                                                                        this.handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="col-md-4 radio-container" style={{ display: "flex", flexDirection: "row" }}>
                                                                No
                                                                <Input
                                                                    className={`checkmark ${this.state.error.isMedicalCondition == 0? 'form-checkmark-error' : ''}`}
                                                                    type="radio"
                                                                    name="isMedicalCondition"
                                                                    id="is_medical_condition_no"
                                                                    style={{ marginTop: "10px" }}
                                                                    value={0}
                                                                    onChange={(e) => {
                                                                        this.handleChange(e)
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="medicalConditionReason"
                                            name="medicalConditionReason"
                                            placeholder="Please let us know"
                                            style={{display: `${this.state.isMedicalCondition == 1 ? "": "none"}`, marginBottom: "10px" }}
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        />
                                        <br/>
                                    </div>
                                </div>
                                {
                                    this.props.waiverType == 'minor_child' ?
                                        <>
                                            <div className="form-check form-check-inline" id="also-emergency-content">
                                                <div className="checkbox-container" style={{ display: "flex", flexDirection: "row" }}>
                                                    <Input
                                                        style={{ marginBottom: "1px", width: "14px", marginLeft: "-17%" }}
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id="isEmergencyContact"
                                                        name="isEmergencyContact"
                                                        onChange={(e) => {
                                                            this.handleChange(e)
                                                        }}
                                                    />
                                                    <span className="checkmark-check"></span>
                                                    <label style={{ fontSize: "13px" }} className="form-check-label fs-subtitle" for="isEmergencyContact">I am also the emergency contact</label>
                                                </div>
                                            </div>
                                            <br />
                                        </>
                                        :
                                        <></>
                                }

                                {TickBoxContent}
                                <div className="form-check form-check-inline">
                                    <div className="checkbox-container" style={{ display: "flex", flexDirection: "row", marginLeft: "5px"}}>
                                        <Input
                                            style={{ marginBottom: "1px", width: "14px"}}
                                            className="form-check-input"
                                            type="checkbox"
                                            id="subscribe"
                                            name="subscribe"
                                            onChange={(e) => {
                                                this.handleChange(e)
                                            }}
                                        />
                                        <span className="checkmark-check"></span>
                                        <label style={{ fontSize: "13px" }} className="form-check-label fs-subtitle" for="subscribe">Thinking of adventuring more often? Subscribe to receive the latest news and offers to our parks!</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="action-button-previous" value="Previous" onClick={() => this.previousPage()}><FaArrowLeft/> Back</button>                    
                    <input id="submit_pi" type="submit" name="submit_pi" className="next action-button float-right" value="Next" />
                </Form>
                </>
            :
            <div></div>
        );
    }
}
export default PersonalInformationCard
