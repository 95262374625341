import { FETCH_SEGMENT, IS_FETCHING_SEGMENT, ERROR } from "../actions/actionTypes";

const initialState = {
    data: [],
    fetching: false,
    fetched: false,
    error: null
};

export const segmentReducer = (state = initialState, action) => {
	switch (action.type) {
		case IS_FETCHING_SEGMENT:
			return { ...state, fetching: true };
		case FETCH_SEGMENT:
			return {
				...state,
				fetched: true,
				fetching: false,
				data: action.payload
			};
		case ERROR:
			return {
				...state,
				fetching: false,
				error: action.payload
			};
		default:
			return state;
	}
};
