export const FETCH_PARK = 'FETCH_PARK';
export const FETCH_PRODUCT = 'FETCH_PRODUCT';
export const FETCH_PRODUCT_DETAIL = 'FETCH PRODUCT DETAIL';
export const FETCH_SEGMENT = 'FETCH_SEGMENT';
export const FETCH_VOUCHER = 'FETCH_VOUCHER';
export const FETCH_PARTNER_CATEGORY = 'FETCH_VOUCHER';
export const IS_FETCHING_PARK = 'IS_FETCHING_PARK';
export const IS_FETCHING_PRODUCT = 'IS_FETCHING_PRODUCT';
export const IS_FETCHING_PRODUCT_DETAIl = 'IS_FETCHING_PRODUCT_DETAIL';
export const IS_FETCHING_SEGMENT = 'IS_FETCHING_SEGMENT';
export const IS_FETCHING_VOUCHER = 'IS_FETCHING_VOUCHER';
export const IS_FETCHING_PARTNER_CATEGORY = "IS_FETCHING_PARTNER_CATEGORY";
export const ERROR = 'ERROR';