export const url = process.env.REACT_APP_API_ENDPOINT;

export const api_read_resource_consumption = url + "web/dataset/read_resource_consumption";
export const api_read_segment = url + "web/dataset/read_segment";
export const api_read_voucher = url + "web/dataset/read_voucher";
export const api_read_partner_category = url + "web/dataset/read_partner_category";

export const api_user_token = url + "web/dataset/user_token"
export const api_customer = url + "web/dataset/customer"
export const api_start_time = url + "web/dataset/start_time_json";
export const api_order = url + "web/dataset/create_booking";
export const api_voucher = url + "web/dataset/voucher_validation";
export const api_government_voucher = url + "web/dataset/gov_voucher_validation";
export const api_create_booking_step2 = url + "web/dataset/create_booking_step2";
export const api_create_booking_step3 = url + "web/dataset/create_booking_step3";
export const api_create_booking_step4 = url + "web/dataset/create_booking_step4";
export const api_create_booking_step4_check = url + "web/dataset/booking_step4";
export const api_create_new_booking = url + "web/dataset/create_new_booking";
export const api_create_booking_request = url + "web/dataset/create_booking_request";
export const api_create_new_customer = url + "web/dataset/create_new_customer";
export const api_get_booking = url + "web/dataset/get_booking_confirmation";
export const api_get_booking_request = url + "web/dataset/get_booking_request_confirmation";
export const api_redirect_booking = url + "web/dataset/redirect_booking";
export const api_create_voucher = url + "web/dataset/create_voucher";
export const api_pay_voucher = url + "web/dataset/pay_voucher";
export const api_pay_voucher_check = url + "web/dataset/check_voucher_order";
export const api_get_voucher_list = url + "web/dataset/get_voucher_list";
export const api_get_voucher_order = url + "web/dataset/get_voucher_order";
export const api_get_voucher_campaign = url + "web/dataset/get_voucher_campaign";
export const api_get_voucher_monetary_value = url + "web/dataset/get_voucher_monetary_value";
export const api_get_experience_list = url + "web/dataset/get_experience_list";
export const api_get_activity_option_list = url + "web/dataset/get_activity_option_list";
export const api_get_active_discount = url + "web/dataset/get_active_discount";
export const api_customer_feedback = url + "web/dataset/customer_feedback";

export const api_product_cache = "/cache/product.json?a=1";
export const api_park_cache = "/cache/park.json?a=1";

export const api_get_windcave_session = url + "web/dataset/get_windcave_session";
export const api_get_windcave_session_voucher = url + "web/dataset/get_windcave_session_voucher";

// Waiver API
export const api_get_booking_id = url + "web/dataset/get_booking_information"
export const api_create_waiver = url + "web/dataset/create_waiver"
export const api_get_country_list = url + "web/dataset/get_country_list"
export const api_get_states_list = url + "web/dataset/get_states_list"
export const api_get_park_waiver_content = url + "web/dataset/get_waiver_content"
export const api_sign_waiver = url + "web/dataset/sign_waiver"
export const api_get_user = url + "web/dataset/waiveruser"